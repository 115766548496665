import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import {
  processData,
  createDataForDashboard,
  createDataForEnlacesDashboard,
  createDataForInfraCriticaDashboard,
  getClientesIndicadores,
  getClientesIndicadoresSubscriptors,
  appsByNucleoErrors,
  createDataforImedDashboard
  // @ts-ignore
} from 'api/datasource.js';
// @ts-ignore
import Transbank from '../views/Transbank/Transbank';
// @ts-ignore
import NewTransbank from '../views/NewTransbank/NewTransbank';
// @ts-ignore
import InfroCritica from '../views/InfraCritica/InfraCritica';
// @ts-ignore
import InfraCritica2 from '../views/InfraCritica2/InfraCritica2';
// @ts-ignore
import ClientesIndicadores from '../views/ClientesIndicadores/ClientesIndicadores';
// @ts-ignore
import Enlaces from '../views/Enlaces/Enlaces';
// @ts-ignore
import Central from '../views/Central/Central';
// @ts-ignore
import Nucleo from '../views/Nucleo/Nucleo';
// @ts-ignore
import NucleoDigital from '../views/NucleoDigital/NucleoDigital';
// @ts-ignore
import VDI from '../views/VDI/VDI';
// @ts-ignore
import Tanzu from '../views/Tanzu/Tanzu';
// @ts-ignore
import Imed from "../views/Imed/Imed";
// @ts-ignore
import array_of_central_items from './fake.js';
// @ts-ignore
import { number_status, global_states } from '../utils/dictionaryStates.js';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {
  const [infoCajasDashboard, setInfoCajasDashboard] = useState({});
  const [infoEnlacesDashboard, setInfoEnlacesDashboard] = useState({});
  const [infoTransbankDashboard, setInfoTransbankDashboard] = useState({});
  const [infoInfraCriticaDashboard, setInfoInfraCriticaDashboard] = useState({});
  const [infoNucleoDashboard, setInfoNucleoDashboard] = useState({});
  const [infoImed, setInfoImed] = useState({});
  const [completeSeries, setCompleteSeries] = useState(null);
  const [subscriptoresClientesIndicadores, setSubscriptoresClientesIndicadores] = useState({});

  const [ldapStatus, setLdapStatus] = useState("UNK");
  // const [infoHostsWeblogic, setInfoHostsWeblogic] = useState({});

  const [orderColumns, setOrderColumns] = useState([]);
  const [speedEnlaces, setEnlacesSpeed] = useState([]);
  const [thresholdSpeed, setThresholdsSpeed] = useState({ overloaded: 85, unused: 5 });
  // const [nameSelectedDashboard, setNameSelectedDashboard] = useState('Clientes indicadores');
  const [nameSelectedDashboard, setNameSelectedDashboard] = useState('');
  const [arrayCentralItems, setArrayCentralItems] = useState(array_of_central_items);
  const [centralItemsTmp, setCentralItemsTmp] = useState(null);
  const [infoIndicatorClients, setInfoIndicatorClients] = useState(null);
  const [infraStatusTmp, setInfraStatusTmp] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });

  const [tanzu, setTanzu] = useState([]);
  
  const [digiNucleo, setDigiNucleo] = useState([]);

  const [loadingInfraCritica, setLoadingInfraCritica] = useState(false);

  const [tmpCliInd,setTmpCliInd] = useState(false);

  function selectDashboard(dashboard: any) {
    setNameSelectedDashboard(dashboard);
  }
  function getDashboard() {
    // if (nameSelectedDashboard === 'Cajas (xCash / Transbank)') {
    // return (
    //   <Transbank
    //     infoDashboard={infoCajasDashboard}
    //     orderColumns={orderColumns}
    //     selectMainDashboard={() => {
    //       selectDashboard(null);
    //     }}
    //   />
    // );
  // }
  if (nameSelectedDashboard === 'Transbank') {
      return (
        <NewTransbank
          infoDashboard={infoTransbankDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Infraestructura Crítica') {
      return (
        <InfraCritica2
          infoDashboard={infoInfraCriticaDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'Clientes indicadores') {
      return (
        <ClientesIndicadores
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          ldapStatus={ldapStatus}
          tanzu={tanzu}
          infoIndicatorClients={infoIndicatorClients}
          subscriptoresClientesIndicadores={subscriptoresClientesIndicadores}
        />
      );
    } else if (nameSelectedDashboard === 'Enlaces') {
      return (
        <Enlaces
          speedEnlaces={speedEnlaces}
          infoDashboard={infoEnlacesDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          overloadedSpeed={thresholdSpeed['overloaded']}
          unusedSpeed={thresholdSpeed['unused']}
        />
      );
    } else if (nameSelectedDashboard === 'Nucleo') {
      return (
        <Nucleo
          infoDashboard={infoNucleoDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Imed') {
      return (
        <Imed
          infoImed = {infoImed}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Ficha Nucleo') {
      return (
        <NucleoDigital
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'VDI') {
      return (
        <VDI
          infoDashboard={infoInfraCriticaDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'Tanzu') {
      return (
        <Tanzu
          infoDashboard={infoInfraCriticaDashboard}
          tanzu = {tanzu}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else {
      return <Central array_of_central_items={arrayCentralItems} selectDashboard={selectDashboard} />;
    }
  }

  // @ts-ignore
  function removeCircularReferences(obj, seen = new WeakSet()) {
    if (obj && typeof obj === 'object') {
      if (seen.has(obj)) {
        return '[Circular]';
      }
      seen.add(obj);
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = removeCircularReferences(obj[key], seen);
        }
      }
      seen.delete(obj);
    }
    return obj;
  }

  async function getInfraCritica(setLoading: any) {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    // const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');

    const series = JSON.parse(JSON.stringify(originalSeries));
    const {
      vcenter,
      infoHostsWeblogic: weblogic,
      ldap,
      dynatrace,
      infraStatus,
      totalInfra,
      problemInfra,
      ldapStatus,
      tanzu,
    } = await createDataForInfraCriticaDashboard(series['infracritica'], setLoading);

    setInfraStatusTmp({ total: totalInfra, problems: problemInfra, status: infraStatus });
    setInfoInfraCriticaDashboard({ vcenter, weblogic, ldap, dynatrace });
    setLdapStatus(ldapStatus);
    setTanzu(tanzu);
  }

  async function getDigiNucleo(){
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    const series = JSON.parse(JSON.stringify(originalSeries));
// 
    const res =  await appsByNucleoErrors(series['infracritica']);
    // console.log(res);
    setDigiNucleo(res);
  }
  // console.log(loadingInfraCritica);
  
  // @ts-ignore
  useEffect(() => {
    // setLoadingInfraCritica();
    getInfraCritica(setLoadingInfraCritica);
    // getSubscriptorsClientesIndicadores();
    // @ts-ignore
  }, [data]);

  // @ts-ignore
  useEffect(() => {
    // console.log(tmpCliInd);
    // if (tmpCliInd === true) {
      getSubscriptorsClientesIndicadores();
    // }
    // @ts-ignore
  }, [tmpCliInd]);

  useEffect(() =>{
    getDigiNucleo();
  }, [data]);

  async function getSubscriptorsClientesIndicadores() {
    // console.log(completeSeries);
    // if (centralItemsTmp) {
    //   console.log(centralItemsTmp);
    // }
    
    if (completeSeries && centralItemsTmp && centralItemsTmp.length) {
      const clientesIndicadoresResponse = getClientesIndicadores(completeSeries['clientesindicadores']);
      const oracleInfo = clientesIndicadoresResponse['oracle'];
      const cliIndStatus = clientesIndicadoresResponse['globalStatus'];
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
      
      // ORACLE
      let infoIndicators = { oracleInfo: oracleInfo };
      // @ts-ignore
      setInfoIndicatorClients(infoIndicators);
      let infraResponse = null;
      if (completeSeries && completeSeries['infracritica']) {
        infraResponse = await getClientesIndicadoresSubscriptors(completeSeries['infracritica']);

        setSubscriptoresClientesIndicadores(infraResponse);
      }
      // 7:10 - 7:14

      let problems = 0;
      let total = 2;
      if (ldapStatus && tanzu.length && infoIndicatorClients && infraResponse) {
        let ldapStatusNumber = number_status[ldapStatus];
        let tanzuStatus = 0;
        let infoIndicatorClientsStatus = 0;
        tanzu.forEach(element => {
          total += 1;
          if (number_status[element.status] >= tanzuStatus) {
            tanzuStatus = number_status[element.status];
          }
        });
        if (infoIndicatorClients && infoIndicatorClients['oracleInfo']) {
          for (const item in infoIndicatorClients['oracleInfo']) {
            total += 1;
            const element = infoIndicatorClients['oracleInfo'][item];
            if (element.status >= infoIndicatorClientsStatus) {
              infoIndicatorClientsStatus = element.status;
            }
            if (element.status !== 0) {
              problems += 1;
            }
          }
        }
        let max = Math.max(...[ldapStatusNumber, infoIndicatorClientsStatus, number_status[cliIndStatus]]);
        if(tanzuStatus == 2 && max < 2){
          max = 1;
        }
        copyArrayCentralItems[indexClientesIndicadores]['status'] = global_states[max];
        copyArrayCentralItems[indexClientesIndicadores]['loading'] = false;
        copyArrayCentralItems[indexClientesIndicadores]['total'] = total;
        // copyArrayCentralItems[indexClientesIndicadores]['problems'] = problems;
        setArrayCentralItems(copyArrayCentralItems);
      }
    }
  }

  useEffect(() => {
    const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');
    // const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
    copyArrayCentralItems[indexInfraCrit]['status'] = infraStatusTmp.status;
    copyArrayCentralItems[indexInfraCrit]['total'] = infraStatusTmp.total;
    copyArrayCentralItems[indexInfraCrit]['problems'] = infraStatusTmp.problems;
   
    // if (copyArrayCentralItems[indexClientesIndicadores]['status'] === 'OK' && infraStatusTmp.status === 'CRITICAL') {
    //   copyArrayCentralItems[indexClientesIndicadores]['status'] = infraStatusTmp.status;
    // }
    setArrayCentralItems(copyArrayCentralItems);
    // getSubscriptorsClientesIndicadores();
    setTmpCliInd(!tmpCliInd);
  }, [infraStatusTmp]);

  // @ts-ignore
  useEffect(() => {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');

    const series = JSON.parse(JSON.stringify(originalSeries));
    // console.log(series)
    setCompleteSeries(series);
    const {
      infoHosts: infoTmpDashboard,
      globalStatus: cajasGlobalStatus,
      total,
      problems,
    } = createDataForDashboard(series['cajas']);

    const {
      infoHosts: infoEnlacesDashboard,
      globalStatus: enlacesGlobalStatus,
      total: totalEnlaces,
      problems: problemsEnlaces,
    } = createDataForEnlacesDashboard(series['enlaces']);

    const {
      infoHosts: transbankInfoDashboard,
      globalStatus: transbankGlobalStatus,
      total: transbankTotal,
      problems: transbankProblems,
    } = createDataForDashboard(series['transbank']);
    const {
      infoHosts: nucleoInfoDashboard,
      globalStatus: nucleoGlobalStatus,
      total: nucleoTotal,
      problems: nucleoProblems,
    } = createDataForDashboard(series['nucleo'], 'OK');
    // console.log(series['nucleo']);
    const {
      urls: infoImedTemp,
      globalStatus: ImedGlobalStatus,
      total: ImedTotal,
      problems: ImedProblems,

    } = createDataforImedDashboard(series["IMED_URLS"]);
    setInfoImed(infoImedTemp);
    // const clientesIndicadoresResponse = getClientesIndicadores(series['clientesindicadores']);
    // const oracleInfo = clientesIndicadoresResponse['oracle'];
    // const cliIndStatus = clientesIndicadoresResponse['globalStatus'];

    // const indexCajas = arrayCentralItems.findIndex((itm: any) => itm.title === 'Cajas (xCash / Transbank)');
    const indexEnlaces = arrayCentralItems.findIndex((itm: any) => itm.title === 'Enlaces');
    const indexTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Transbank');
    const nucleoTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Nucleo');
    const indexImed = arrayCentralItems.findIndex((itm: any) => itm.title === 'Imed');
    const nucleoDigitalTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Ficha Nucleo');
    // const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');

    const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));

    // copyArrayCentralItems[indexClientesIndicadores]['status'] = cliIndStatus;

    // Cajas
    copyArrayCentralItems[nucleoTransbank]['status'] = nucleoGlobalStatus;
    copyArrayCentralItems[nucleoTransbank]['total'] = nucleoTotal;
    copyArrayCentralItems[nucleoTransbank]['problems'] = nucleoProblems;

    // Imed
    copyArrayCentralItems[indexImed]['status'] = ImedGlobalStatus;
    copyArrayCentralItems[indexImed]['total'] = ImedTotal;
    copyArrayCentralItems[indexImed]['problems'] = ImedProblems;

    // Cajas
    // copyArrayCentralItems[indexCajas]['status'] = cajasGlobalStatus;
    // copyArrayCentralItems[indexCajas]['total'] = total;
    // copyArrayCentralItems[indexCajas]['problems'] = problems;
    setInfoCajasDashboard(infoTmpDashboard);

    // Enlaces
    copyArrayCentralItems[indexEnlaces]['status'] = enlacesGlobalStatus;
    copyArrayCentralItems[indexEnlaces]['total'] = totalEnlaces;
    copyArrayCentralItems[indexEnlaces]['problems'] = problemsEnlaces;
    setInfoEnlacesDashboard(infoEnlacesDashboard);

    // Transbank
    copyArrayCentralItems[indexTransbank]['status'] = transbankGlobalStatus;
    copyArrayCentralItems[indexTransbank]['total'] = transbankTotal;
    copyArrayCentralItems[indexTransbank]['problems'] = transbankProblems;
    setInfoTransbankDashboard(transbankInfoDashboard);

    setCentralItemsTmp(copyArrayCentralItems);

    // Set info central panel
    setArrayCentralItems(copyArrayCentralItems);

    setInfoNucleoDashboard(nucleoInfoDashboard);
  }, [data]);

  useEffect(() => {
    if (options && options.orderColumns) {
      setOrderColumns(JSON.parse(options.orderColumns));
    }
    if (options && options.enlacesSpeed) {
      setEnlacesSpeed(JSON.parse(options.enlacesSpeed));
    }
    if (options && options['enlace-critical-threshold'] && options['enlace-unused-threshold']) {
      setThresholdsSpeed({
        overloaded: options['enlace-critical-threshold'],
        unused: options['enlace-unused-threshold'],
      });
    }
  }, [options]);

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d1def0', padding: '15px' }}
    >
      {getDashboard()}
    </div>
  );
};
