import React, { useEffect, useState } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import './NucleoDigital.css';
import InfoHost from '../../components/InfoHost/InfoHost';


function NucleoDigital({ selectMainDashboard, data }) {
    const infoItemsTanzu = [
        {type: "", hostname: "Nucleo Web", iconName: "shield", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "0px"}},
        {type: "", hostname: "Microfronts", iconName: "query", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "63px"}},
        {type: "Microservicios", hostname: "Front", iconName: "service", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "126px"}},
        {type: "Microservicios", hostname: "Back", iconName: "service", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "189px"}},
        {type: "API", hostname: "Llamado Paciente", iconName: "cloud_api", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "252px"}},
        {type: "API", hostname: "Gestión Camas", iconName: "cloud_api", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "315px"}},
        {type: "API", hostname: "ApiDocs", iconName: "cloud_api", style: {width: "220px", height: "53px", position: "absolute", left: "100px", top: "378px"}},
        {type: "Integración Nats", iconName: "query", hostname: "Movimiento Pacientes", style: {width: "300px", height: "70px", position: "absolute", left: "450px", top: "150px"}},
        {type: "Integración Nats", iconName: "query", hostname: "Replica Clientes", style: {width: "300px", height: "70px", position: "absolute", left: "450px", top: "225px"}},
        {type: "Integración Nats", iconName: "query", hostname: "Replica IC Saturno", style: {width: "300px", height: "70px", position: "absolute", left: "450px", top: "300px"}},
    ];

    const infoItemsClover = [
        {type: "Afga/Lebox", iconName: "medical_result", hostname: "Resultados", style: {width: "220px", height: "60px", position: "absolute", left: "10px", top: "0px"}},
        {type: "Afga/Lebox", iconName: "medical_request", hostname: "Solicitudes", style: {width: "220px", height: "60px", position: "absolute", left: "10px", top: "65px"}},
        {type: "Afga/Lebox", iconName: "medical_request", hostname: "Estados", style: {width: "220px", height: "60px", position: "absolute", left: "300px", top: "0px"}},
        {type: "Medicap", iconName: "medical_request", hostname: "Reservas", style: {width: "220px", height: "60px", position: "absolute", left: "300px", top: "65px"}},
        {type: "Modulab", iconName: "medical_result", hostname: "Estados/Resultados", style: {width: "220px", height: "60px", position: "absolute", left: "580px", top: "0px"}},
        {type: "Modulab", iconName: "medical_request", hostname: "Solicitud Examen", style: {width: "220px", height: "60px", position: "absolute", left: "580px", top: "65px"}},
    ]

    const itemsTanzu = infoItemsTanzu.map((item) => {
        let status = "NOTDEFINED";
        if(data[item.hostname]){
            status = data[item.hostname].status;
        }
        return (
            <CardWrapper
                status={status}
                mainContent={<InfoHost iconName={item.iconName} type={item.type} hostname={item.hostname} ip={``} />}
                style={item.style}
            />
        )
    }
    );

    const itemsClover = infoItemsClover.map((item) => (
        <CardWrapper
            status={"NOTDEFINED"}
            mainContent={<InfoHost iconName={item.iconName} type={item.type} hostname={item.hostname} ip={``} />}
            style={item.style}
        />
    ));

    return (
        <section className="nucleo-digital-view">
            <Toolbar title={`Ficha Nucleo`} />
            <div className='nucleo-digital-main'>
                <div className='row1'>
                    <CardWrapper
                        status={"UNK"}
                        mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'CloverLeaf'} ip={``} />}
                        items={itemsClover}
                        style={{
                            width: '880px', 
                            height: "100%",
                        }}
                    />
                </div>
                <div className='row2'>
                    <div className='col1'>
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'azure'} type={''} hostname={'Azure AD'} ip={``} />}
                            // items={items}
                            style={{ width: '220px' }}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'cloud_api'} type={'Api'} hostname={'Profesionales'} ip={``} />}
                            // items={items}
                            style={{ width: '220px' }}
                        />
                    </div>
                    <div className='col2'>
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'shield'} type={'Nucleo'} hostname={'Oracle PO11S7'} ip={``} />}
                            // items={items}
                            style={{ width: '220px' , height: '70px'}}
                        />
                    </div>
                    <div className='col3'>
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'service'} type={'Webservices - Papudo'} hostname={'Medicap - Cambio de Estado'} ip={``} />}
                            // items={items}
                            style={{ width: '334px' }}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'service'} type={'Fase 1 (WS)'} hostname={'Admisiones de Urgencia'} ip={``} />}
                            // items={items}
                            style={{ width: '334px' }}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'service'} type={'Webservices'} hostname={'Sisalud - Preadmisiones'} ip={``} />}
                            // items={items}
                            style={{ width: '334px' }}
                        />
                    </div>
                </div>
                <div className='row3'>
                    <div className='col1'>
                        <CardWrapper
                            status={"UNK"}
                            mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Tanzu'} ip={`IP 172.12.1.11`} />}
                            items={itemsTanzu}
                            style={{
                                width: '100%', 
                                height: "100%",
                            }}
                        />
                    </div>
                    <div className='col2'>
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'web'} type={''} hostname={'Weblogic'} ip={``} />}
                            // items={items}
                            style={{ width: '300px' , padding: "14px"}}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'shield'} type={''} hostname={'Nucleo 1 App Escritorio'} ip={``} />}
                            // items={items}
                            style={{ width: '300px', padding: "14px"}}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'web'} type={'URL Modulab'} hostname={'Resultados Laboratorio'} ip={`Vista Analítica`} />}
                            // items={items}
                            style={{ width: '300px', padding: "14px"}}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'medical_result'} type={'Resultados'} hostname={'Imágenes'} ip={``} />}
                            // items={items}
                            style={{ width: '300px', padding: "14px"}}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'medical_result'} type={'Resultados'} hostname={'Anatomía Patológica'} ip={``} />}
                            // items={items}
                            style={{ width: '300px', padding: "14px"}}
                        />
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'service'} type={''} hostname={'Servicio HIBA'} ip={``} />}
                            // items={items}
                            style={{ width: '300px', padding: "14px"}}
                        />
                    </div>
                    <div className='col3'>
                        <CardWrapper
                            status={'NOTDEFINED'}
                            mainContent={<InfoHost iconName={'server'} type={''} hostname={'Servidor LDAP'} ip={`adcas3.alemana.cl`} />}
                            // items={items}
                            style={{ width: '334px' }}
                        />
                    </div>
                </div>
            </div>
            <div className='lines'>
                {/*Nucleo to right*/}
                <div className="line" style={{ width: '352px', top: '415px', left: '1018px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '410px', left: '1018px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '420px', left: '1018px' }}></div>
                <div className="line" style={{ width: '3px', height: '170px', top: '370px', left: '1370px' }}></div>
                <div className="line" style={{ width: '59px', top: '370px', left: '1370px' }}></div>
                <div className="line" style={{ width: '59px', top: '455px', left: '1370px' }}></div>
                <div className="line" style={{ width: '59px', top: '540px', left: '1370px' }}></div>

                

                {/* Clover leaf out lines */}
                <div className="line" style={{ width: '573px', top: '355px', left: '750px' }}></div>

                <div className="line" style={{ width: '3px', height: '29px', top: '355px', left: '900px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '377px', left: '899px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '377px', left: '890px' }}></div>


                <div className="line" style={{ width: '3px', height: '150px', top: '205px', left: '750px' }}></div>
                <div className="line" style={{ width: '37px', top: '205px', left: '713px' }}></div>
                <div className="line" style={{ width: '37px', top: '270px', left: '713px' }}></div>


                <div className="line" style={{ width: '3px', height: '150px', top: '205px', left: '1040px' }}></div>
                <div className="line" style={{ width: '37px', top: '205px', left: '1003px' }}></div>
                <div className="line" style={{ width: '37px', top: '270px', left: '1003px' }}></div>

                <div className="line" style={{ width: '3px', height: '150px', top: '205px', left: '1320px' }}></div>
                <div className="line" style={{ width: '37px', top: '205px', left: '1284px' }}></div>
                <div className="line" style={{ width: '37px', top: '270px', left: '1284px' }}></div>


                {/* Inner Tanzu Lines */}
                <div className="line" style={{ width: '3px', height: '375px', top: '620px', left: '80px' }}></div>
                
                <div className="line" style={{ width: '58px', top: '620px', left: '80px' }}></div>

                <div className="line" style={{ width: '58px', top: '680px', left: '80px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '685px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '676px', left: '126px' }}></div>


                <div className="line" style={{ width: '58px', top: '745px', left: '80px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '750px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '741px', left: '126px' }}></div>

                <div className="line" style={{ width: '58px', top: '810px', left: '80px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '815px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '806px', left: '126px' }}></div>

                <div className="line" style={{ width: '58px', top: '875px', left: '80px' }}></div>    
                <div className="bottom-arrow" style={{ width: '14px', top: '880px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '871px', left: '126px' }}></div>

                <div className="line" style={{ width: '58px', top: '940px', left: '80px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '945px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '936px', left: '126px' }}></div>

                <div className="line" style={{ width: '58px', top: '995px', left: '80px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '1000px', left: '126px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '991px', left: '126px' }}></div>
                
                {/*Nucleo web */}
                <div className="line" style={{ width: '114px', top: '600px', left: '359px' }}></div>
                <div className="line" style={{ width: '3px', height: '230px', top: '370px', left: '470px' }}></div>
                <div className="line" style={{ width: '45px', top: '370px', left: '425px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '365px', left: '424px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '375px', left: '424px' }}></div>
                <div className="line" style={{ width: '45px', top: '470px', left: '425px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '465px', left: '424px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '475px', left: '424px' }}></div>

                <div className="line" style={{ width: '390px', top: '620px', left: '359px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '615px', left: '358px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '625px', left: '358px' }}></div>
                <div className="line" style={{ width: '3px', height: '208px', top: '415px', left: '749px' }}></div>
                <div className="line" style={{ width: '49px', top: '415px', left: '749px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '420px', left: '786px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '411px', left: '786px' }}></div>
                {/* <div className="bottom-arrow" style={{ width: '14px', top: '615px', left: '358px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '625px', left: '358px' }}></div> */}


                <div className="line" style={{ width: '573px', top: '640px', left: '359px' }}></div>
                <div className="line" style={{ width: '3px', height: '343px', top: '640px', left: '929px' }}></div>
                <div className="line" style={{ width: '49px', top: '725px', left: '930px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '730px', left: '967px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '721px', left: '967px' }}></div>
                <div className="line" style={{ width: '49px', top: '810px', left: '930px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '815px', left: '967px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '806px', left: '967px' }}></div>
                <div className="line" style={{ width: '49px', top: '895px', left: '930px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '900px', left: '967px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '891px', left: '967px' }}></div>
                <div className="line" style={{ width: '49px', top: '980px', left: '930px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '985px', left: '967px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '976px', left: '967px' }}></div>

                {/* Nucleo Oracle -- Integracion Nats */}

                <div className="line" style={{ width: '3px', height: '325px', top: '454px', left: '805px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '459px', left: '794px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '459px', left: '805px' }}></div>
                <div className="line" style={{ width: '20px', top: '776px', left: '788px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '771px', left: '787px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '781px', left: '787px' }}></div>

                <div className="line" style={{ width: '3px', height: '400px', top: '454px', left: '825px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '459px', left: '814px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '459px', left: '825px' }}></div>
                <div className="line" style={{ width: '40px', top: '851px', left: '788px' }}></div>

                <div className="line" style={{ width: '3px', height: '475px', top: '454px', left: '845px' }}></div>
                <div className="line" style={{ width: '60px', top: '926px', left: '788px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '921px', left: '787px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '931px', left: '787px' }}></div>

                {/* Weblogic */}

                <div className="line" style={{ width: '3px', height: '33px', top: '454px', left: '980px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '459px', left: '969px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '459px', left: '980px' }}></div>

                <div className="line" style={{ width: '153px', top: '485px', left: '980px' }}></div>
                <div className="line" style={{ width: '3px', height: '33px', top: '487px', left: '1130px' }}></div>

                <div className="line" style={{ width: '3px', height: '20px', top: '585px', left: '1130px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '590px', left: '1119px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '590px', left: '1130px' }}></div>

                {/* Nucleo 1  out lines*/}

                <div className="line" style={{ width: '3px', height: '340px', top: '635px', left: '1340px' }}></div>

                <div className="line" style={{ width: '62px', top: '635px', left: '1280px' }}></div>

                <div className="line" style={{ width: '62px', top: '720px', left: '1280px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '715px', left: '1279px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '725px', left: '1279px' }}></div>
                
                <div className="line" style={{ width: '62px', top: '805px', left: '1280px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '800px', left: '1279px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '810px', left: '1279px' }}></div>

                <div className="line" style={{ width: '62px', top: '890px', left: '1280px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '885px', left: '1279px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '895px', left: '1279px' }}></div>

                <div className="line" style={{ width: '62px', top: '975px', left: '1280px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '970px', left: '1279px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '980px', left: '1279px' }}></div>

                <div className="line" style={{ width: '65px', top: '760px', left: '1340px' }}></div>
                <div className="bottom-arrow" style={{ width: '14px', top: '765px', left: '1393px' }}></div>
                <div className="top-arrow" style={{ width: '14px', top: '756px', left: '1393px' }}></div>


            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )

}

export default NucleoDigital;