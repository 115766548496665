import React from 'react';
import { icons } from '../../utils/dictionaryIcons';
import './InfoHost.css';

function InfoHost({ iconName, type, hostname, ip }) {
  return (
    <section className="infohost-cmpt">
      {iconName && 
      <div className="icon-infohost">
        <img src={icons[iconName]} />
      </div>}
      <div className="content-infohost">
        <div className="type">{type}</div>
        <div className="hostname">{hostname}</div>
        <div className="ip">{ip}</div>
      </div>
    </section>
  );
}

export default InfoHost;
