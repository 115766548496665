import React, { useEffect, useRef, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import {
  createDataForDashboard,
  transbankFilter,
  nucleoFilter,
  infraFilter,
  createDataForEnlacesDashboard,
  createDataForInfraCriticaDashboard,
  getClientesIndicadores,
  getClientesIndicadoresSubscriptors,
  createDataForTanzu,
  createDataURL,
  createDataForVDI,
  createDataForBD,
  createDataForFirewall,
  appsByNucleoDigitalErrors
  // @ts-ignore
} from 'api/datasource.js';
// @ts-ignore
import Transbank from '../views/Transbank/Transbank';
// @ts-ignore
import NewTransbank from '../views/NewTransbank/NewTransbank';
// @ts-ignore
import InfroCritica from '../views/InfraCritica/InfraCritica';
// @ts-ignore
import InfraCritica2 from '../views/InfraCritica2/InfraCritica2';
// @ts-ignore
import ClientesIndicadores from '../views/ClientesIndicadores/ClientesIndicadores';
// @ts-ignore
import Enlaces from '../views/Enlaces/Enlaces';
// @ts-ignore
import Central from '../views/Central/Central';
// @ts-ignore
import Nucleo from '../views/Nucleo/Nucleo';
// @ts-ignore
import NucleoDigital from '../views/NucleoDigital/NucleoDigital';
// @ts-ignore
import VDI from '../views/VDI/VDI';
// @ts-ignore
import Tanzu from '../views/Tanzu/Tanzu';
// @ts-ignore
import Imed from '../views/Imed/Imed';
// @ts-ignore
import BaseDeDatos from '../views/BaseDeDatos/BaseDeDatos';
// @ts-ignore
import Firewall from '../views/Firewall/Firewall';
// @ts-ignore
import array_of_central_items from './fake.js';
// @ts-ignore
import { number_status, global_states } from '../utils/dictionaryStates.js';
// @ts-ignore
import { getData } from '../api/backend.js';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const containerRef: any = useRef(null);
  const [scale, setScale]: any = useState(1);
  const [dataBackend, setDataBackend] = useState<any>({});
  const [infoEnlacesDashboard, setInfoEnlacesDashboard] = useState({});
  const [infoTransbankDashboard, setInfoTransbankDashboard] = useState({});
  const [infoInfraCriticaDashboard, setInfoInfraCriticaDashboard] = useState({});
  const [infoVDIDashboard, setInfoVDIDashboard] = useState({});
  const [infoBdDashboard, setInfoBdDashboard] = useState({});
  const [infoNucleoDashboard, setInfoNucleoDashboard] = useState({});
  const [infoFirewallDashboard, setInfoFirewallDashboard] = useState({});
  const [infoImed, setInfoImed] = useState({});
  //const [infoRisAgfa, setInfoRisAgfa] = useState({}); 
  const [subscriptoresClientesIndicadores, setSubscriptoresClientesIndicadores] = useState({});
  const [urlsClientesIndicadores, setUrlsClientesIndicadores] = useState({});
  const [ldapStatus, setLdapStatus] = useState('UNK');
  const [tuxedoStatus, setTuxedoStatus] = useState('UNK');
  const [orderColumns, setOrderColumns] = useState([]);
  const [speedEnlaces, setEnlacesSpeed] = useState([]);
  const [thresholdSpeed, setThresholdsSpeed] = useState({ overloaded: 85, unused: 5 });
  const [nameSelectedDashboard, setNameSelectedDashboard] = useState('');
  const [arrayCentralItems, setArrayCentralItems] = useState(array_of_central_items);
  const [centralItemsTmp, setCentralItemsTmp] = useState(null);
  const [infoIndicatorClients, setInfoIndicatorClients] = useState(null);
  const [infraStatusTmp, setInfraStatusTmp] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });
  const [VDIStatus, setVDIStatus] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });
  const [hasRedirected, setHasRedirected] = useState(false);
  const [tanzu, setTanzu] = useState([]);
  const [tanzuClientStatus, setTanzuClientStatus] = useState('UNDEFINED');
  const [redHatStatus, setRedHatStatus] = useState('UNDEFINED');
  const [arrayHostsWeblogicInfo, setArrayHostsWeblogicInfo] = useState('UNDEFINED');
  const [kubernetesStatus, setKubernetesStatus] = useState('UNDEFINED');
  const [cpuGraphData, setCpuGraphData] = useState({ datasets: [], timestamps: [] });
  const [memoryGraphData, setMemoryGraphData] = useState({ datasets: [], timestamps: [] });
  const [digiNucleo, setDigiNucleo] = useState([]);
  const [loadingInfraCritica, setLoadingInfraCritica] = useState(false);

  function selectDashboard(dashboard: any) {
    setNameSelectedDashboard(dashboard);
  }

  useEffect(() => {
    function adjustScale() {
      const screenWidth = width;
      const screenHeight = height;

      const baseWidth = 1920;
      const baseHeight = 1000;

      const scaleX = screenWidth / baseWidth;
      const scaleY = screenHeight / baseHeight;

      const maxScale = 1;
      const scale = Math.min(scaleX, scaleY, maxScale);

      if (containerRef.current) {
        containerRef.current.style.transform = `scale(${scale})`;
        containerRef.current.style.transformOrigin = 'top left';
        // containerRef.current.style.zoom = scale;
        setScale(scale);
      }
    }

    adjustScale();

    window.addEventListener('resize', adjustScale);

    return () => window.removeEventListener('resize', adjustScale);
  }, [width, height]);

  function redirectView(view: any) {
    if (hasRedirected) {
      return 0;
    }
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('view')) {
      const viewParam = urlParams.get('view')
      if (viewParam != null && viewParam === view) {
        setHasRedirected(true);
        setNameSelectedDashboard(viewParam);
      }
    }
  }

  async function getDataBackend() {
    const backendData = await getData();
    setDataBackend(backendData);
  }

  useEffect(() => {
    getDataBackend();
  }, [data])

  function getDashboard() {
    if (nameSelectedDashboard === 'Transbank') {
      return (
        <NewTransbank
          infoDashboard={infoTransbankDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Infraestructura Crítica') {
      return (
        <InfraCritica2
          infoDashboard={infoInfraCriticaDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Clientes indicadores') {
      return (
        <ClientesIndicadores
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          arrayHostsWeblogicInfo={arrayHostsWeblogicInfo}
          redHatStatus={redHatStatus}
          tanzuStatus={tanzuClientStatus}
          ldapStatus={ldapStatus}
          tuxedoStatus={tuxedoStatus}
          infoIndicatorClients={infoIndicatorClients}
          subscriptoresClientesIndicadores={subscriptoresClientesIndicadores}
          urlsClientesIndicadores={urlsClientesIndicadores}
        />
      );
    } else if (nameSelectedDashboard === 'Enlaces') {
      return (
        <Enlaces
          speedEnlaces={speedEnlaces}
          infoDashboard={infoEnlacesDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          scale={scale}
          overloadedSpeed={thresholdSpeed['overloaded']}
          unusedSpeed={thresholdSpeed['unused']}
        />
      );
    } else if (nameSelectedDashboard === 'Nucleo') {
      return (
        <Nucleo
          infoDashboard={infoNucleoDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Imed') {
      return (
        <Imed
          infoImed={infoImed}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Ficha Nucleo') {
      return (
        <NucleoDigital
          data={digiNucleo}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'VDI') {
      return (
        <VDI
          infoDashboard={infoVDIDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Tanzu') {
      return (
        <Tanzu
          tanzu={tanzu}
          cpuGraphData={cpuGraphData}
          memoryGraphData={memoryGraphData}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Base de Datos') {
      return (
        <BaseDeDatos
          infoDashboard={infoBdDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Firewall') {
      return (
        <Firewall
          infoDashboard={infoFirewallDashboard}
          scale={scale}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else {
      return <Central array_of_central_items={arrayCentralItems} selectDashboard={selectDashboard} />;
    }
  }

  async function getInfraCritica(setLoading: any) {
    const seriesInfraFiltered = infraFilter(dataBackend['infracritica'])
    const {
      vcenter,
      infoHostsWeblogic: weblogic,
      ldap,
      dynatrace,
      infraStatus,
      totalInfra,
      problemInfra,
      ldapStatus,
      tuxedoStatus: tuxedoSt,
      vcenterVDI,
      totalVDI,
      problemVDI,
      VDIstatus
    } = await createDataForInfraCriticaDashboard(seriesInfraFiltered, setLoading);
    const { hostsVDI, totalVDI: totalVDI2, problemVDI: problemVDI2, arrUrls: urlsVDI, status: VDIstatus2 } = await createDataForVDI(dataBackend['vdi'], dataBackend['TOCO_URLS'], VDIstatus);
    setInfraStatusTmp({ total: totalInfra, problems: problemInfra, status: infraStatus });
    setVDIStatus({ total: totalVDI + totalVDI2, problems: problemVDI + problemVDI2, status: VDIstatus2 })
    setInfoInfraCriticaDashboard({ vcenter, weblogic, ldap, dynatrace });
    setInfoVDIDashboard({ vcenter: vcenterVDI, toco: hostsVDI, tocourls: urlsVDI });
    setLdapStatus(ldapStatus);
    setTuxedoStatus(tuxedoSt)
    redirectView('Tanzu');
    redirectView('VDI');
  }

  async function getDigiNucleo() {
    const res = await appsByNucleoDigitalErrors(dataBackend['infracritica']);
    setDigiNucleo(res);
  }

  async function getFirewall() {
    const {
      infoHosts: firewallInfoDashboard,
      globalStatus: firewallGlobalStatus,
      total: firewallTotal,
      problems: firewallProblems,
    } = createDataForFirewall(dataBackend['firewall']);
    setInfoFirewallDashboard(firewallInfoDashboard);

    const indexTanzu = arrayCentralItems.findIndex((itm: any) => itm.title === 'Firewall');
    setArrayCentralItems((arrayCentralItems: any) => {
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexTanzu]['status'] = firewallGlobalStatus;
      copyArrayCentralItems[indexTanzu]['total'] = firewallTotal;
      copyArrayCentralItems[indexTanzu]['problems'] = firewallProblems;
      return copyArrayCentralItems;
    })
  }

  async function getTanzu() {
    const { cpuGraphData, memoryGraphData, tanzu } = await createDataForTanzu(dataBackend['infracritica']);
    setCpuGraphData(cpuGraphData);
    setMemoryGraphData(memoryGraphData);

    let tanzuProblems = 0;
    let kubernetesStatustemp = 'UNKNOWN';
    if (tanzu) {
      kubernetesStatustemp = 'OK'
      tanzu.forEach((tanzuElem: any) => {
        if (tanzuElem.status != 'OK') {
          tanzuProblems += 1
          if (kubernetesStatustemp === 'OK' || (kubernetesStatustemp === 'WARNING' && tanzuElem.status == 'CRITICAL')) {
            kubernetesStatustemp = tanzuElem.status;
          }
        }
      })
    }

    setKubernetesStatus(kubernetesStatustemp);
    const indexTanzu = arrayCentralItems.findIndex((itm: any) => itm.title === 'Tanzu');

    setArrayCentralItems((arrayCentralItems: any) => {
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexTanzu]['status'] = kubernetesStatustemp;
      copyArrayCentralItems[indexTanzu]['loading'] = false;
      copyArrayCentralItems[indexTanzu]['total'] = tanzu.length;
      copyArrayCentralItems[indexTanzu]['problems'] = tanzuProblems;
      return copyArrayCentralItems;
    });
    setTanzu(tanzu);
  }

  async function getBD() {
    const data_BD = dataBackend['basededatos'];
    const {
      infoHosts: infoBD,
      globalStatus: infoBDGlobalStatus,
      total: infoBDTotal,
      totalProblems: infoBDProblems } = await createDataForBD(data_BD);

    setInfoBdDashboard(infoBD);

    const indexBD = arrayCentralItems.findIndex((itm: any) => itm.title === 'Base de Datos');
    setArrayCentralItems((arrayCentralItems: any) => {
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexBD]['status'] = infoBDGlobalStatus;
      copyArrayCentralItems[indexBD]['total'] = infoBDTotal;
      copyArrayCentralItems[indexBD]['problems'] = infoBDProblems;
      return copyArrayCentralItems;
    });
  }

  // @ts-ignore
  useEffect(() => {
    // setLoadingInfraCritica();
    if (dataBackend['infracritica']) {
      getInfraCritica(setLoadingInfraCritica);
      redirectView('Infraestructura Crítica');
    }
    // getSubscriptorsClientesIndicadores();
    // @ts-ignore
  }, [dataBackend]);

  // @ts-ignore
  useEffect(() => {
    if (dataBackend['basededatos']) {
      getBD();
    }
    // @ts-ignore
  }, [dataBackend]);

  // @ts-ignore
  useEffect(() => {
    if (dataBackend['infracritica']) {
      getTanzu();
    }
    // @ts-ignore
  }, [dataBackend]);

  // @ts-ignore
  useEffect(() => {
    if (dataBackend['firewall']) {
      getFirewall();
    }
    // @ts-ignore
  }, [dataBackend]);

  // @ts-ignore
  useEffect(() => {
    if (dataBackend['clientesindicadores'])
      getSubscriptorsClientesIndicadores();
    if (infoIndicatorClients != null) {
      redirectView('Clientes indicadores');
    }
  }, [tuxedoStatus, dataBackend]);

  useEffect(() => {
    if (dataBackend['infracritica']) {
      getDigiNucleo();
      redirectView('Ficha Nucleo')
    }
  }, [dataBackend]);

  async function getSubscriptorsClientesIndicadores() {
    if (centralItemsTmp && centralItemsTmp.length) {
      let problems = 0;
      let total = 0;
      const clientesIndicadoresResponse = getClientesIndicadores(dataBackend['clientesindicadores']);
      const oracleInfo = clientesIndicadoresResponse['oracle'];
      const tykStatus = clientesIndicadoresResponse['tykStatus'];
      const cliIndStatus = clientesIndicadoresResponse['globalStatus'];
      const redHatStatus = clientesIndicadoresResponse['redHatStatus'];
      const arrayHostsWeblogicInfo = clientesIndicadoresResponse['arrayHostsWeblogicInfo'];
      const totalHostsWeblogic = clientesIndicadoresResponse['totalHostsWeblogic'];
      const problemsWeblogic = clientesIndicadoresResponse['problemsWeblogic'];
      setRedHatStatus(global_states[redHatStatus]);
      setArrayHostsWeblogicInfo(arrayHostsWeblogicInfo);
      //actualmente usado para roble Psol7, tyk, redhat
      total += 3;
      if (oracleInfo['status'] > 0) {
        problems += 1;
      }
      if (tykStatus > 0) {
        problems += 1;
      }
      if (redHatStatus > 0) {
        problems += 1;
      }
      total += totalHostsWeblogic;
      problems += problemsWeblogic;

      const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
      // ORACLE
      let infoIndicators = { oracleInfo: oracleInfo, tykStatus: tykStatus };
      // @ts-ignore
      setInfoIndicatorClients(infoIndicators);
      let infraResponse = null;
      let globalInfraDyna = 'UNDEFINED'
      if (dataBackend['infracritica']) {
        const { replicaClienteDynatraceStatus, total: totalInfraDyna, problems: problemsInfraDyna, globalStatus: globalStatusInfraDyna } = await getClientesIndicadoresSubscriptors(dataBackend['infracritica']);
        problems += problemsInfraDyna;
        total += totalInfraDyna; //22??
        globalInfraDyna = globalStatusInfraDyna;
        infraResponse = replicaClienteDynatraceStatus;
        setSubscriptoresClientesIndicadores(infraResponse);
      }
      // 7:10 - 7:14
      const tanzuStatus = Math.max(number_status[globalInfraDyna], tykStatus);
      setTanzuClientStatus(global_states[tanzuStatus]);

      if (ldapStatus && infoIndicatorClients && infraResponse) {
        let ldapStatusNumber = number_status[ldapStatus];
        //ldap
        total += 1;
        if (ldapStatusNumber > 0) {
          problems += 1;
        }
        //tuxedo
        total += 1;
        if (tuxedoStatus != 'OK') {
          problems += 1;
        }
        const {
          urls: infoV3,
          globalStatus: infoV3GlobalStatus,
          total: infoV3Total,
          problems: infoV3Problems,
        } = createDataURL(dataBackend['CLIENTES_V3_URLS'], true);
        total += infoV3Total;
        problems += infoV3Problems;
        const {
          urls: infoAzure,
          globalStatus: infoAzureGlobalStatus,
          total: infoAzureTotal,
          problems: infoAzureProblems,
        } = createDataURL(dataBackend['AZURE_URLS'], true);
        total += infoAzureTotal;
        problems += infoAzureProblems;
        const {
          urls: infoIndicadores,
          globalStatus: infoIndicadoresGlobalStatus,
          total: infoIndicadoresTotal,
          problems: infoIndicadoresProblems,
        } = createDataURL(dataBackend['INDICADORES_URLS'], true);
        total += infoIndicadoresTotal;
        problems += infoIndicadoresProblems;
        setUrlsClientesIndicadores({ V3: infoV3, Azure: infoAzure, Indicadores: infoIndicadores });
        let max = Math.max(...[
          ldapStatusNumber,
          number_status[tuxedoStatus],
          number_status[cliIndStatus]],
          number_status[infoV3GlobalStatus],
          number_status[globalInfraDyna],
          number_status[infoAzureGlobalStatus],
          number_status[infoIndicadoresGlobalStatus],
        );
        setArrayCentralItems((arrayCentralItems: any) => {
          const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
          copyArrayCentralItems[indexClientesIndicadores]['status'] = global_states[max];
          copyArrayCentralItems[indexClientesIndicadores]['loading'] = false;
          copyArrayCentralItems[indexClientesIndicadores]['total'] = total;
          copyArrayCentralItems[indexClientesIndicadores]['problems'] = problems;
          return copyArrayCentralItems;
        });
      }
    }
  }

  useEffect(() => {
    const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');
    const indexVDI = arrayCentralItems.findIndex((itm: any) => itm.title === 'VDI');
    setArrayCentralItems((arrayCentralItems: any) => {
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexInfraCrit]['status'] = infraStatusTmp.status;
      copyArrayCentralItems[indexInfraCrit]['total'] = infraStatusTmp.total;
      copyArrayCentralItems[indexInfraCrit]['problems'] = infraStatusTmp.problems;
      copyArrayCentralItems[indexVDI]['status'] = VDIStatus.status;
      copyArrayCentralItems[indexVDI]['total'] = VDIStatus.total;
      copyArrayCentralItems[indexVDI]['problems'] = VDIStatus.problems;
      return copyArrayCentralItems;
    });
  }, [infraStatusTmp, VDIStatus]);

  // @ts-ignore
  useEffect(() => {

    const {
      infoHosts: infoEnlacesDashboard,
      globalStatus: enlacesGlobalStatus,
      total: totalEnlaces,
      problems: problemsEnlaces,
    } = createDataForEnlacesDashboard(dataBackend['enlaces']);

    const seriesTransbankFiltered = transbankFilter(dataBackend['transbank']);

    const {
      infoHosts: transbankInfoDashboard,
      globalStatus: transbankGlobalStatus,
      total: transbankTotal,
      problems: transbankProblems,
    } = createDataForDashboard(seriesTransbankFiltered);

    const seriesNucleoFiltered = nucleoFilter(dataBackend['nucleo'])
    const {
      infoHosts: nucleoInfoDashboard,
      globalStatus: nucleoGlobalStatus,
      total: nucleoTotal,
      problems: nucleoProblems,
    } = createDataForDashboard(seriesNucleoFiltered, 'OK');
    const {
      urls: infoImedTemp,
      globalStatus: ImedGlobalStatus,
      total: ImedTotal,
      problems: ImedProblems,

    } = createDataURL(dataBackend['IMED_URLS'], true);
    setInfoImed(infoImedTemp);

    const {
      urls: infoRisAgfaTemp,
      globalStatus: RisAgfaGlobalStatus,
      total: RisAgfaTotal,
      problems: RisAgfaProblems,
    } = createDataURL(dataBackend['RIS_AGFA_URLS'], true);

    const indexEnlaces = arrayCentralItems.findIndex((itm: any) => itm.title === 'Enlaces');
    const indexTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Transbank');
    const nucleoTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Nucleo');
    const indexImed = arrayCentralItems.findIndex((itm: any) => itm.title === 'Imed');
    const indexRisAgfa = arrayCentralItems.findIndex((itm: any) => itm.title === 'RIS - AGFA');
    const nucleoDigitalTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Ficha Nucleo');

    setArrayCentralItems((arrayCentralItems: any) => {
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      // Cajas
      copyArrayCentralItems[nucleoTransbank]['status'] = nucleoGlobalStatus;
      copyArrayCentralItems[nucleoTransbank]['total'] = nucleoTotal;
      copyArrayCentralItems[nucleoTransbank]['problems'] = nucleoProblems;

      // Imed
      copyArrayCentralItems[indexImed]['status'] = ImedGlobalStatus;
      copyArrayCentralItems[indexImed]['total'] = ImedTotal;
      copyArrayCentralItems[indexImed]['problems'] = ImedProblems;

      // RIS - AFGA
      copyArrayCentralItems[indexRisAgfa]['status'] = RisAgfaGlobalStatus;
      copyArrayCentralItems[indexRisAgfa]['total'] = RisAgfaTotal;
      copyArrayCentralItems[indexRisAgfa]['problems'] = RisAgfaProblems;

      copyArrayCentralItems[indexEnlaces]['status'] = enlacesGlobalStatus;
      copyArrayCentralItems[indexEnlaces]['total'] = totalEnlaces;
      copyArrayCentralItems[indexEnlaces]['problems'] = problemsEnlaces;
      setInfoEnlacesDashboard(infoEnlacesDashboard);

      // Transbank
      copyArrayCentralItems[indexTransbank]['status'] = transbankGlobalStatus;
      copyArrayCentralItems[indexTransbank]['total'] = transbankTotal;
      copyArrayCentralItems[indexTransbank]['problems'] = transbankProblems;
      setInfoTransbankDashboard(transbankInfoDashboard);
      setCentralItemsTmp(copyArrayCentralItems);
      return copyArrayCentralItems;
    });

    setInfoNucleoDashboard(nucleoInfoDashboard);
    redirectView('Imed')
    redirectView('Transbank');
    redirectView('Nucleo');
  }, [dataBackend]);

  useEffect(() => {
    if (options && options.orderColumns) {
      setOrderColumns(JSON.parse(options.orderColumns));
    }
    if (options && options.enlacesSpeed) {
      setEnlacesSpeed(JSON.parse(options.enlacesSpeed));
    }
    if (options && options['enlace-critical-threshold'] && options['enlace-unused-threshold']) {
      setThresholdsSpeed({
        overloaded: options['enlace-critical-threshold'],
        unused: options['enlace-unused-threshold'],
      });
    }
    redirectView('Enlaces');
  }, [options]);

  return (
    <div
      className='panel-wrapper'
      ref={containerRef}
      style={{
        width: `1800px`,
        height: `1020px`,
        backgroundColor: '#d1def0',
        padding: '15px'
      }}
    >
      {getDashboard()}
    </div>
  );
};
