import axios from 'axios';

const headers = {
  Authorization:
    'Api-Token dt0c01.CBI5AGUA3WLTLHGF2IIUYQIP.JZEXA6DJN4L3AUEYUBYAGMG3MBAFEGPF3MN32AYLUOANOGGPGI7QZU36KSSZLHAC',
};

const DYNA_CLA = 'https://tpw16984.live.dynatrace.com/api/v2';

const fetchEntitie = async (endpoint) => {
  try {
    const response = await axios.get(`${DYNA_CLA}/${endpoint}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET:', error.message);
    //throw error;
  }
};

const getNamespaceIds = async (nameSpace, app) => {
  try {
    const response = await axios.get(`${DYNA_CLA}/entities?entitySelector=type(CLOUD_APPLICATION)%20AND%20entityName.equals(${app})%20AND%20toRelationship.IS_CLOUD_CONFIG_OF_CA(type(KUBERNETES_CLUSTER)%20AND%20entityName.equals(tanzu-prod))%20AND%20toRelationship.IS_NAMESPACE_OF_CA(type(CLOUD_APPLICATION_NAMESPACE)%20AND%20entityName.equals(${nameSpace}))`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solicitud GET para el id de la app ${app} en el namespace ${nameSpace}:`, error.message);
    //throw error;
  }
}

const getAppData = async (id) =>{
  try {
    const response = await axios.get(`${DYNA_CLA}/entities/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solicitud GET para app ${id}`, error.message);
  }
}

const getTanzuK8s = async () =>{
  try{
    const entitySelector = `type%28%22KUBERNETES_NODE%22%29AND%20toRelationship.IS_KUBERNETES_CLUSTER_OF_NODE%28type%28KUBERNETES_CLUSTER%29%20AND%20entityName.equals%28tanzu-prod%29%29&from=now-2h&fields=properties.kubernetesConditions`
    const response = await axios.get(`${DYNA_CLA}/entities?entitySelector=${entitySelector}`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solitud GET para gráfico ${component}`)
  }
}

const getCpuAndMemory = async () => {
  try{
    const metricSelector = `builtin:host.cpu.usage:last,builtin:host.mem.usage:last`
    const response = await axios.get(`${DYNA_CLA}/query?metricSelector=${metricSelector}`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solitud GET para gráfico ${component}`)
  }
}

const getGraphDataCPU = async (component) =>{
  try{
    const queryselector = `((builtin:kubernetes.node.${component}:filter(or(in("dt.entity.kubernetes_node",entitySelector("type(KUBERNETES_NODE) AND toRelationships.IS_KUBERNETES_CLUSTER_OF_NODE(type(KUBERNETES_CLUSTER),entityId(KUBERNETES_CLUSTER-99A036D68CDEBA6C))")))):splitBy("dt.entity.kubernetes_cluster"):sum:timeshift(-2m)/builtin:kubernetes.node.cpu_allocatable:filter(or(in("dt.entity.kubernetes_node",entitySelector("type(KUBERNETES_NODE) AND toRelationships.IS_KUBERNETES_CLUSTER_OF_NODE(type(KUBERNETES_CLUSTER),entityId(KUBERNETES_CLUSTER-99A036D68CDEBA6C))")))):splitBy("dt.entity.kubernetes_cluster"):sum:timeshift(-2m))*100):setUnit(Percent)`
    const response = await axios.get(`${DYNA_CLA}/metrics/query?metricSelector=${queryselector}` , { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solitud GET para gráfico ${component}`)
  }
}

const getGraphDataMemory = async (component) =>{
  try{
    const queryselector = `((builtin:kubernetes.node.${component}:filter(or(in("dt.entity.kubernetes_node",entitySelector("type(KUBERNETES_NODE) AND toRelationships.IS_KUBERNETES_CLUSTER_OF_NODE(type(KUBERNETES_CLUSTER),entityId(KUBERNETES_CLUSTER-99A036D68CDEBA6C))")))):splitBy("dt.entity.kubernetes_cluster"):sum:timeshift(-2m)/builtin:kubernetes.node.memory_allocatable:filter(or(in("dt.entity.kubernetes_node",entitySelector("type(KUBERNETES_NODE) AND toRelationships.IS_KUBERNETES_CLUSTER_OF_NODE(type(KUBERNETES_CLUSTER),entityId(KUBERNETES_CLUSTER-99A036D68CDEBA6C))")))):splitBy("dt.entity.kubernetes_cluster"):sum:timeshift(-2m))*100):setUnit(Percent)`
    const response = await axios.get(`${DYNA_CLA}/metrics/query?metricSelector=${queryselector}` , { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solitud GET para gráfico ${component}`)
  }
}

export default { fetchEntitie, getNamespaceIds, getAppData, getTanzuK8s, getCpuAndMemory, getGraphDataCPU, getGraphDataMemory  };
