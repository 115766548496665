// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tanzu-view {
    position: relative;
    color: #596c88;
    height: 100%;
    background-color: #f2f6fe;
}

.tanzu-view .back-icon {
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.tanzu-view .back-icon img {
    width: 40px;
}

.tanzu-view .dynatrace-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    align-items: center;
    height: calc(100% - 76px);
}

.tanzu-view .dynatrace-section .graphs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;
}

.tanzu-view .dynatrace-section .graphs .graph-container {
    width: 45%;
    height: 100%;
}

.tanzu-view .dynatrace-section .dyna-items {
    padding: 15px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 10px 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./views/Tanzu/Tanzu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,iDAAiD;IACjD,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,iDAAiD;IACjD,YAAY;AAChB","sourcesContent":[".tanzu-view {\n    position: relative;\n    color: #596c88;\n    height: 100%;\n    background-color: #f2f6fe;\n}\n\n.tanzu-view .back-icon {\n    position: absolute;\n    right: 50px;\n    top: 50px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n}\n\n.tanzu-view .back-icon img {\n    width: 40px;\n}\n\n.tanzu-view .dynatrace-section {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    padding: 5px;\n    border-radius: 10px;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    align-items: center;\n    height: calc(100% - 76px);\n}\n\n.tanzu-view .dynatrace-section .graphs {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    height: 50%;\n}\n\n.tanzu-view .dynatrace-section .graphs .graph-container {\n    width: 45%;\n    height: 100%;\n}\n\n.tanzu-view .dynatrace-section .dyna-items {\n    padding: 15px;\n    border-radius: 10px;\n    background-color: #ffffff;\n    margin: 10px 0;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
