// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info-fw{
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    border-radius: 10px;
    border: 2px dashed gray;
    background-color: #ffffff;
    margin: 5px;
    flex: 40%;
}

.card-info-fw .header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-info-fw .header .status{
    width: 24px;
    height: 24px;
    border-radius: 3px;
}

.card-info-fw .items-info{
    width: 100%;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./components/CardInfoFW/CardInfoFW.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".card-info-fw{\n    display: flex;\n    flex-direction: column;\n    padding: 0px 5px;\n    border-radius: 10px;\n    border: 2px dashed gray;\n    background-color: #ffffff;\n    margin: 5px;\n    flex: 40%;\n}\n\n.card-info-fw .header{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.card-info-fw .header .status{\n    width: 24px;\n    height: 24px;\n    border-radius: 3px;\n}\n\n.card-info-fw .items-info{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
