import React from 'react';
// @ts-ignore
import Toolbar from '../../components/Toolbar/Toolbar';
import AlemanaStatus from '../../components/AlemanaStatus/AlemanaStatus';
import CardIndicadores from '../../components/CardIndicardores/CardIndicadores';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import InfoHost from '../../components/InfoHost/InfoHost';
import InfoItemIndicadores from '../../components/InfoItemIndicadores/InfoItemIndicadores';
import { icons } from '../../utils/dictionaryIcons';
import { zabbix_states, number_status } from '../../utils/dictionaryStates';

import './ClientesIndicadores.css';

const alemanStatusArray = [
  {
    status: 'NOTDEFINED',
    icon: 'alemana_go',
  },
  {
    status: 'NOTDEFINED',
    title: 'Nuevas Agendas',
    icon: 'alemana_logo',
  },
  {
    status: 'NOTDEFINED',
    title: 'Movimiento Pacientes',
    icon: 'alemana_logo',
  },
];

const webLogicItems = {
  // SG_Parametros: {
  //   label: 'Sistema',
  //   status: 'NOTDEFINED',
  //   width: '220',
  // },
  Indicadores: {
    // label: 'Sistema',
    status: 'NOTDEFINED',
    width: '220',
  },
  'Hosts Indicadores': {
    label: 'Dispositivos',
    status: 'NOTDEFINED',
    width: '485',
    class: 'hosts',
    items: [
      {
        title: 'antofagasta01-1',
        status: 'NOTDEFINED',
        label_item: 'antofagasta01-1'
      },
      {
        title: 'antofagasta01-2',
        status: 'NOTDEFINED',
        label_item: 'antofagasta01-2'
      },
      {
        title: 'calama01-1',
        status: 'NOTDEFINED',
        label_item: 'calama01-1'
      },
      {
        title: 'calama01-2',
        status: 'NOTDEFINED',
        label_item: 'calama01-2'
      },
      {
        title: 'tocopilla01-1',
        status: 'NOTDEFINED',
        label_item: 'tocopilla01-1'
      },
      {
        title: 'tocopilla01-2',
        status: 'NOTDEFINED',
        label_item: 'tocopilla01-2'
      },
    ]
  },
  'Hosts Clientes': {
    label: 'Dispositivos',
    status: 'NOTDEFINED',
    width: '485',
    class: 'hosts',
    items: [
      {
        title: 'antofagasta05-1',
        status: 'NOTDEFINED',
        label_item: 'antofagasta05-1'
      },
      {
        title: 'calama05-1',
        status: 'NOTDEFINED',
        label_item: 'calama05-1'
      },
      {
        title: 'tocopilla05-1',
        status: 'NOTDEFINED',
        label_item: 'tocopilla05-1'
      },
    ]
  },
  V3: {
    label: 'Mantenedor de clientes',
    status: 'NOTDEFINED',
    width: '485',
    class: 'V3',
    items: [
      {
        title: 'Login',
        status: 'NOTDEFINED',
        label_item: 'Login'
      },
      {
        title: 'Interfaz Web',
        status: 'NOTDEFINED',
        label_item: 'Interfaz'
      },
      {
        title: 'Social',
        status: 'NOTDEFINED',
        label_item: 'Social'
      },
      {
        title: 'Fase 2 C. cliente',
        status: 'NOTDEFINED',
        label_item: 'Fase 2 cliente'
      },
    ],
  },
};

const oracle = {
  status: 0,
  items: {
    Clientes_V2: {
      label: 'Sistema',
      status: 4,
      width: '185',
    },
    SINDICADORES: {
      label: 'Sistema',
      status: 4,
      width: '185',
    },
    SGPARAMETROS: {
      label: 'Sistema',
      status: 4,
      width: '185',
    },
  },
};

const tanzuItems = {
  TYK: {
    label: 'Validacion de seguridad',
    status: 'NOTDEFINED',
    width: '198',
  },
  APIJUR: {
    label: 'API',
    status: 'NOTDEFINED',
    width: '198',
  },
  // APIOR: {
  //   label: 'API',
  //   status: 'NOTDEFINED',
  //   width: '198',
  // },
  APIPARAM: {
    label: 'API',
    status: 'NOTDEFINED',
    width: '198',
  },
  APICLI: {
    label: 'API principal',
    status: 'NOTDEFINED',
    width: '198',
    items: [
      {
        title: 'Operaciones',
        status: 'NOTDEFINED',
      },
      {
        title: 'Consultas',
        status: 'NOTDEFINED',
      },
    ],
  },
  V4: {
    label: 'Sistema',
    status: 'NOTDEFINED',
    width: '198',
    items: [
      {
        title: 'Sistema interno',
        status: 'NOTDEFINED',
      },
    ],
  },
  Replica_cliente: {
    label: 'Informacion del cliente',
    status: 'NOTDEFINED',
    items: [
      {
        title: 'Desencoladores',
        status: 'NOTDEFINED',
      },
      {
        title: 'Instancias Colas Nats Jetstream',
        status: 'NOTDEFINED',
        items: {
          SAP: {
            status: 'NOTDEFINED',
          },
          Roble: {
            status: 'NOTDEFINED',
          },
          Nucleo: {
            status: 'NOTDEFINED',
          },
          Ago: {
            status: 'NOTDEFINED',
          },
          Quimioprocess: {
            status: 'NOTDEFINED',
          },
          Fase_1: {
            status: 'NOTDEFINED',
          },
          Tuo_Tempo_Temuco: {
            status: 'NOTDEFINED',
          },
          Tuo_Tempo_Valdivia: {
            status: 'NOTDEFINED',
          },
          Salesforce: {
            status: 'NOTDEFINED',
          },
          Modulab: {
            status: 'NOTDEFINED',
          },
        },
      },
    ],
  },
  Red_Hat: {
    label: 'RHSSO',
    status: 'NOTDEFINED',
    icon: 'redhat',
    width: '237',
  },
  Azure: {
    label: 'Autentificacion',
    status: 'NOTDEFINED',
    icon: 'azure',
    width: '237',
  },
};

function ClientesIndicadores({
  selectMainDashboard,
  ldapStatus,
  infoIndicatorClients,
  tanzuStatus,
  redHatStatus,
  tuxedoStatus,
  subscriptoresClientesIndicadores,
  urlsClientesIndicadores,
  arrayHostsWeblogicInfo
}) {
  //console.log(urlsClientesIndicadores)
  // console.log(subscriptoresClientesIndicadores);
  let oracleInfo = infoIndicatorClients['oracleInfo'] || oracle;
  //console.log(oracleInfo)
  function getAlemanaStatus() {
    return alemanStatusArray.map((eachState, index) => {
      if (eachState.title && eachState.icon) {
        let statusAlemana = "NOTDEFINED";
        if (eachState.title === "Movimiento Pacientes" && subscriptoresClientesIndicadores["movPacientesStatus"]) {
          statusAlemana = subscriptoresClientesIndicadores["movPacientesStatus"];
        }
        const bodyAlemana = (
          <div className="alemana-status-body-title-icon">
            <div className="icon">
              <img src={icons[eachState.icon]} />
            </div>
            <div className="title">{eachState.title}</div>
          </div>
        );
        return (
          <AlemanaStatus status={statusAlemana} key={`${index}-each-icon-alemana-status`}>
            {bodyAlemana}
          </AlemanaStatus>
        );
      } else if (eachState.icon) {
        let statusAlemana = "NOTDEFINED";
        if (eachState.icon === "alemana_go" && subscriptoresClientesIndicadores["agoStatus"]) {
          statusAlemana = subscriptoresClientesIndicadores["agoStatus"];
        }
        const bodyAlemana = (
          <div className="alemana-status-body-icon">
            <img src={icons[eachState.icon]} />
          </div>
        );
        return (
          <AlemanaStatus status={statusAlemana} key={`${index}-each-icon-alemana-status`}>
            {bodyAlemana}
          </AlemanaStatus>
        );
      }
    });
  }

  function getDesencoladoresStatus(arrayStatus = []) {
    // const { jurCas, jurCat, jurCav, natCas, natCat, natCav } = subscriptoresClientesIndicadores;
    // const arrayStatus = [jurCas, jurCat, jurCav, natCas, natCat, natCav];
    // console.log(arrayStatus);
    let dequeueStatus = 'UNK';
    let someAlert = arrayStatus.some((item) => item === 'CRITICAL');
    if (!someAlert) {
      someAlert = arrayStatus.some((item) => item === 'WARNING');
      if (!someAlert) {
        someAlert = arrayStatus.some((item) => item === 'UNKNOWN' || item === 'UNK');
        if (!someAlert) {
          dequeueStatus = 'OK';
        }
      }
    }
    return dequeueStatus;
  }

  function getSecondColumnContent() {
    const items = [];
    const itemsIcons = [];
    for (const device in tanzuItems) {
      const itemsDevicesCmpt = [];
      const deviceInfo = tanzuItems[device];
      const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      let icon = null;
      if (deviceInfo.icon) {
        icon = <img src={icons[deviceInfo.icon]} style={{ width: '100%', height: 'auto' }} />;
        // console.log('if', deviceInfo);
        let cardIndStatus = deviceInfo.status;
        if (device === 'APIPARAM') {
          cardIndStatus = subscriptoresClientesIndicadores['paramStatus'] || 'UNK';
        } else if (device === 'APICLI') {
          cardIndStatus = subscriptoresClientesIndicadores['cliStatus'] || 'UNK';
        } else if (device === 'APIJUR') {
          cardIndStatus = subscriptoresClientesIndicadores['jurStatus'] || 'UNK';
        } else if (device === 'V4') {
          cardIndStatus = subscriptoresClientesIndicadores['v4Status'] || 'UNK';
        } else if (device === 'Azure') {
          cardIndStatus = urlsClientesIndicadores.Azure[0].status;
        } else if (device === 'Red_Hat') {
          cardIndStatus = redHatStatus;
        }
        itemsIcons.push(
          <div className={`${device}-class`}>
            <CardIndicadores
              icon={icon}
              title={device}
              label={deviceInfo.label}
              status={cardIndStatus}
              style={{
                width: `${deviceInfo.width}px`,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                backgroundColor: '#ffffff',
                border: '2px solid #76cce7',
              }}
            >
              {itemsDevicesCmpt}
            </CardIndicadores>
          </div>
        );
      } else {
        // console.log('else', deviceInfo);
        itemsDevice.forEach((itDev) => {
          if (itDev.title === 'Instancias Colas Nats Jetstream') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                // iconName={'query'}
                status={subscriptoresClientesIndicadores['natsStream'] || 'UNK'}
                itemName={itDev.title}
                // itemLabel={'Registro de pagos'}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Desencoladores') {
            const { jurCas, jurCat, jurCav, natCas, natCat, natCav } = subscriptoresClientesIndicadores;
            const arrayStatus = [jurCas, jurCat, jurCav, natCas, natCat, natCav];
            let dequeueStatus = getDesencoladoresStatus(arrayStatus);
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                // iconName={'query'}
                status={dequeueStatus || 'UNK'}
                itemName={itDev.title}
                // itemLabel={'Registro de pagos'}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Operaciones') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['cliOperaciones'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Consultas') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['cliConsultas'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Sistema interno') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['v4Status'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores status={itDev.status} itemName={itDev.title} key={`item-query1`} />
            );
          }
        });
        let cardIndStatus = deviceInfo.status;
        if (device === 'APIPARAM') {
          cardIndStatus = subscriptoresClientesIndicadores['paramStatus'] || 'UNK';
        } else if (device === 'APICLI') {
          cardIndStatus = subscriptoresClientesIndicadores['cliStatus'] || 'UNK';
        } else if (device === 'APIJUR') {
          cardIndStatus = subscriptoresClientesIndicadores['jurStatus'] || 'UNK';
        } else if (device === 'V4') {
          cardIndStatus = subscriptoresClientesIndicadores['v4Status'] || 'UNK';
        } else if (device === 'TYK') {
          cardIndStatus = infoIndicatorClients["tykStatus"] !== undefined ? zabbix_states[infoIndicatorClients["tykStatus"]] : 'UNK';
        } else if (device === 'Replica_cliente') {
          const { jurCas, jurCat, jurCav, natCas, natCat, natCav } = subscriptoresClientesIndicadores;
          const arrayStatus = [jurCas, jurCat, jurCav, natCas, natCat, natCav];
          // console.log(arrayStatus);
          let desStatus = getDesencoladoresStatus(arrayStatus);
          // console.log(desStatus);
          let natsStream = getDesencoladoresStatus([desStatus, subscriptoresClientesIndicadores['natsStream']]);
          // console.log(natsStream);
          cardIndStatus = natsStream;
        }
        items.push(
          <div className={`${device}-class`}>
            <CardIndicadores
              icon={icon}
              title={device}
              label={deviceInfo.label}
              status={cardIndStatus}
              style={{ width: `${deviceInfo.width}px` }}
            >
              {itemsDevicesCmpt}
            </CardIndicadores>
          </div>
        );
      }
    }

    return (
      <div
        className="card-wrap-second"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '140px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {itemsIcons}
        </div>
        <CardWrapper
          status={tanzuStatus}
          mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Tanzu'} ip={`IP 172.12.1.11`} />}
          items={items}
          style={{ width: '741px', height: '659px' }}
        />
      </div>
    );
  }

  function getThirdColumnContent() {
    const items = [];
    const itemsWeblogic = [];
    let V3;
    let Hosts;
    let HostsClientes;
    const itemsOracle = [];
    let webLogicStatus = 'OK';
    // const itemsIcons = [];
    for (const device in webLogicItems) {
      const itemsDevicesCmpt = [];
      const deviceInfo = webLogicItems[device];
      const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      let statusTemp = "OK"
      if (itemsDevice.length === 0) {
        statusTemp = 'NOTDEFINED';
        webLogicStatus = 'NOTDEFINED';
      }
      itemsDevice.forEach((itDev) => {
        let itemZabbix = undefined;
        if (device === 'V3') {
          itemZabbix = urlsClientesIndicadores.V3.find(url => url.label === itDev.label_item);
        }
        else if (device === 'Hosts Indicadores') {
          itemZabbix = arrayHostsWeblogicInfo.find(item => item.label === itDev.label_item);
        }
        else if (device === 'Hosts Clientes') {
          itemZabbix = arrayHostsWeblogicInfo.find(item => item.label === itDev.label_item);
        }
        const statusItem = itemZabbix ? itemZabbix.status : itDev.status;
        if (number_status[statusTemp] < number_status[statusItem]) {
          statusTemp = statusItem;
        }
        if (number_status[webLogicStatus] < number_status[statusItem]) {
          webLogicStatus = statusItem
        }
        itemsDevicesCmpt.push(
          <InfoItemIndicadores
            status={statusItem}
            itemName={itDev.title}
            style={{ width: "30%" }}
            key={`item-query1`}
          />
        );
      });
      if (device === "Indicadores" && urlsClientesIndicadores.Indicadores && urlsClientesIndicadores.Indicadores.length > 0) {
        statusTemp = "OK"
        webLogicStatus = 'OK';
        urlsClientesIndicadores.Indicadores.forEach((url) => {
          if (number_status[url.status] > number_status[statusTemp]) {
            statusTemp = url.status;
          }
          if (number_status[url.status] > number_status[webLogicStatus]) {
            webLogicStatus = url.status;
          }
        });
      }
      const card = (
        <div className={`${deviceInfo.class}-class`}>
          <CardIndicadores
            // icon={icon}
            title={device}
            label={deviceInfo.label}
            status={statusTemp}
            style={{ width: `${deviceInfo.width}px` }}
          >
            {<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>{itemsDevicesCmpt}</div>}
          </CardIndicadores>
        </div>
      )
      if (device === "V3") {
        V3 = card;
      }
      else if (device === 'Hosts Indicadores') {
        Hosts = card;
      }
      else if (device === 'Hosts Clientes') {
        HostsClientes = card;
      }
      else {
        itemsWeblogic.push(card)
      }
    }
    items.push(<div style={{ display: "flex", justifyContent: "end" }}>{itemsWeblogic}</div>)
    items.push(HostsClientes)
    items.push(Hosts)
    items.push(V3)

    for (const device in oracleInfo['items']) {
      // const itemsDevicesCmpt = [];
      const deviceInfo = oracleInfo['items'][device];
      // const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      // let icon = null;
      // itemsDevice.forEach((itDev) => {
      //   itemsDevicesCmpt.push(
      //     <InfoItemIndicadores
      //       // iconName={'query'}
      //       status={itDev.status}
      //       itemName={itDev.title}
      //       // itemLabel={'Registro de pagos'}
      //       key={`item-query1`}
      //     />
      //   );
      // });
      itemsOracle.push(
        <CardIndicadores
          // icon={icon}
          title={device}
          label={deviceInfo.label}
          status={zabbix_states[deviceInfo.status]}
          style={{ width: `${deviceInfo.width}px` }}
        />
      );
    }
    const oracleContent = <div style={{
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
    }}>{itemsOracle}</div>

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CardWrapper
          status={ webLogicStatus }
          mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Weblogic11'} ip={`IP 11.11.11.11`} />}
          items={items}
          style={{ width: '515px' }}
        />
        <div className='second-row'>
          <div className={`Ldap-class`}>
            <CardIndicadores
              icon={<img src={icons['ldap']} style={{ width: '100%', height: 'auto' }} />}
              title={'L-Dap'}
              label={'Autenticacion'}
              status={ldapStatus}
              style={{
                width: `237px`,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                backgroundColor: '#ffffff',
                border: '2px solid #76cce7',
              }}
            ></CardIndicadores>
          </div>
          <CardWrapper
            status={tuxedoStatus}
            mainContent={<InfoHost iconName={'computer2'} type={'Servidor'} hostname={'Tuxedo'} ip={`Middleware`} />}
            // items={items}
            style={{ width: '226px' }}
          />
        </div>
        <CardWrapper
          status={zabbix_states[oracleInfo['status']]}
          mainContent={<InfoHost iconName={'database'} type={'Servidor'} hostname={'ORACLE'} ip={`BD Roble`} />}
          items={oracleContent}
          style={{ width: '474px' }}
        />
      </div>

    );
  }

  // function getWebLogic() {
  //   return (
  //     <CardWrapper
  //       status={'NOTDEFINED'}
  //       mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Weblogic11'} ip={`IP 11.11.11.11`} />}
  //       items={items}
  //       style={{ width: '235px', height: '509px' }}
  //     />
  //   );
  // }
  // console.log(subscriptoresClientesIndicadores);

  return (
    <section className="clientes-indicadores-view">
      <Toolbar title={`Clientes Indicadores`} />
      <div className="content">
        {/* <div className="first-column">
          <div className="first-column-content">
            <div className="cc-item"></div>
            <div className="cc-item items">{getAlemanaStatus()}</div>
            <div className="cc-item"></div>
          </div>
        </div> */}
        <div className="second-column">
          {getSecondColumnContent()}
          <div className="subscriptors-fase1" style={{ bottom: '99px', left: '530px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['fase1'] || 'UNK'} itemName={'Fase 1'} />
          </div>
          <div className="subscriptors-salesforce" style={{ bottom: '74px', left: '530px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['salesforce'] || 'UNK'}
              itemName={'Salesforce'}
            />
          </div>
          <div className="subscriptors" style={{ bottom: '49px', left: '530px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['robleStatus'] || 'UNK'} itemName={'Roble'} />
          </div>
          <div className="subscriptors-temuco" style={{ bottom: '99px', left: '715px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['temuco'] || 'UNK'}
              itemName={'Tuo Tempo Temuco'}
            />
          </div>
          <div className="subscriptors-valdivia" style={{ bottom: '74px', left: '715px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['valdivia'] || 'UNK'}
              itemName={'Tuo Tempo Valdivia'}
            />
          </div>

          <div className="subscriptors" style={{ bottom: '49px', left: '715px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['agoStatus'] || 'UNK'} itemName={'Ago'} />
          </div>
          <div className="subscriptors" style={{ bottom: '99px', left: '345px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['sapStatus'] || 'UNK'} itemName={'SAP'} />
          </div>
          <div className="subscriptors" style={{ bottom: '74px', left: '345px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['quimioStatus'] || 'UNK'}
              itemName={'Quimioprocess'}
            />
          </div>
          <div className="subscriptors" style={{ bottom: '99px', left: '160px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['sistdepStatus'] || 'UNK'}
              itemName={'Sist. Dep'}
            />
          </div>
          <div className="subscriptors" style={{ bottom: '74px', left: '160px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['nucleoStatus'] || 'UNK'}
              itemName={'Nucleo'}
            />
          </div>
          {/* DESENCOLADORES INICIO*/}
          <div className="subscriptors" style={{ bottom: '468px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['jurCas'] || 'UNK'} itemName={'jur-cas'} />
          </div>
          <div className="subscriptors" style={{ bottom: '442px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['jurCat'] || 'UNK'} itemName={'jur-cat'} />
          </div>
          <div className="subscriptors" style={{ bottom: '416px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['jurCav'] || 'UNK'} itemName={'jur-cav'} />
          </div>
          <div className="subscriptors" style={{ bottom: '390px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['natCas'] || 'UNK'} itemName={'nat-cas'} />
          </div>
          <div className="subscriptors" style={{ bottom: '364px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['natCat'] || 'UNK'} itemName={'nat-cat'} />
          </div>
          <div className="subscriptors" style={{ bottom: '338px', left: '435px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['natCav'] || 'UNK'} itemName={'nat-cav'} />
          </div>
          {/* <div className="subscriptors" style={{ bottom: '338px', left: '347px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['natCavSrv'] || 'UNK'}
              itemName={'nat-cav-srv'}
            />
          </div> */}
          {/* DESENCOLADORES FIN */}
        </div>
        <div className="third-column">
          {getThirdColumnContent()}
        </div>
        <div className="fourth-column"></div>
        {/* FLECHAS DE LA IZQUIERDA HACIA ALEMANA GO */}
        {/* <div className="line" style={{ width: '3px', height: '205px', top: '335px', left: '300px' }}></div>
        <div className="line" style={{ width: '74px', top: '335px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '330px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '339px', left: '224px' }}></div>

        <div className="line" style={{ width: '124px', top: '437px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '432px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '441px', left: '224px' }}></div>

        <div className="line" style={{ width: '74px', top: '537px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '532px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '541px', left: '224px' }}></div> */}

        {/* ORACLE - WEBLOGIC */}
        <div className="line" style={{ width: '3px', height: '587px', top: '171px', left: '1673px' }}></div>
        <div className="line" style={{ width: '31px', top: '171px', left: '1643px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '167px', left: '1641px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '175px', left: '1641px' }}></div>

        <div className="line" style={{ width: '54px', top: '755px', left: '1622px' }}></div>

        {/* V3 - TUXEDO */}
        <div className="line" style={{ width: '3px', height: '21px', top: '583px', left: '1533px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '597px', left: '1532px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '597px', left: '1524px' }}></div>

        {/* TUXEDO - ORACLE */}
        <div className="line" style={{ width: '3px', height: '20px', top: '671px', left: '1533px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '674px', left: '1524px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '674px', left: '1532px' }}></div>

        {/* LDAP - Weblogic */}
        <div className="line" style={{ width: '3px', height: '29px', top: '583px', left: '1219px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '604px', left: '1219px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '604px', left: '1208px' }}></div>

        {/* INDICADORES - TYK */}
        <div className="line" style={{ width: '3px', height: '77px', top: '0px', left: '1570px' }}></div>
        <div className="line" style={{ width: '1268px', top: '0px', left: '305px' }}></div>
        <div className="line" style={{ width: '3px', height: '420px', top: '0px', left: '305px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '416px', left: '304px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '416px', left: '295px' }}></div>

        {/* V3 - TYK */}
        <div className="line" style={{ width: '70px', top: '470px', left: '1072px' }}></div>
        <div className="line" style={{ width: '3px', height: '450px', top: '23px', left: '1072px' }}></div>
        <div className="line" style={{ width: '735px', top: '20px', left: '340px' }}></div>
        <div className="line" style={{ width: '3px', height: '399px', top: '23px', left: '340px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '416px', left: '339px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '416px', left: '330px' }}></div>

        {/* CLIENTES_V2 - TANZU */}
        <div className="line" style={{ width: '196px', top: '755px', left: '951px' }}></div>
        <div className="line" style={{ width: '3px', height: '445px', top: '313px', left: '948px' }}></div>
        <div className="line" style={{ width: '76px', top: '313px', left: '875px' }}></div>
        <div className="line" style={{ width: '76px', top: '470px', left: '875px' }}></div>
        <div className="line" style={{ width: '76px', top: '606px', left: '875px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '309px', left: '874px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '317px', left: '874px' }}></div>

        <div className="bottom-arrow" style={{ width: '14px', top: '464px', left: '874px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '474px', left: '874px' }}></div>

        <div className="bottom-arrow" style={{ width: '14px', top: '602px', left: '874px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '610px', left: '874px' }}></div>

        {/* CLIENTES_V2 - APIOR */}
        <div className="line" style={{ width: '26px', top: '313px', left: '636px' }}></div>
        <div className="line" style={{ width: '3px', height: '230px', top: '313px', left: '660px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '309px', left: '635px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '317px', left: '635px' }}></div>
        <div className="line" style={{ width: '289px', top: '540px', left: '660px' }}></div>

        {/* CLIENTES_V2 - APIJUR */}
        {/* <div className="line" style={{ width: '26px', top: '423px', left: '834px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '419px', left: '833px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '427px', left: '833px' }}></div> */}


        {/* V4 - TYK */}
        <div className="line" style={{ width: '3px', height: '31px', top: '482px', left: '265px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '485px', left: '264px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '485px', left: '255px' }}></div>

        {/* DESENCOLADORES */}
        <div className="line" style={{ width: '3px', height: '275px', top: '390px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '390px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '416px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '442px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '468px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '494px', left: '400px' }}></div>
        <div className="line" style={{ width: '36px', top: '520px', left: '400px' }}></div>
        <div className="line" style={{ width: '48px', top: '663px', left: '400px' }}></div>
        {/* TYK - REDHAT */}

        {/* Replica_cliente - subscriptores */}
        <div className="line" style={{ width: '4px', height: '34px', bottom: '154px', left: '654px' }}></div>
        <div className="line" style={{ width: '559px', height: '3px', bottom: '154px', left: '240px' }}></div>
        <div className="line" style={{ width: '3px', height: '32px', bottom: '125px', left: '240px' }}></div>
        <div className="line" style={{ width: '3px', height: '32px', bottom: '125px', left: '420px' }}></div>
        <div className="line" style={{ width: '3px', height: '32px', bottom: '125px', left: '606px' }}></div>
        <div className="line" style={{ width: '3px', height: '32px', bottom: '125px', left: '799px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '743px', left: '798px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '743px', left: '789px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '743px', left: '605px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '743px', left: '596px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '743px', left: '419px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '743px', left: '410px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '743px', left: '239px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '743px', left: '230px' }}></div>
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default ClientesIndicadores;
