import axios from 'axios';

const headers = {
  Authorization:
    'Api-Token dt0c01.CBI5AGUA3WLTLHGF2IIUYQIP.JZEXA6DJN4L3AUEYUBYAGMG3MBAFEGPF3MN32AYLUOANOGGPGI7QZU36KSSZLHAC',
};

const DYNA_CLA = 'https://tpw16984.live.dynatrace.com/api/v2';

const fetchEntitie = async (endpoint) => {
  try {
    const response = await axios.get(`${DYNA_CLA}/${endpoint}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET:', error.message);
    //throw error;
  }
};

const getNamespaceIds = async (nameSpace, app) => {
  try {
    const response = await axios.get(`${DYNA_CLA}/entities?entitySelector=type(CLOUD_APPLICATION)%20AND%20entityName.equals(${app})%20AND%20toRelationship.IS_CLOUD_CONFIG_OF_CA(type(KUBERNETES_CLUSTER)%20AND%20entityName.equals(tanzu-prod))%20AND%20toRelationship.IS_NAMESPACE_OF_CA(type(CLOUD_APPLICATION_NAMESPACE)%20AND%20entityName.equals(${nameSpace}))`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solicitud GET para el id de la app ${app} en el namespace ${nameSpace}:`, error.message);
    //throw error;
  }
}

const getAppData = async (id) =>{
  try {
    const response = await axios.get(`${DYNA_CLA}/entities/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Hubo un error al hacer la solicitud GET para app ${id}`, error.message);
  }
}

export default { fetchEntitie, getNamespaceIds, getAppData };
