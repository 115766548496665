import AxiosFactory from './Axios';
const pako = require('pako');

const baseURL = 'https://dashboards.dparadig.com:8099'

function recoverOriginalContent(compressedBase64) {
    try {
        const compressedData = atob(compressedBase64);
        const compressedArray = new Uint8Array(compressedData.length);
        for (let i = 0; i < compressedData.length; i++) {
            compressedArray[i] = compressedData.charCodeAt(i);
        }
        const decompressedArray = pako.inflate(compressedArray);
        const originalString = new TextDecoder().decode(decompressedArray);
        return originalString;
    } catch (error) {
        console.error('Error recovering original content:', error);
        return null;
    }
}

export async function getData() {
    let response;
    const checkmkApi = AxiosFactory(baseURL);
    response = await checkmkApi.get('cla/data');
    if (response.data && response.data.compressed) {
        const decoded = recoverOriginalContent(response.data.data);
        response = JSON.parse(decoded);
        return response;
    } else {
        return response.data;
    }
}
