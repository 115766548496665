import React from 'react';
import { color_status } from '../../utils/dictionaryStates';
import './CardIndicadores.css';

function CardIndicadores({ icon, title, label, children, status, style }) {
  return (
    <section className="card-indicadores-cmpt" style={style}>
      <div className="header">
        <div className="left-content">
          <div style={{ width: icon ? '30px' : '', height: icon ? '30px' : '' }}>{icon}</div>
          <div className="text-info">
            <div className="title">{title}</div>
            <div className="label-ti">{label}</div>
          </div>
        </div>
        <div className="right-content">
          <div className="status" style={{ background: color_status[status] }}></div>
        </div>
      </div>
      <div className="body">{children}</div>
    </section>
  );
}

export default CardIndicadores;
