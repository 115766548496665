import { zabbix_states, color_status, number_status, global_states } from '../utils/dictionaryStates';
import { dictHypervisors } from '../utils/dictionaryHypervisors';
import api from './requests';
import {
  nameSpace,
  arrayDigitalWorkflow,
  arrayFarmacyWorkflow,
  arrayMantainerWorkflow,
  arrayNucleoWorkflow,
  arrayFrontNucleoDigital, 
  arrayBackNucleoDigital
} from '../utils/applicationsByNucleo';
import {
  nameSpaceReplicaCliente,
  arrayReplicaClienteApps,
  nameSpaceTuoTempoTemuco,
  nameSpaceTuoTempoValdivia,
  nameSpaceSalesforce,
  nameSpaceApiClientes,
  arrayApiClientes,
  nameSpaceApiParam,
  arrayApiParam,
  nameSpaceClientesV4,
  arrayClientesV4,
  nameSpaceRoble,
  arrayRoble,
  nameSpaceAgo,
  arrayAgo,
  nameSpaceQuimio,
  arrayQuimio,
  nameSpaceSistdep,
  arraySistdep,
  nameSpaceNucleo,
  arrayNucleo,
  nameSpaceSap,
  arraySap,
  nameSpaceMovPacientes,
  arrayMovPacientes
} from '../utils/applicationsByReplicaCliente';

export function processData(data) {
  const series = {};
  data.series.forEach((serie) => {
    if (serie.fields) {
      serie.fields.forEach((field) => {
        const values = field.values.length ? field.values : [];
        const [category, host] = serie.name.split(':');
        if (!series[category]) {
          series[category] = {};
        }
        if (!series[category][host]) {
          series[category][host] = [];
        }
        values.forEach((value, index) => {
          if (!series[category][host][index]) {
            series[category][host].push({});
          }
          if ((field.name === 'tags' || field.name === 'triggers') && typeof value === 'string') {
            series[category][host][index][field.name] = JSON.parse(value);
          } else {
            series[category][host][index][field.name] = value;
          }
        });
      });
    }
  });
  return JSON.parse(JSON.stringify(series));
}

export function createDataForDashboard(infoHosts, default_state = 'UNK') {
  // console.log(infoHosts);

  const objectHosts = {};
  let auxPriority = 0;
  let globalStatus = default_state;
  let problems = 0;
  let total = 0;
  // const dict
  for (const host in infoHosts) {
    objectHosts[host] = { items: [] };
    const itemsByHosts = infoHosts[host];

    const interfaces = {};
    itemsByHosts.forEach((item) => {
      if (item.name.includes('{#FSLABEL}') && (host === 'maullinx.alemana.cl' || host === 'algarrobo0.alemana.cl')) {
      } else {
        let status = default_state;
        const dictTags = {};
        const arrayTags = item.tags && item.tags.length ? item.tags : [];
        arrayTags.forEach((infoTag) => {
          dictTags[infoTag.tag] = infoTag.value;
        });
        if (item.triggerid) {
          // status = 'UNK';

          if (item.value != 0) {
            status = zabbix_states[item.priority];
            if (zabbix_states[item.priority] >= auxPriority) {
              globalStatus = zabbix_states[item.priority];
            }
            problems++;
          } else {
            if (globalStatus === 'UNK') {
              globalStatus = 'OK';
            }
            if (status === 'UNK') {
              status = 'OK';
            }
          }
        } else {
          if (item.triggers) {
            // status = 'UNK';
            // console.log(item);
            // const triggers = JSON.parse(item.triggers);
            let tmpProblem = false;
            // console.log(triggers);
            // let globalStatus = triggers.some(trg => trg.value != 0);
            item.triggers.forEach((trg) => {
              if (
                parseInt(trg.value, 10) !== 0
                // parseInt(trg.priority, 10) >= zabbix_states[parseInt(trg.priority, 10)]
              ) {
                if (parseInt(trg.priority, 10) >= auxPriority) {
                  globalStatus = zabbix_states[parseInt(trg.priority, 10)];
                  auxPriority = globalStatus
                }
                // if (trg.priority) {
                // }
                status = zabbix_states[parseInt(trg.priority, 10)];
                tmpProblem = true;
              } else {
                if (globalStatus === 'UNK') {
                  globalStatus = 'OK';
                }
              }
              if (status === 'UNK') {
                status = 'OK';
              }
            });
            if (tmpProblem) {
              problems++;
            }
          }
        }
        if (!objectHosts[host]['ip'] && dictTags['dashboard_host_ip']) {
          objectHosts[host]['ip'] = dictTags['dashboard_host_ip'];
        }
        if (!objectHosts[host]['name'] && dictTags['dashboard_host_name']) {
          objectHosts[host]['name'] = dictTags['dashboard_host_name'];
        }
        if (item.name === 'ICMP ping') {
          total++;
          objectHosts[host]['status'] = status;
          objectHosts[host]['ip'] = dictTags['dashboard_host_ip'];
          objectHosts[host]['name'] = dictTags['dashboard_host_name'];
          objectHosts[host]['type'] = dictTags['dashboard_host_type'];
          objectHosts[host]['iconName'] = dictTags['dashboard_icon_name'];
          objectHosts[host]['custom_desc'] = dictTags['custom_desc'];
        } else if (item.name.includes('Interfaz ')) {
          total++;
          const [, nameInterface] = item.name.split('Interfaz ');
          const itemInterface = {
            status: status,
            name: dictTags['dashboard_item_name'],
            label: dictTags['dashboard_item_label'],
            iconName: dictTags['dashboard_icon_name'],
          };
          interfaces[nameInterface] = itemInterface;
        } else if (item.name.includes('Inbound Bandwidth ')) {
          const [, nameInterface] = item.name.split('Inbound Bandwidth ');
          let value = parseFloat(item.lastvalue).toFixed(2);
          interfaces[nameInterface]['inbound'] = value;
        } else if (item.name.includes('Outbound Bandwidth ')) {
          const [, nameInterface] = item.name.split('Outbound Bandwidth ');
          let value = parseFloat(item.lastvalue).toFixed(2);
          interfaces[nameInterface]['outbound'] = value;
        } else {
          let newName = '';
          if (item.name.includes('{#FSLABEL}')) {
            const arrayItName = item.name.split('{#FSLABEL}');
            newName = arrayItName[1];
          }
          total++;
          const itemCreated = {
            status: status,
            name: dictTags['dashboard_item_name'] || newName,
            label: dictTags['dashboard_item_label'] || 'Infraestructura',
            iconName: dictTags['dashboard_icon_name'] || 'query',
          };
          objectHosts[host]['items'].push(itemCreated);
        }
      }
    });
    for (const interf in interfaces) {
      const element = interfaces[interf];
      objectHosts[host]['items'].push(element);
    }
  }
  // console.log(objectHosts);

  return { infoHosts: objectHosts, globalStatus, total, problems };
}

export function transbankFilter(infoHosts){
  const infoHostsFiltered = {};
  for (const key in infoHosts) {
    let data = infoHosts[key];
    if(key === "ASTON"){
      const dataFiltered = []; 
      data.forEach(service => {
        if(!service.name.startsWith("{#FSLABEL}")){
          dataFiltered.push(service);
        }
      });
      infoHostsFiltered[key] = dataFiltered;
    }
    else{
      infoHostsFiltered[key] = data;
    }
  }
  return infoHostsFiltered;
}

export function nucleoFilter(infoHosts){
  const infoHostsFiltered = {};
  for (const key in infoHosts) {
    let data = infoHosts[key];
    if(key === "CHINA"){
      const dataFiltered = []; 
      data.forEach(service => {
        if(!service.name.startsWith("{#FSLABEL}")){
          dataFiltered.push(service);
        }
      });
      infoHostsFiltered[key] = dataFiltered;
    }
    else if(key === "HEIMDAL" || key == "ANTILLANCA"){
      const dataFiltered = []; 
      data.forEach(service => {
        if(!service.name.includes("Space utilization")){
          dataFiltered.push(service);
        }
      });
      infoHostsFiltered[key] = dataFiltered;
    }
    else{
      infoHostsFiltered[key] = data;
    }
  }
  return infoHostsFiltered;
}

export function infraFilter(infoHosts){
  const infoHostsFiltered = {};
  for (const key in infoHosts) {
    let data = infoHosts[key];
    if(key === "hypervisor"){
      const dataFiltered = []; 
      data.forEach(service => {
        if(!service.name.includes("Free space on datastore")){
          dataFiltered.push(service);
        }
      });
      infoHostsFiltered[key] = dataFiltered;
    }
    else{
      infoHostsFiltered[key] = data;
    }
  }
  return infoHostsFiltered;
}


function hypervisorCheck(hypervisorItem,  vcenterArray, globalStatus){
  let globalStatusChange = false;
  let globalStatusPrio = 3;
  let problemsAdd = 0;
  let totalAdd = 0;
  let { name, hostname, triggers: triggers, lastvalue } = hypervisorItem;
  if (!vcenterArray[dictHypervisors[hostname]][hostname]) {
    vcenterArray[dictHypervisors[hostname]][hostname] = {};
  }
  let statusTmp = 0;

  triggers.forEach((trg) => {
    if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
      statusTmp = trg.priority;
    }
    if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
      globalStatusChange = true;
      globalStatusPrio = trg.priority
    }
  });
  if (statusTmp !== 0) {
    problemsAdd++;
    // console.log(hypervisorItem);
  }
  totalAdd++;
  const statusItem = zabbix_states[statusTmp];

  if (name.includes('ping')) {
    vcenterArray[dictHypervisors[hostname]][hostname] = { status: statusItem };
  } else if (name.includes('Uptime')) {
    vcenterArray[dictHypervisors[hostname]][hostname]['Uptime'] = { status: statusItem, value: lastvalue };
  } else if (name.includes('Overall status')) {
    vcenterArray[dictHypervisors[hostname]][hostname]['Overall_status'] = { status: statusItem, value: lastvalue };
  } else if (name.includes('CPU usage in percents')) {
    vcenterArray[dictHypervisors[hostname]][hostname]['CPU'] = { status: statusItem, value: lastvalue };
  } else if (name.includes('datastore')) {
    const parts = name.split(' ');
    const resultArray = parts.slice(5, -1);
    const datastoreName = resultArray.join(' ');
    if (!vcenterArray[dictHypervisors[hostname]][hostname]['datastore']) {
      vcenterArray[dictHypervisors[hostname]][hostname]['datastore'] = [];
    }
    vcenterArray[dictHypervisors[hostname]][hostname]['datastore'].push({
      name: datastoreName,
      status: statusItem,
      value: 100 - parseInt(lastvalue, 10),
    });
  } else if (name.includes('Memory usage in percents')) {
    vcenterArray[dictHypervisors[hostname]][hostname]['Memory'] = { status: statusItem, value: lastvalue };
  }
  return {vcenterArray, globalStatusChange, globalStatusPrio, problemsAdd, totalAdd}
}

export async function createDataForVDI(infoHosts, infoURLs,VDIstatus){
  let hostsVDI = {};
  let totalVDI = 0;
  let problemVDI = 0;
  let vdiGlobalStatus = 0;
  Object.entries(infoHosts).forEach(([host, services]) => {
    hostsVDI[host] = {}
    services.forEach((service) =>{
      let statusTmp = 0;

      service.triggers.forEach((trg) => {
        if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
          statusTmp = trg.priority;
        }
        if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > vdiGlobalStatus) {
          vdiGlobalStatus = trg.priority;
        }
      });

      if (statusTmp !== 0) {
        problemVDI++;
      }
      totalVDI += 1;
      const statusItem = zabbix_states[statusTmp];
      if (service.name.includes('ICMP ping')) {
        hostsVDI[host].status = statusItem;
      } else if (service.name.includes('CPU utilization')) {
        hostsVDI[host]['CPU'] = { status: statusItem};
      } else if (service.name.includes('Memory utilization')) {
        hostsVDI[host]['Memory'] = { status: statusItem};
      } else if (service.name.includes('(C:): Space utilization')) {
        hostsVDI[host]['Disk C'] = { status: statusItem};
      } else if (service.name.includes('(E:): Space utilization')) {
        hostsVDI[host]['Disk E'] = { status: statusItem};
      }

      
    })
  });
  let status = zabbix_states[Math.max(vdiGlobalStatus, VDIstatus)]


  const arrUrls = [];
  let globalUrlStatus = 'OK';
  let totalUrl = 0;
  if(infoURLs){
    infoURLs.forEach(element => {
      let url = element.description.split(' ')[1];
      let name = element.tags.find((tag) => tag.tag === 'dashboard_item_name').value;
      let label = element.tags.find((tag) => tag.tag === 'dashboard_item_label').value;
      let urlstatus = 'OK';
      if(element.value != 0){
        urlstatus = zabbix_states[element.priority]
        if(element.priority > 1){
          problemVDI +=1;
          if(element.priority > 3){
            globalUrlStatus = 'CRITICAL';
          }
          if(element.priority < 4 && globalUrlStatus !== 'CRITICAL'){
            globalUrlStatus = 'WARNING';
          }
        }
      }
      arrUrls.push({url: url, name: name, label: label, status: urlstatus})
    });
    totalUrl =  infoURLs.length;
  }
  totalVDI += totalUrl;


  let num_status = Math.max(number_status[status], number_status[globalUrlStatus])
  status = global_states[num_status];
  // return { urls: arrUrls, globalStatus, total, problems };

  return {hostsVDI, totalVDI, problemVDI, arrUrls ,status}
}

export async function createDataForTanzu(infoHosts) {
  const datasetsCPU = [];
  let timestampsCPU = [];

  // Object with label and associated colors
  const labelColorsCPU = {
    'cpu_usage': { borderColor: 'rgba(75, 192, 192, 1)', backgroundColor: 'rgba(75, 192, 192, 0.2)' },
    'requests_cpu': { borderColor: 'rgba(255, 99, 132, 1)', backgroundColor: 'rgba(255, 99, 132, 0.2)' },
    'limits_cpu': { borderColor: 'rgba(54, 162, 235, 1)', backgroundColor: 'rgba(54, 162, 235, 0.2)' }
  };

  // Iterate over the labelColors object
  for (const label in labelColorsCPU) {
    const response = await api.getGraphDataCPU(label);
    const timestamps = response.result?.[0]?.data?.[0]?.timestamps || [];
    const values = response.result?.[0]?.data?.[0]?.values || [];

    // Convert timestamps to date and time strings
    const dates = timestamps.map(timestamp => {
      const date = new Date(timestamp);
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      });
    });

    timestampsCPU = dates;

    // Get the color settings from the labelColors object
    const { borderColor, backgroundColor } = labelColorsCPU[label];

    datasetsCPU.push({
      label: label,
      data: values,
      tension: 0.4,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      pointBorderColor: borderColor,
      pointBackgroundColor: backgroundColor,
      pointRadius: 4
    });
  }

  const datasetsMemory = [];
  let timestampsMemory = [];
  const labelColorsMemory = {
    'memory_working_set': { borderColor: 'rgba(75, 192, 192, 1)', backgroundColor: 'rgba(75, 192, 192, 0.2)' },
    'requests_memory': { borderColor: 'rgba(255, 99, 132, 1)', backgroundColor: 'rgba(255, 99, 132, 0.2)' },
    'limits_memory': { borderColor: 'rgba(54, 162, 235, 1)', backgroundColor: 'rgba(54, 162, 235, 0.2)' }
  };

  for (const label in labelColorsMemory) {
    const response = await api.getGraphDataMemory(label);
    const timestamps = response.result?.[0]?.data?.[0]?.timestamps || [];
    const values = response.result?.[0]?.data?.[0]?.values || [];

    // Convert timestamps to date and time strings
    const dates = timestamps.map(timestamp => {
      const date = new Date(timestamp);
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      });
    });

    timestampsMemory = dates;

    // Get the color settings from the labelColors object
    const { borderColor, backgroundColor } = labelColorsMemory[label];

    datasetsMemory.push({
      label: label,
      data: values,
      tension: 0.4,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      pointBorderColor: borderColor,
      pointBackgroundColor: backgroundColor,
      pointRadius: 4
    });
  }

  const tanzuKubernetes = await api.getTanzuK8s();
  console.log(tanzuKubernetes)
  const copyInfoHosts = infoHosts;

  //const kubernetesProblems = copyInfoHosts['tanzuProblems']['problems'] || [];

  const arrayProblem = [];

  // kubernetesProblems.forEach((kbProblem) => {
  //   if (kbProblem.status === 'OPEN') {
  //     const objectMng = kbProblem.impactedEntities;
  //     objectMng.forEach((mng) => {
  //       arrayProblem.push({ id: mng.entityId.id, type: mng.entityId.type });
  //     });
  //   }
  // });
  
  const arrayTanzu = [];

  const tanzuEntities = tanzuKubernetes.entities ? tanzuKubernetes.entities: [];
  tanzuEntities.forEach(element => {
    let errors = [];
    let status = 'OK';
    const conditions = element.properties.kubernetesConditions;
    if(conditions){
      conditions.forEach((condition) => {
        if(condition.type === 'Ready' && condition.status === 'False'){
          errors.push('Ready: False')
          status = 'CRITICAL';
        } 
        if(condition.type !== 'Ready' && condition.status === 'True'){
          errors.push(`${condition.type}: True`)
          status = 'CRITICAL';
        }
      })
    }
    else{
      errors.push('kubernetesConditions not found')
      status = 'CRITICAL';
    }
    console.log(errors)
    arrayTanzu.push({ name: element.displayName, status, color: color_status[status], errors });
  });

  return {
    cpuGraphData: {
      datasets: datasetsCPU,
      timestamps: timestampsCPU
    },
    memoryGraphData: {
      datasets: datasetsMemory,
      timestamps: timestampsMemory
    },
    tanzu: arrayTanzu
  }
}

export async function createDataForInfraCriticaDashboard(infoHosts, setLoading) {
  setLoading(true);
  let vcenterResult = {};
  let vcenterVDI = {};
  const webLogicResult = {};
  const anakeArray = [];
  const alerceArray = [];
  const caneloArray = [];
  let tanzuStatus = 0;
  let globalStatus = 0;
  let totalInfra = 0;
  let problemInfra = 0;
  let vdiGlobalStatus = 0;
  let totalVDI = 0;
  let problemVDI = 0;

  const copyInfoHosts = infoHosts;
  const vcenterArray = copyInfoHosts['vcenter'];
  const hypervisorArray = copyInfoHosts['hypervisor'];
  const weblogic = copyInfoHosts['weblogic'];
  const anake = copyInfoHosts['ANAKE'];
  const alerce = copyInfoHosts['ALERCE'];
  const canelo = copyInfoHosts['CANELO'];
  const dynacpu = copyInfoHosts['dynacpu'] || {};
  const dynamemory = copyInfoHosts['dynamemory'] || {};

  let dynaObjCpu = dynacpu['result'];
  let dynaObjMemory = dynamemory['result'];

  let ldapTemp = 0;
  // console.log(anake);

  anake.forEach((element) => {
    // const triggers = JSON.parse(element.triggers);
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
    }
    if (statusTmp > ldapTemp) {
      ldapTemp = statusTmp;
    }
    const statusItem = zabbix_states[statusTmp];
    anakeArray.push({
      service: element.name,
      status: statusItem,
    });
    totalInfra++;
  });

  let tuxedoStatus = 0;
  alerce.forEach((element) => {
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(element);
    }
    if(statusTmp > tuxedoStatus){
      tuxedoStatus = statusTmp;
    }
    const statusItem = zabbix_states[statusTmp];
    let service = element.name;
    if(service.startsWith('Memory')){
      service = 'Memory';
    }
    if(service.startsWith('CPU')){
      service = 'CPU';
    }
    alerceArray.push({
      service: service,
      value: parseFloat(element.lastvalue).toFixed(2),
      status: statusItem,
    });
    totalInfra++;
  })

  canelo.forEach((element) => {
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(element);
    }
    if(statusTmp > tuxedoStatus){
      tuxedoStatus = statusTmp;
    }
    const statusItem = zabbix_states[statusTmp];
    let service = element.name;
    if(service.startsWith('Memory')){
      service = 'Memory';
    }
    if(service.startsWith('CPU')){
      service = 'CPU';
    }
    caneloArray.push({
      service: service,
      value: parseFloat(element.lastvalue).toFixed(2),
      status: statusItem,
    });
    totalInfra++;
  })

  weblogic.forEach((element) => {
    // const triggers = JSON.parse(element.triggers);
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(element);
    }
    totalInfra++;
    const statusItem = zabbix_states[statusTmp];

    if (!webLogicResult[element.hostname]) {
      webLogicResult[element.hostname] = { items: [] };
    }
    if (element.name.includes('ICMP ping')) {
      webLogicResult[element.hostname]['status'] = statusItem;
      webLogicResult[element.hostname]['ip'] = element.ip;
      webLogicResult[element.hostname]['name'] = element.hostname;
      webLogicResult[element.hostname]['type'] = 'server';
      webLogicResult[element.hostname]['iconName'] = 'server';
      webLogicResult[element.hostname]['custom_desc'] = element.hostname;
    } else {
      webLogicResult[element.hostname]['items'].push({
        status: statusItem,
        name: element.name,
        label: '',
        iconName: element.iconName || 'query',
        lastvalue: element.lastvalue,
      });
    }
  });

  vcenterArray.forEach((vcenterInfo) => {
    const [prefix, vcName] = vcenterInfo.hostname.split('-');
    if(vcName === 'altamar'){
      vcenterResult[vcName] = { status: 'OK' };
      if (vcenterInfo.lastvalue !== 1) {
        vcenterResult[vcName] = { status: 'CRITICAL' };
      }
    }
    else{
      vcenterVDI[vcName] = { status: 'OK' };
      if (vcenterInfo.lastvalue !== 1) {
        vcenterVDI[vcName] = { status: 'CRITICAL' };
      }
    }
  });

  hypervisorArray.forEach((hypervisorItem) => {
    if(dictHypervisors[hypervisorItem.hostname] === 'altamar' ){
      const {vcenterArray: vcenterArrtmp, globalStatusChange, globalStatusPrio, problemsAdd, totalAdd} = hypervisorCheck(hypervisorItem, vcenterResult, globalStatus);
      vcenterResult = {...vcenterArrtmp};
      if(globalStatusChange){
        globalStatus = globalStatusPrio;
      }
      problemInfra += problemsAdd;
      totalInfra += totalAdd 
    }
    if(dictHypervisors[hypervisorItem.hostname] && dictHypervisors[hypervisorItem.hostname].startsWith("lascar")){
      const {vcenterArray: vcenterArrtmp, globalStatusChange, globalStatusPrio, problemsAdd, totalAdd} = hypervisorCheck(hypervisorItem, vcenterVDI, vdiGlobalStatus);
      vcenterVDI = {...vcenterArrtmp};
      if(globalStatusChange){
        vdiGlobalStatus = globalStatusPrio;
      }
      problemVDI += problemsAdd;
      totalVDI += totalAdd 
    }
  });
  setLoading(false);
  return {
    vcenter: vcenterResult,
    infoHostsWeblogic: webLogicResult,
    ldap: anakeArray,
    ldapStatus: zabbix_states[ldapTemp],
    dynatrace: { cpu: dynaObjCpu, memory: dynaObjMemory, alerce: alerceArray, canelo: caneloArray },
    infraStatus: zabbix_states[globalStatus],
    totalInfra,
    problemInfra,
    tuxedoStatus: zabbix_states[tuxedoStatus],
    vcenterVDI,
    totalVDI,
    problemVDI,
    VDIstatus: vdiGlobalStatus
  };
}

export function createDataForEnlacesDashboard(infoHosts) {
  // console.log(infoHosts);

  const objectHosts = {};
  let auxPriority = 1;
  let globalStatus = 'OK';
  let problems = 0;
  let total = 0;
  // const dict
  for (const host in infoHosts) {
    const [site, hostname] = host.split(';');
    objectHosts[hostname] = { items: [] };
    const itemsByHosts = infoHosts[host];
    const interfaces = {};
    itemsByHosts.forEach((item) => {
      if(item.name.includes('Interfaz ') || item.name.includes('Interface ') || item.name.includes( "ICMP ping")){//items that impact in the global status

        let status = 'OK';
        const dictTags = {};
        const arrayTags = item.tags && item.tags.length ? item.tags : [];
        arrayTags.forEach((infoTag) => {
          dictTags[infoTag.tag] = infoTag.value;
        });
        if (item.value && item.value != 0) {
          status = zabbix_states[item.priority];
          if (item.priority > 1) { //warning or critical in zabbix
            if (item.priority > auxPriority) { 
              globalStatus = zabbix_states[item.priority];
              auxPriority = item.priority
            }
            problems++;
          }
        }

        if(item.triggers){
          let tmpProblem = false;
          item.triggers.forEach((trg) => {
            if (trg.value !== "0") { //warning or critical in zabbix
              if (trg.priority >= auxPriority) {
                globalStatus = zabbix_states[parseInt(trg.priority, 10)];
                auxPriority = trg.priority
              }
              status = zabbix_states[parseInt(trg.priority, 10)];
              tmpProblem = true;
            } else {
              if (globalStatus === 'UNK') {
                globalStatus = 'OK';
              }
            }
            if (status === 'UNK') {
              status = 'OK';
            }
          });
          if (tmpProblem) {
            problems++;
          }
        }

        if (item.name.includes('Interfaz ') || item.name.includes('Interface ')) {
          total++;
          const [, nameInterface] = item.name.includes('Interfaz ') ? item.name.split('Interfaz '): item.name.split('Interface ');
          const itemInterface = {
            status: status,
            name: dictTags['dashboard_item_name'],
            label: dictTags['dashboard_item_label'] === '.' ? dictTags['interface'] : dictTags['dashboard_item_label'],
            iconName: dictTags['dashboard_icon_name'],
          };
          interfaces[nameInterface] = itemInterface;
          const item2 = {
            status: status,
            name: dictTags['dashboard_item_name'],
            label: dictTags['dashboard_item_label'] === '.' ? dictTags['interface'] : dictTags['dashboard_item_label'],
            iconName: dictTags['dashboard_icon_name'],
          };
          objectHosts[hostname]['items'].push(item2);
        }
        if (item.name === 'ICMP ping') {
          total++;
          objectHosts[hostname]['status'] = status;
          objectHosts[hostname]['ip'] = dictTags['dashboard_host_ip'];
          objectHosts[hostname]['name'] = dictTags['dashboard_host_name'];
          objectHosts[hostname]['type'] = dictTags['dashboard_host_type'];
          objectHosts[hostname]['iconName'] = dictTags['dashboard_icon_name'];
          objectHosts[hostname]['custom_desc'] = dictTags['custom_desc'];
          objectHosts[hostname]['site'] = site;
        }
      }
    });

    itemsByHosts.forEach((item) => {

      let status = 'OK';
      const dictTags = {};
      const arrayTags = item.tags && item.tags.length ? item.tags : [];
      arrayTags.forEach((infoTag) => {
        dictTags[infoTag.tag] = infoTag.value;
      });
      if (item.name.includes('Inbound Bandwidth ')) {
        const [, nameInterface] = item.name.split('Inbound Bandwidth ');
        let value = parseFloat(item.lastvalue).toFixed(2);
        interfaces[nameInterface]['inbound'] = value;
      } else if (item.name.includes('Outbound Bandwidth ')) {
        const [, nameInterface] = item.name.split('Outbound Bandwidth ');
        let value = parseFloat(item.lastvalue).toFixed(2);
        interfaces[nameInterface]['outbound'] = value;
      } 
    });
    for (const interf in interfaces) {
      const element = interfaces[interf];
      objectHosts[hostname]['items'].push(element);
    }
  }

  return { infoHosts: objectHosts, globalStatus, total, problems };
}

export function problemSubscriptors(sub){
  if(sub && sub !== "OK"){
    return 1;
  }
  return 0;
}

export async function getClientesIndicadoresSubscriptors(infoProblems) {
  const copyInfoProblems = infoProblems;
  const kubernetesProblems = copyInfoProblems['tanzuProblems']['problems'] || [];
  const arrayProblem = [];
  let problems = 0;
  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = kbProblem.impactedEntities;
      objectMng.forEach((mng) => {
        arrayProblem.push(mng.entityId.id);
      });
    }
  });

  let arrayStatusReplicaCliente = await appsByNucleoErrorsHelper(
    nameSpaceReplicaCliente,
    arrayProblem,
    arrayReplicaClienteApps
  );
  const statusFase1 = arrayStatusReplicaCliente[1];
  const statusNatsStream = arrayStatusReplicaCliente[2];
  const jurCas = arrayStatusReplicaCliente[3];
  const jurCat = arrayStatusReplicaCliente[4];
  const jurCav = arrayStatusReplicaCliente[5];
  const natCas = arrayStatusReplicaCliente[6];
  const natCat = arrayStatusReplicaCliente[7];
  const natCav = arrayStatusReplicaCliente[8];
  // const natCavSrv = arrayStatusReplicaCliente[9];

  const [, idTemuco] = nameSpaceTuoTempoTemuco.split(':');
  const [, idValidivia] = nameSpaceTuoTempoValdivia.split(':');
  const [, idSalesforce] = nameSpaceSalesforce.split(':');
  let arrayStatusNamespaces = await appsErrorsHelper(arrayProblem, [idTemuco, idValidivia, idSalesforce]);
  const statusTemuco = arrayStatusNamespaces[0];
  const statusValdivia = arrayStatusNamespaces[1];
  const statusSalesforce = arrayStatusNamespaces[2];

  let arrayStatusRoble = await appsByNucleoErrorsHelper(nameSpaceRoble, arrayProblem, arrayRoble);
  let arrayStatusAgo = await appsByNucleoErrorsHelper(nameSpaceAgo, arrayProblem, arrayAgo);
  let arrayStatusQuimio = await appsByNucleoErrorsHelper(nameSpaceQuimio, arrayProblem, arrayQuimio);
  let arrayStatusSistdep = await appsByNucleoErrorsHelper(nameSpaceSistdep, arrayProblem, arraySistdep);
  let arrayStatusNucleo = await appsByNucleoErrorsHelper(nameSpaceNucleo, arrayProblem, arrayNucleo);
  let arrayStatusSap = await appsByNucleoErrorsHelper(nameSpaceSap, arrayProblem, arraySap);
  let arrayStatusMovPacientes = await appsByNucleoErrorsHelper(nameSpaceMovPacientes, arrayProblem, arrayMovPacientes);
  const statusRoble = arrayStatusRoble[0];
  const statusAgo = arrayStatusAgo[0];
  const statusQuimio = arrayStatusQuimio[0];
  const statusSistdep = arrayStatusSistdep[0];
  const statusNucleo = arrayStatusNucleo[0];
  const statusSap = arrayStatusSap[0];

  const statusMovPacientes0 = arrayStatusMovPacientes[0].status;
  const statusMovPacientes1 = arrayStatusMovPacientes[1].status;
  let movPacientesStatus = 'UNKNOWN'
  if(arrayStatusMovPacientes.length === 2){
    if(statusMovPacientes0 === 'CRITICAL' || statusMovPacientes1 === 'CRITICAL'){
      movPacientesStatus = 'CRITICAL'
    }
    else if(statusMovPacientes0 === 'WARNING' || statusMovPacientes1 === 'WARNING'){
      movPacientesStatus = 'WARNING'
    }
    else if(statusMovPacientes0 === 'OK' && statusMovPacientes1 === 'OK'){
      movPacientesStatus = 'OK'
    }
  }

  

  let arrayStatusApiClientes = await appsByNucleoErrorsHelper(nameSpaceApiClientes, arrayProblem, arrayApiClientes);
  const statusOperacionesCli = arrayStatusApiClientes[0];
  const statusConsultasCli = arrayStatusApiClientes[1];
  const statusJur = arrayStatusApiClientes[2];

  let arrayStatusApiParam = await appsByNucleoErrorsHelper(nameSpaceApiParam, arrayProblem, arrayApiParam);
  const statusApiParam = arrayStatusApiParam[0];

  let arrayStatusV4 = await appsByNucleoErrorsHelper(nameSpaceClientesV4, arrayProblem, arrayClientesV4);
  const statusV4 = arrayStatusV4[0];

  const replicaClienteDynatraceStatus = {
    natsStream: statusNatsStream ? statusNatsStream['status'] : 'CRITICAL',
    jurCas: jurCas ? jurCas['status'] : 'CRITICAL',
    jurCat: jurCat ? jurCat['status'] : 'CRITICAL',
    jurCav: jurCav ? jurCav['status'] : 'CRITICAL',
    natCas: natCas ? natCas['status'] : 'CRITICAL',
    natCat: natCat ? natCat['status'] : 'CRITICAL',
    natCav: natCav ? natCav['status'] : 'CRITICAL',
    // natCavSrv: natCavSrv ? natCavSrv['status'] : 'CRITICAL',
    fase1: statusFase1 ? statusNatsStream['status'] : 'CRITICAL',
    temuco: statusTemuco || 'CRITICAL',
    valdivia: statusValdivia || 'CRITICAL',
    salesforce: statusSalesforce || 'CRITICAL',
    cliOperaciones: statusOperacionesCli ? statusOperacionesCli['status'] : 'CRITICAL',
    cliConsultas: statusConsultasCli ? statusConsultasCli['status'] : 'CRITICAL',
    cliStatus: statusOperacionesCli['status'] === 'OK' ? statusOperacionesCli['status'] : statusConsultasCli['status'],
    paramStatus: statusApiParam ? statusApiParam['status'] : 'CRITICAL',
    jurStatus: statusJur ? statusJur['status'] : 'CRITICAL',
    v4Status: statusV4 ? statusV4['status'] : 'CRITICAL',
    robleStatus: statusRoble ? statusRoble['status'] : 'CRITICAL',
    agoStatus: statusAgo ? statusAgo['status'] : 'CRITICAL',
    quimioStatus: statusQuimio ? statusQuimio['status'] : 'CRITICAL',
    sistdepStatus: statusSistdep ? statusSistdep['status'] : 'CRITICAL',
    nucleoStatus: statusNucleo ? statusNucleo['status'] : 'CRITICAL',
    sapStatus: statusSap ? statusSap['status'] : 'CRITICAL',
    movPacientesStatus: movPacientesStatus,
    
  };
  let total = 0;
  let globalStatus = 'OK';
  Object.keys(replicaClienteDynatraceStatus).forEach(key => {
    if(key !== 'cliStatus' && key !== 'desencoladores' && key !== 'movPacientesStatus'){
      const itemStatus = replicaClienteDynatraceStatus[key];
      problems += problemSubscriptors(itemStatus);
      total += 1;
      if(number_status[globalStatus] < number_status[itemStatus]){
        globalStatus = itemStatus;
      }
    }
  });
  return { replicaClienteDynatraceStatus, total, problems, globalStatus };
}

export function getClientesIndicadores(infoHosts) {
  const oracleObject = { items: {}, status: 0 };
  let tykStatus = 0;
  let redHatStatus = 0;
  let globalStatus = 'OK';
  let totalHostsWeblogic = 0;
  let problemsWeblogic = 0;
  const arrayHostsWeblogicInfo = []
  const arrayHostsWeblogicNames = ['antofagasta01-1', 'antofagasta01-2', 'calama01-1', 'calama01-2', 'tocopilla01-1', 'tocopilla01-2', 'antofagasta05-1', 'calama05-1', 'tocopilla05-1'];
  if (infoHosts) {
    if(infoHosts['ROBLE_ORACLE_PSOL7']){
      infoHosts['ROBLE_ORACLE_PSOL7'].forEach((item) => {
        let tmpStatus = 0;
        item.triggers.forEach((trg) => {
          if (trg.value != 0 && trg.priority > tmpStatus) {
            tmpStatus = trg.priority;
          }
        });
        if (item.name.includes('Numero de sesiones')) {
          const [tmpStr, entityDb] = item.name.split('Numero de sesiones activas ');
          if (!oracleObject['items'][entityDb]) {
            oracleObject['items'][entityDb] = { label: 'Sistema', status: tmpStatus, width: '198' };
          } else {
            if (oracleObject['items'][entityDb]['status'] < tmpStatus) {
              oracleObject['items'][entityDb]['status'] = tmpStatus;
              globalStatus = zabbix_states[tmpStatus];
            }
          }
        } else if (item.name.includes('Profundidad de cola')) {
          const [tmpStr, entityDb] = item.name.split('Profundidad de cola de sesiones en espera ');
          if (!oracleObject['items'][entityDb]) {
            oracleObject['items'][entityDb] = { label: 'Sistema', status: tmpStatus, width: '198' };
          } else {
            if (oracleObject['items'][entityDb]['status'] < tmpStatus) {
              oracleObject['items'][entityDb]['status'] = tmpStatus;
              globalStatus = zabbix_states[tmpStatus];
            }
          }
        } else {
          if (oracleObject['status'] < tmpStatus) {
            oracleObject['status'] = tmpStatus;
            globalStatus = zabbix_states[tmpStatus];
          }
        }
      });
    }
    if(infoHosts['Tyk_prod']){
      infoHosts['Tyk_prod'].forEach((item) => {
        let tmpStatus = 0;
        item.triggers.forEach((trg) => {
          if (trg.value != 0 && trg.priority > tmpStatus) {
            tmpStatus = trg.priority;
          }
        });
        if (tykStatus < tmpStatus) {
          tykStatus = tmpStatus;
        }
        else if (globalStatus < tmpStatus) {
          globalStatus = zabbix_states[tmpStatus];
        }
      });
    }
    if(infoHosts['LOIRA']){
      infoHosts['LOIRA'].forEach((item) => {
        let tmpStatus = 0;
        item.triggers.forEach((trg) => {
          if (trg.value != 0 && trg.priority > tmpStatus) {
            tmpStatus = trg.priority;
          }
        });
        if (redHatStatus < tmpStatus) {
          redHatStatus = tmpStatus;
        }
        else if (globalStatus < tmpStatus) {
          globalStatus = zabbix_states[tmpStatus];
        }
      });
    }
    if(infoHosts['DON']){
      infoHosts['DON'].forEach((item) => {
        let tmpStatus = 0;
        item.triggers.forEach((trg) => {
          if (trg.value != 0 && trg.priority > tmpStatus) {
            tmpStatus = trg.priority;
          }
        });
        if (redHatStatus < tmpStatus) {
          redHatStatus = tmpStatus;
        }
        else if (globalStatus < tmpStatus) {
          globalStatus = zabbix_states[tmpStatus];
        }
      });
    }
    
    arrayHostsWeblogicNames.forEach((hostName) => {
      if (infoHosts[hostName]) {
        infoHosts[hostName].forEach((item) => {
          let tmpStatus = 0;
          item.triggers.forEach((trg) => {
            if (trg.value != 0 && trg.priority > tmpStatus) {
              tmpStatus = trg.priority;
              problemsWeblogic ++;
            }
          });
          arrayHostsWeblogicInfo.push({
            label: hostName,
            title: hostName,
            status: zabbix_states[tmpStatus]
          });
          if (globalStatus < tmpStatus) {
            globalStatus = zabbix_states[tmpStatus];
          }
        });
        totalHostsWeblogic ++;
      }
    })

  }
  const response = { oracle: oracleObject, arrayHostsWeblogicInfo, tykStatus, redHatStatus, problemsWeblogic, totalHostsWeblogic, globalStatus };
  return response;
}

async function appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayWorkFlow, debug = false) {
  let temp = [];
  for (let i = 0; i < arrayWorkFlow.length; i++) {
    let flag = 'OK';
    let dataNameSpace = await api.getNamespaceIds(nameSpace, arrayWorkFlow[i]);
    if(debug){
      console.log(nameSpace, arrayWorkFlow[i])
      console.log(dataNameSpace)
    }
    if (dataNameSpace && dataNameSpace.totalCount == 1) {
      let id = dataNameSpace.entities[0].entityId;
      let name = dataNameSpace.entities[0].displayName;
      let dataTemp = await api.getAppData(id);

      let toRelationships = dataTemp.toRelationships;
      Object.values(toRelationships).forEach((arr) => {
        arr.forEach((relation) => {
          if (arrayProblem.includes(relation.id)) {
            flag = 'CRITICAL';
          }
        });
      });
      temp.push({
        id: id,
        name: name,
        status: flag ? 'OK' : 'CRITICAL',
      });
    }
  }
  return temp;
}

async function appsErrorsHelper(arrayProblem, arrayId) {
  let temp = [];
  for (let i = 0; i < arrayId.length; i++) {
    let flag = 'OK';
    let id = arrayId[i];
    let dataTemp = await api.getAppData(id);

    let toRelationships = dataTemp.toRelationships;
    Object.values(toRelationships).forEach((arr) => {
      arr.forEach((relation) => {
        if (arrayProblem.includes(relation.id)) {
          flag = 'CRITICAL';
          console.log(relation);
        }
      });
    });
    temp.push(flag);
  }
  return temp;
}

export async function appsByNucleoErrors(infoHosts) {
  const copyInfoHosts = infoHosts;
  const kubernetesProblems = copyInfoHosts['tanzuProblems']['problems'] || [];
  const arrayProblem = [];
  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = kbProblem.impactedEntities;
      objectMng.forEach((mng) => {
        arrayProblem.push(mng.entityId.id);
      });
    }
  });

  let farmacy = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayFarmacyWorkflow);
  let nucleo = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayNucleoWorkflow);
  let manteiner = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayMantainerWorkflow);
  let digital = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayDigitalWorkflow);

  return {
    farmacy: farmacy,
    nucleo: nucleo,
    manteiner: manteiner,
    digital: digital,
  };
}

export async function appsByNucleoDigitalErrors(infoHosts) {
  const copyInfoHosts = infoHosts;
  const kubernetesProblems = copyInfoHosts['tanzuProblems']['problems'] || [];
  const arrayProblem = [];
  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = kbProblem.impactedEntities;
      objectMng.forEach((mng) => {
        arrayProblem.push(mng.entityId.id);
      });
    }
  });
  let back = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayBackNucleoDigital);
  let front = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayFrontNucleoDigital);
  let backStatus = 0;
  back.forEach(element => {
    if(number_status[element.status] > backStatus){
      backStatus = number_status[element.status];
    }
  });
  
  let frontStatus = 0;
  front.forEach(element => {
    if(number_status[element.status] > frontStatus){
      frontStatus = number_status[element.status];
    }
  });

  return {
    Back: {data: back, status: global_states[backStatus]},
    Front: {data: front, status: global_states[frontStatus]},
  };
}

export function createDataURL(info, newBackend = false){
  const arrUrls = [];
  let problems = 0;
  let globalStatus = 'OK';
  let total = 0;
  if(info){
    const arrayInfo = newBackend ? info : info.undefined;
    arrayInfo.forEach(element => {
      let url = element.description.split(' ')[1];
      let name = element.tags.find((tag) => tag.tag === 'dashboard_item_name').value;
      let label = element.tags.find((tag) => tag.tag === 'dashboard_item_label').value;
      let status = 'OK';
      if(element.value != 0){
        status = zabbix_states[element.priority]
        if(element.priority > 1){
          problems +=1;
          if(element.priority > 3){
            globalStatus = 'CRITICAL';
          }
          if(element.priority < 4 && globalStatus !== 'CRITICAL'){
            globalStatus = 'WARNING';
          }
        }
      }
      arrUrls.push({url: url, name: name, label: label, status: status})
    });
    total =  arrayInfo.length;
  }
  return { urls: arrUrls, globalStatus, total, problems };
}

export function createDataForBD(infoDashboard){
  let infoHosts = {};
  let globalStatus = 0;
  let total = 0;
  let totalProblems =0;

  Object.entries(infoDashboard).forEach(([hostname, items]) => {
    let hostStatus = 0;
    const arr_problems = [];
    items.forEach((item) =>{
      let auxPriority = 0;
      let problems= [];
      total +=1;
      item.triggers.forEach((trg) => {
        if (parseInt(trg.value, 10) !== 0) {
          if (parseInt(trg.priority, 10) >= auxPriority) {
            auxPriority =trg.priority;
            problems.push(trg.description)
          }
          if (parseInt(trg.priority, 10) > hostStatus) {
            hostStatus = trg.priority;
          }
        } 
      });
      const statusItem = zabbix_states[auxPriority];

      if(auxPriority !== 0){
        totalProblems +=1;
        arr_problems.push({status: statusItem, itemName: item.name, itemProblems: problems});
        if(auxPriority > globalStatus){
          globalStatus = auxPriority;
        }
      }
    })
    infoHosts[hostname] = {status: zabbix_states[hostStatus], problems: arr_problems}
  });
  return { infoHosts, globalStatus: zabbix_states[globalStatus], total, totalProblems };

}

export function createDataForFirewall(infoDashboard){
  let infoHosts = {};
  let globalStatus = 0;
  let total = 0;
  let totalProblems =0;
  const pingServiceName = 'ICMP ping'
  Object.entries(infoDashboard).forEach(([hostname, items]) => {
    let hostStatus = undefined;
    const arr_problems = [];
    const services = [];
    let statusItem = 'UNK';
    items.forEach((item) =>{
      if(item.name === 'HA State'){
        statusItem = item.lastvalue === 'active' ? 'OK' : 'CRITICAL';
      }
      else if(item.name === 'F5 BIG-IP: Failover status'){
        statusItem = item.lastvalue === '4' ? 'OK' : 'CRITICAL';
      }
      else{

        let auxPriority = 0;
        const problems= [];
        total +=1;
        item.triggers.forEach((trg) => {
          if (parseInt(trg.value, 10) !== 0) {
            if (parseInt(trg.priority, 10) >= auxPriority) {
              auxPriority =trg.priority;
              problems.push(trg.description)
            }
            if (item.name.includes(pingServiceName)) {
              hostStatus = trg.priority;
            }
          }
          else if(item.name.includes(pingServiceName)){
            hostStatus = 0;
          }
        });
        statusItem = zabbix_states[auxPriority];
  
        if(auxPriority !== 0){
          totalProblems +=1;
          arr_problems.push({status: statusItem, itemName: item.name, itemProblems: problems});
          if(auxPriority > globalStatus){
            globalStatus = auxPriority;
          }
        }
      }
      services.push({itemName: item.name, statusItem: statusItem});
    })
    infoHosts[hostname] = {status: hostStatus!== undefined ? zabbix_states[hostStatus]: 'UNKNOWN', items: services, problems: arr_problems}
  });
  return { infoHosts, globalStatus: zabbix_states[globalStatus], total, problems: totalProblems };

}