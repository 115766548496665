import { zabbix_states, color_status, number_status } from '../utils/dictionaryStates';
import { dictHypervisors } from '../utils/dictionaryHypervisors';
import api from './requests';
import {
  nameSpace,
  arrayDigitalWorkflow,
  arrayFarmacyWorkflow,
  arrayMantainerWorkflow,
  arrayNucleoWorkflow,
} from '../utils/applicationsByNucleo';
import {
  nameSpaceReplicaCliente,
  arrayReplicaClienteApps,
  nameSpaceTuoTempoTemuco,
  nameSpaceTuoTempoValdivia,
  nameSpaceSalesforce,
  nameSpaceApiClientes,
  arrayApiClientes,
  nameSpaceApiParam,
  arrayApiParam,
  nameSpaceClientesV4,
  arrayClientesV4,
  nameSpaceRoble,
  arrayRoble,
  nameSpaceAgo,
  arrayAgo,
  nameSpaceQuimio,
  arrayQuimio,
  nameSpaceSistdep,
  arraySistdep,
  nameSpaceNucleo,
  arrayNucleo,
  nameSpaceSap,
  arraySap,
} from '../utils/applicationsByReplicaCliente';

export function processData(data) {
  const series = {};
  data.series.forEach((serie) => {
    if (serie.fields) {
      serie.fields.forEach((field) => {
        const values = field.values.length ? field.values : [];
        const [category, host] = serie.name.split(':');
        if (!series[category]) {
          series[category] = {};
        }
        if (!series[category][host]) {
          series[category][host] = [];
        }
        values.forEach((value, index) => {
          if (!series[category][host][index]) {
            series[category][host].push({});
          }
          if ((field.name === 'tags' || field.name === 'triggers') && typeof value === 'string') {
            // if (field.name === 'tags') {
            series[category][host][index][field.name] = JSON.parse(value);
          } else {
            series[category][host][index][field.name] = value;
          }
        });
      });
    }
  });
  return JSON.parse(JSON.stringify(series));
}

export function createDataForDashboard(infoHosts, default_state = 'UNK') {
  // console.log(infoHosts);

  const objectHosts = {};
  let auxPriority = 0;
  let globalStatus = default_state;
  let problems = 0;
  let total = 0;
  // const dict
  for (const host in infoHosts) {
    //console.log("Host: ", host);

    objectHosts[host] = { items: [] };
    const itemsByHosts = infoHosts[host];

    const interfaces = {};
    itemsByHosts.forEach((item) => {
      if (item.name.includes('{#FSLABEL}') && (host === 'maullinx.alemana.cl' || host === 'algarrobo0.alemana.cl')) {
      } else {
        let status = default_state;
        const dictTags = {};
        const arrayTags = item.tags && item.tags.length ? item.tags : [];
        arrayTags.forEach((infoTag) => {
          dictTags[infoTag.tag] = infoTag.value;
        });
        //console.log("Tags", dictTags)
        //console.log("Item1: ", item);
        if (item.triggerid) {
          // status = 'UNK';
          //console.log(item.value);

          if (item.value != 0) {
            status = zabbix_states[item.priority];
            if (zabbix_states[item.priority] >= auxPriority) {
              // if (item.priority) {
              //console.log(item.priority);

              globalStatus = zabbix_states[item.priority];
              // }
              // console.log(item.);
            }
            problems++;
          } else {
            if (globalStatus === 'UNK') {
              globalStatus = 'OK';
            }
            if (status === 'UNK') {
              status = 'OK';
            }
          }
        } else {
          if (item.triggers) {
            // status = 'UNK';
            // console.log(item);
            // const triggers = JSON.parse(item.triggers);
            let tmpProblem = false;
            // console.log(triggers);
            // let globalStatus = triggers.some(trg => trg.value != 0);
            item.triggers.forEach((trg) => {
              if (
                parseInt(trg.value, 10) !== 0
                // parseInt(trg.priority, 10) >= zabbix_states[parseInt(trg.priority, 10)]
              ) {
                if (parseInt(trg.priority, 10) >= auxPriority) {
                  globalStatus = zabbix_states[parseInt(trg.priority, 10)];
                  auxPriority = globalStatus
                }
                // if (trg.priority) {
                // }
                status = zabbix_states[parseInt(trg.priority, 10)];
                tmpProblem = true;
              } else {
                if (globalStatus === 'UNK') {
                  globalStatus = 'OK';
                }
              }
              if (status === 'UNK') {
                status = 'OK';
              }
            });
            if (tmpProblem) {
              problems++;
            }
          }
        }
        if (!objectHosts[host]['ip'] && dictTags['dashboard_host_ip']) {
          objectHosts[host]['ip'] = dictTags['dashboard_host_ip'];
        }
        if (!objectHosts[host]['name'] && dictTags['dashboard_host_name']) {
          objectHosts[host]['name'] = dictTags['dashboard_host_name'];
        }
        if (item.name === 'ICMP ping') {
          total++;
          objectHosts[host]['status'] = status;
          objectHosts[host]['ip'] = dictTags['dashboard_host_ip'];
          objectHosts[host]['name'] = dictTags['dashboard_host_name'];
          objectHosts[host]['type'] = dictTags['dashboard_host_type'];
          objectHosts[host]['iconName'] = dictTags['dashboard_icon_name'];
          objectHosts[host]['custom_desc'] = dictTags['custom_desc'];
        } else if (item.name.includes('Interfaz ')) {
          total++;
          const [, nameInterface] = item.name.split('Interfaz ');
          const itemInterface = {
            status: status,
            name: dictTags['dashboard_item_name'],
            label: dictTags['dashboard_item_label'],
            iconName: dictTags['dashboard_icon_name'],
          };
          interfaces[nameInterface] = itemInterface;
        } else if (item.name.includes('Inbound Bandwidth ')) {
          const [, nameInterface] = item.name.split('Inbound Bandwidth ');
          let value = parseFloat(item.lastvalue).toFixed(2);
          interfaces[nameInterface]['inbound'] = value;
        } else if (item.name.includes('Outbound Bandwidth ')) {
          const [, nameInterface] = item.name.split('Outbound Bandwidth ');
          let value = parseFloat(item.lastvalue).toFixed(2);
          interfaces[nameInterface]['outbound'] = value;
        } else {
          let newName = '';
          if (item.name.includes('{#FSLABEL}')) {
            const arrayItName = item.name.split('{#FSLABEL}');
            newName = arrayItName[1];
          }
          total++;
          const itemCreated = {
            status: status,
            name: dictTags['dashboard_item_name'] || newName,
            label: dictTags['dashboard_item_label'] || 'Infraestructura',
            iconName: dictTags['dashboard_icon_name'] || 'query',
          };
          objectHosts[host]['items'].push(itemCreated);
        }
      }
    });
    for (const interf in interfaces) {
      const element = interfaces[interf];
      objectHosts[host]['items'].push(element);
    }
  }
  // console.log(objectHosts);

  return { infoHosts: objectHosts, globalStatus, total, problems };
}

export async function createDataForInfraCriticaDashboard(infoHosts, setLoading) {
  setLoading(true);
  const vcenterResult = {};
  const webLogicResult = {};
  const anakeArray = [];
  let ldapStatus = 0;
  let globalStatus = 0;
  let totalInfra = 0;
  let problemInfra = 0;

  const copyInfoHosts = JSON.parse(JSON.stringify(infoHosts));
  const vcenterArray = copyInfoHosts['vcenter'];
  const hypervisorArray = copyInfoHosts['hypervisor'];
  const weblogic = copyInfoHosts['weblogic'];
  const anake = copyInfoHosts['ANAKE'];
  const dynacpu = copyInfoHosts['dynacpu'] || {};
  const dynamemory = copyInfoHosts['dynamemory'] || {};
  const kubernetesDevices = copyInfoHosts['tanzuDevices'] || [];
  const kubernetesProblems = copyInfoHosts['tanzuProblems'] || [];
  //console.log('devices', kubernetesDevices);
  //console.log('problems', kubernetesProblems);

  const arrayProblem = [];

  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = JSON.parse(kbProblem.impactedEntities);
      objectMng.forEach((mng) => {
        //console.log(mng);

        arrayProblem.push({ id: mng.entityId.id, type: mng.entityId.type });
      });
    }
  });
  // console.log(arrayProblem);
  const arrayHostProblems = [];

  for (let index = 0; index < kubernetesDevices.length; index++) {
    const kbDev = kubernetesDevices[index];
    if (kbDev.displayName.includes('prod')) {
      const { toRelationships, displayName } = await api.fetchEntitie(`entities/${kbDev.entityId}`);
      // const data = await api.getNamespaceIds(nameSpace, arrayMantainerWorkflow[0]);
      // console.log(data)
      let status = 'OK';
      toRelationships.isProcessOf.forEach((mng) => {
        arrayProblem.forEach((elmProb) => {
          if (elmProb.id === mng.id && elmProb.type === mng.type) {
            // console.log(displayName);
            // arrayHostProblems.push({name: displayName, status: 'CRITICAL'});
            status = 'CRITICAL';
            globalStatus = 5;
            // break
          } else if (status !== 'CRITICAL') {
            status = 'OK';
          }
        });
      });
      arrayHostProblems.push({ name: displayName, status, color: color_status[status] });
    }
  }

  let dynaObjCpu = JSON.parse(dynacpu[0]['result']);
  let dynaObjMemory = JSON.parse(dynamemory[0]['result']);

  let ldapTemp = 0;
  // console.log(anake);

  anake.forEach((element) => {
    // const triggers = JSON.parse(element.triggers);
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(element);
    }
    if (statusTmp > ldapTemp) {
      ldapTemp = statusTmp;
    }
    const statusItem = zabbix_states[statusTmp];
    anakeArray.push({
      service: element.name,
      status: statusItem,
    });
    totalInfra++;
  });

  weblogic.forEach((element) => {
    // const triggers = JSON.parse(element.triggers);
    let statusTmp = 0;
    element.triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(element);
    }
    totalInfra++;
    const statusItem = zabbix_states[statusTmp];

    if (!webLogicResult[element.hostname]) {
      webLogicResult[element.hostname] = { items: [] };
    }
    if (element.name.includes('ICMP ping')) {
      webLogicResult[element.hostname]['status'] = statusItem;
      webLogicResult[element.hostname]['ip'] = element.ip;
      webLogicResult[element.hostname]['name'] = element.hostname;
      webLogicResult[element.hostname]['type'] = 'server';
      webLogicResult[element.hostname]['iconName'] = 'server';
      webLogicResult[element.hostname]['custom_desc'] = element.hostname;
    } else {
      webLogicResult[element.hostname]['items'].push({
        status: statusItem,
        name: element.name,
        label: '',
        iconName: element.iconName || 'query',
        lastvalue: element.lastvalue,
      });
    }
  });

  vcenterArray.forEach((vcenterInfo) => {
    const [prefix, vcName] = vcenterInfo.hostname.split('-');
    vcenterResult[vcName] = { status: 'OK' };
    if (vcenterInfo.lastvalue !== 1) {
      vcenterResult[vcName] = { status: 'CRITICAL' };
    }
  });

  hypervisorArray.forEach((hypervisorItem) => {
    let { name, hostname, triggers: triggers, lastvalue } = hypervisorItem;
    if (!vcenterResult[dictHypervisors[hostname]][hostname]) {
      vcenterResult[dictHypervisors[hostname]][hostname] = {};
    }
    let statusTmp = 0;

    triggers.forEach((trg) => {
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > statusTmp) {
        statusTmp = trg.priority;
      }
      if (parseInt(trg.value, 10) !== 0 && parseInt(trg.priority, 10) > globalStatus) {
        globalStatus = trg.priority;
      }
    });
    if (statusTmp !== 0) {
      problemInfra++;
      // console.log(hypervisorItem);
    }
    totalInfra++;
    const statusItem = zabbix_states[statusTmp];

    if (name.includes('ping')) {
      vcenterResult[dictHypervisors[hostname]][hostname] = { status: statusItem };
    } else if (name.includes('Uptime')) {
      vcenterResult[dictHypervisors[hostname]][hostname]['Uptime'] = { status: statusItem, value: lastvalue };
    } else if (name.includes('Overall status')) {
      vcenterResult[dictHypervisors[hostname]][hostname]['Overall_status'] = { status: statusItem, value: lastvalue };
    } else if (name.includes('CPU usage in percents')) {
      vcenterResult[dictHypervisors[hostname]][hostname]['CPU'] = { status: statusItem, value: lastvalue };
    } else if (name.includes('datastore')) {
      const parts = name.split(' ');
      const resultArray = parts.slice(5, -1);
      const datastoreName = resultArray.join(' ');
      if (!vcenterResult[dictHypervisors[hostname]][hostname]['datastore']) {
        vcenterResult[dictHypervisors[hostname]][hostname]['datastore'] = [];
      }
      vcenterResult[dictHypervisors[hostname]][hostname]['datastore'].push({
        name: datastoreName,
        status: statusItem,
        value: 100 - parseInt(lastvalue, 10),
      });
    } else if (name.includes('Memory usage in percents')) {
      vcenterResult[dictHypervisors[hostname]][hostname]['Memory'] = { status: statusItem, value: lastvalue };
    }
  });
  setLoading(false);
  return {
    vcenter: vcenterResult,
    infoHostsWeblogic: webLogicResult,
    ldap: anakeArray,
    ldapStatus: zabbix_states[ldapTemp],
    dynatrace: { cpu: dynaObjCpu, memory: dynaObjMemory },
    infraStatus: zabbix_states[globalStatus],
    totalInfra,
    problemInfra,
    tanzu: arrayHostProblems,
  };
}

export function createDataForEnlacesDashboard(infoHosts) {
  // console.log(infoHosts);

  const objectHosts = {};
  let auxPriority = 0;
  let globalStatus = 'OK';
  let problems = 0;
  let total = 0;
  // const dict
  for (const host in infoHosts) {
    const [site, hostname] = host.split(';');
    objectHosts[hostname] = { items: [] };
    const itemsByHosts = infoHosts[host];
    const interfaces = {};
    itemsByHosts.forEach((item) => {
      let status = 'OK';
      const dictTags = {};
      const arrayTags = item.tags && item.tags.length ? item.tags : [];
      arrayTags.forEach((infoTag) => {
        dictTags[infoTag.tag] = infoTag.value;
      });
      if (item.value && item.value !== 0) {
        status = zabbix_states[item.priority];
        if (zabbix_states[item.priority] > auxPriority) {
          globalStatus = zabbix_states[item.priority];
        }
        problems++;
      }
      // console.log(dictTags);

      if (item.name.includes('Interfaz ')) {
        total++;
        const [, nameInterface] = item.name.split('Interfaz ');
        const itemInterface = {
          status: status,
          name: dictTags['dashboard_item_name'],
          label: dictTags['dashboard_item_label'] === '.' ? dictTags['interface'] : dictTags['dashboard_item_label'],
          iconName: dictTags['dashboard_icon_name'],
        };
        interfaces[nameInterface] = itemInterface;
      } else if (item.name.includes('Interface ')) {
        total++;
        const [, nameInterface] = item.name.split('Interface ');
        const itemInterface = {
          status: status,
          name: dictTags['dashboard_item_name'],
          label: dictTags['dashboard_item_label'] === '.' ? dictTags['interface'] : dictTags['dashboard_item_label'],
          iconName: dictTags['dashboard_icon_name'],
        };
        interfaces[nameInterface] = itemInterface;
      }
    });

    itemsByHosts.forEach((item) => {
      // console.log(host, item);
      if (item.triggers.length) {
        // console.log(host, item.triggers[0]);

        item.value = item.triggers[0].value;
        item.priority = item.triggers[0].priority;
        // item.priority = triggers[0].priority;
        // item.priority = triggers[0].priority;
      }
      // console.log("\n\n\n");
      // console.log(triggers);

      let status = 'OK';
      const dictTags = {};
      const arrayTags = item.tags && item.tags.length ? item.tags : [];
      arrayTags.forEach((infoTag) => {
        dictTags[infoTag.tag] = infoTag.value;
      });
      if (item.value && item.value != 0) {
        // console.log(item.priority);
        status = zabbix_states[item.priority];
        if (item.priority > auxPriority) {
          globalStatus = zabbix_states[item.priority];
        }
        problems++;
      }
      // console.log(dictTags);
      if (item.name === 'ICMP ping') {
        total++;
        objectHosts[hostname]['status'] = status;
        objectHosts[hostname]['ip'] = dictTags['dashboard_host_ip'];
        objectHosts[hostname]['name'] = dictTags['dashboard_host_name'];
        objectHosts[hostname]['type'] = dictTags['dashboard_host_type'];
        objectHosts[hostname]['iconName'] = dictTags['dashboard_icon_name'];
        objectHosts[hostname]['custom_desc'] = dictTags['custom_desc'];
        objectHosts[hostname]['site'] = site;
      }
      // else if (item.name.includes('Interfaz ')) {
      //   total++;
      //   const [, nameInterface] = item.name.split('Interfaz ');
      //   const itemInterface = {
      //     status: status,
      //     name: dictTags['dashboard_item_name'],
      //     label: dictTags['dashboard_item_label'],
      //     iconName: dictTags['dashboard_icon_name'],
      //   };
      //   interfaces[nameInterface] = itemInterface;
      // } else if (item.name.includes('Interface ')) {
      //   total++;
      //   const [, nameInterface] = item.name.split('Interface ');
      //   const itemInterface = {
      //     status: status,
      //     name: dictTags['dashboard_item_name'],
      //     label: dictTags['dashboard_item_label'],
      //     iconName: dictTags['dashboard_icon_name'],
      //   };
      //   interfaces[nameInterface] = itemInterface;
      // }
      else if (item.name.includes('Inbound Bandwidth ')) {
        const [, nameInterface] = item.name.split('Inbound Bandwidth ');
        let value = parseFloat(item.lastvalue).toFixed(2);
        interfaces[nameInterface]['inbound'] = value;
      } else if (item.name.includes('Outbound Bandwidth ')) {
        const [, nameInterface] = item.name.split('Outbound Bandwidth ');
        let value = parseFloat(item.lastvalue).toFixed(2);
        interfaces[nameInterface]['outbound'] = value;
      } else {
        total++;
        const item = {
          status: status,
          name: dictTags['dashboard_item_name'],
          label: dictTags['dashboard_item_label'] === '.' ? dictTags['interface'] : dictTags['dashboard_item_label'],
          iconName: dictTags['dashboard_icon_name'],
        };
        objectHosts[hostname]['items'].push(item);
      }
    });
    for (const interf in interfaces) {
      const element = interfaces[interf];
      objectHosts[hostname]['items'].push(element);
    }
  }
  // console.log(objectHosts);

  return { infoHosts: objectHosts, globalStatus, total, problems };
}

export async function getClientesIndicadoresSubscriptors(infoProblems) {
  const copyInfoProblems = JSON.parse(JSON.stringify(infoProblems));
  const kubernetesProblems = copyInfoProblems['tanzuProblems'] || [];
  const arrayProblem = [];
  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = JSON.parse(kbProblem.impactedEntities);
      objectMng.forEach((mng) => {
        arrayProblem.push(mng.entityId.id);
      });
    }
  });

  let arrayStatusReplicaCliente = await appsByNucleoErrorsHelper(
    nameSpaceReplicaCliente,
    arrayProblem,
    arrayReplicaClienteApps
  );
  const statusFase1 = arrayStatusReplicaCliente[1];
  const statusNatsStream = arrayStatusReplicaCliente[2];
  const jurCas = arrayStatusReplicaCliente[3];
  const jurCat = arrayStatusReplicaCliente[4];
  const jurCav = arrayStatusReplicaCliente[5];
  const natCas = arrayStatusReplicaCliente[6];
  const natCat = arrayStatusReplicaCliente[7];
  const natCav = arrayStatusReplicaCliente[8];
  // const natCavSrv = arrayStatusReplicaCliente[9];
  // console.log(natCav);

  // console.log(natCavSrv);

  const [, idTemuco] = nameSpaceTuoTempoTemuco.split(':');
  const [, idValidivia] = nameSpaceTuoTempoValdivia.split(':');
  const [, idSalesforce] = nameSpaceSalesforce.split(':');
  let arrayStatusNamespaces = await appsErrorsHelper(arrayProblem, [idTemuco, idValidivia, idSalesforce]);
  const statusTemuco = arrayStatusNamespaces[0];
  const statusValdivia = arrayStatusNamespaces[1];
  const statusSalesforce = arrayStatusNamespaces[2];

  let arrayStatusRoble = await appsByNucleoErrorsHelper(nameSpaceRoble, arrayProblem, arrayRoble);
  let arrayStatusAgo = await appsByNucleoErrorsHelper(nameSpaceAgo, arrayProblem, arrayAgo);
  let arrayStatusQuimio = await appsByNucleoErrorsHelper(nameSpaceQuimio, arrayProblem, arrayQuimio);
  let arrayStatusSistdep = await appsByNucleoErrorsHelper(nameSpaceSistdep, arrayProblem, arraySistdep);
  let arrayStatusNucleo = await appsByNucleoErrorsHelper(nameSpaceNucleo, arrayProblem, arrayNucleo);
  let arrayStatusSap = await appsByNucleoErrorsHelper(nameSpaceSap, arrayProblem, arraySap);
  const statusRoble = arrayStatusRoble[0];
  const statusAgo = arrayStatusAgo[0];
  const statusQuimio = arrayStatusQuimio[0];
  const statusSistdep = arrayStatusSistdep[0];
  const statusNucleo = arrayStatusNucleo[0];
  const statusSap = arrayStatusSap[0];

  let arrayStatusApiClientes = await appsByNucleoErrorsHelper(nameSpaceApiClientes, arrayProblem, arrayApiClientes);
  const statusOperacionesCli = arrayStatusApiClientes[0];
  const statusConsultasCli = arrayStatusApiClientes[1];
  const statusJur = arrayStatusApiClientes[2];

  let arrayStatusApiParam = await appsByNucleoErrorsHelper(nameSpaceApiParam, arrayProblem, arrayApiParam);
  const statusApiParam = arrayStatusApiParam[0];

  let arrayStatusV4 = await appsByNucleoErrorsHelper(nameSpaceClientesV4, arrayProblem, arrayClientesV4);
  const statusV4 = arrayStatusV4[0];
  // console.log(statusConsultasCli);
  // console.log(number_status);

  const replicaClienteDynatraceStatus = {
    natsStream: statusNatsStream ? statusNatsStream['status'] : 'CRITICAL',
    jurCas: jurCas ? jurCas['status'] : 'CRITICAL',
    jurCat: jurCat ? jurCat['status'] : 'CRITICAL',
    jurCav: jurCav ? jurCav['status'] : 'CRITICAL',
    natCas: natCas ? natCas['status'] : 'CRITICAL',
    natCat: natCat ? natCat['status'] : 'CRITICAL',
    natCav: natCav ? natCav['status'] : 'CRITICAL',
    desencoladores: Math.max(
      number_status[jurCas],
      number_status[jurCat],
      number_status[jurCav],
      number_status[natCas],
      number_status[natCat],
      number_status[natCav]
    ),
    // natCavSrv: natCavSrv ? natCavSrv['status'] : 'CRITICAL',
    fase1: statusFase1 ? statusNatsStream['status'] : 'CRITICAL',
    temuco: statusTemuco || 'CRITICAL',
    valdivia: statusValdivia || 'CRITICAL',
    salesforce: statusSalesforce || 'CRITICAL',
    cliOperaciones: statusOperacionesCli ? statusOperacionesCli['status'] : 'CRITICAL',
    cliConsultas: statusConsultasCli ? statusConsultasCli['status'] : 'CRITICAL',
    cliStatus: statusOperacionesCli['status'] === 'OK' ? statusOperacionesCli['status'] : statusConsultasCli['status'],
    paramStatus: statusApiParam ? statusApiParam['status'] : 'CRITICAL',
    jurStatus: statusJur ? statusJur['status'] : 'CRITICAL',
    v4Status: statusV4 ? statusV4['status'] : 'CRITICAL',
    robleStatus: statusRoble ? statusRoble['status'] : 'CRITICAL',
    agoStatus: statusAgo ? statusAgo['status'] : 'CRITICAL',
    quimioStatus: statusQuimio ? statusQuimio['status'] : 'CRITICAL',
    sistdepStatus: statusSistdep ? statusSistdep['status'] : 'CRITICAL',
    nucleoStatus: statusNucleo ? statusNucleo['status'] : 'CRITICAL',
    sapStatus: statusSap ? statusSap['status'] : 'CRITICAL',
  };
  return replicaClienteDynatraceStatus;
}

export function getClientesIndicadores(infoHosts) {
  const oracleObject = { items: {}, status: 0 };
  let globalStatus = 'OK';
  if (infoHosts && infoHosts['ROBLE_ORACLE_PSOL7']) {
    infoHosts['ROBLE_ORACLE_PSOL7'].forEach((item) => {
      let tmpStatus = 0;
      item.triggers.forEach((trg) => {
        if (trg.value != 0 && trg.priority > tmpStatus) {
          tmpStatus = trg.priority;
        }
      });
      if (item.name.includes('Numero de sesiones')) {
        const [tmpStr, entityDb] = item.name.split('Numero de sesiones activas ');
        if (!oracleObject['items'][entityDb]) {
          oracleObject['items'][entityDb] = { label: 'Sistema', status: tmpStatus, width: '198' };
        } else {
          if (oracleObject['items'][entityDb]['status'] < tmpStatus) {
            oracleObject['items'][entityDb]['status'] = tmpStatus;
            globalStatus = zabbix_states[tmpStatus];
          }
        }
      } else if (item.name.includes('Profundidad de cola')) {
        const [tmpStr, entityDb] = item.name.split('Profundidad de cola de sesiones en espera ');
        if (!oracleObject['items'][entityDb]) {
          oracleObject['items'][entityDb] = { label: 'Sistema', status: tmpStatus, width: '198' };
        } else {
          if (oracleObject['items'][entityDb]['status'] < tmpStatus) {
            oracleObject['items'][entityDb]['status'] = tmpStatus;
            globalStatus = zabbix_states[tmpStatus];
          }
        }
      } else {
        if (oracleObject['status'] < tmpStatus) {
          oracleObject['status'] = tmpStatus;
          globalStatus = zabbix_states[tmpStatus];
        }
      }
    });
  }
  const response = { oracle: oracleObject, globalStatus };
  return response;
}

async function appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayWorkFlow) {
  let temp = [];
  for (let i = 0; i < arrayWorkFlow.length; i++) {
    let flag = 'OK';
    let dataNameSpace = await api.getNamespaceIds(nameSpace, arrayWorkFlow[i]);
    // console.log(dataNameSpace);

    if (dataNameSpace && dataNameSpace.totalCount == 1) {
      let id = dataNameSpace.entities[0].entityId;
      let name = dataNameSpace.entities[0].displayName;
      let dataTemp = await api.getAppData(id);
      // console.log(dataTemp);

      let toRelationships = dataTemp.toRelationships;
      Object.values(toRelationships).forEach((arr) => {
        arr.forEach((relation) => {
          if (arrayProblem.includes(relation.id)) {
            flag = 'CRITICAL';
            //console.log(relation);
          }
        });
      });
      temp.push({
        id: id,
        name: name,
        status: flag ? 'OK' : 'CRITICAL',
      });
    }
  }
  //console.log(temp);
  return temp;
}

async function appsErrorsHelper(arrayProblem, arrayId) {
  let temp = [];
  for (let i = 0; i < arrayId.length; i++) {
    let flag = 'OK';
    let id = arrayId[i];
    let dataTemp = await api.getAppData(id);
    // console.log(dataTemp);

    let toRelationships = dataTemp.toRelationships;
    Object.values(toRelationships).forEach((arr) => {
      arr.forEach((relation) => {
        if (arrayProblem.includes(relation.id)) {
          flag = 'CRITICAL';
          console.log(relation);
        }
      });
    });
    temp.push(flag);
  }
  //console.log(temp);
  return temp;
}

export async function appsByNucleoErrors(infoHosts) {
  const copyInfoHosts = JSON.parse(JSON.stringify(infoHosts));
  const kubernetesProblems = copyInfoHosts['tanzuProblems'] || [];
  const arrayProblem = [];
  kubernetesProblems.forEach((kbProblem) => {
    if (kbProblem.status === 'OPEN') {
      const objectMng = JSON.parse(kbProblem.impactedEntities);
      objectMng.forEach((mng) => {
        arrayProblem.push(mng.entityId.id);
      });
    }
  });

  let farmacy = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayFarmacyWorkflow);
  let nucleo = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayNucleoWorkflow);
  let manteiner = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayMantainerWorkflow);
  let digital = await appsByNucleoErrorsHelper(nameSpace, arrayProblem, arrayDigitalWorkflow);

  return {
    farmacy: farmacy,
    nucleo: nucleo,
    manteiner: manteiner,
    digital: digital,
  };
}

export function createDataforImedDashboard(info){
  const arrUrls = [];
  let problems = 0;
  let globalStatus = "OK";
  info.undefined.forEach(element => {
    let url = element.description.split(" ")[1];
    let name = element.tags.find((tag) => tag.tag === "dashboard_item_name").value;
    let label = element.tags.find((tag) => tag.tag === "dashboard_item_label").value;
    let status = "OK";
    if(element.value != 0){
      status = zabbix_states[element.priority]
      if(element.priority > 1){
        problems +=1;
        if(element.priority > 3){
          globalStatus = "CRITICAL";
        }
        if(element.priority < 4 && globalStatus !== "CRITICAL"){
          globalStatus = "WARNING";
        }
      }
    }
    arrUrls.push({url: url, name: name, label: label, status: status})
  });
  const total =  info.undefined.length;
  return { urls: arrUrls, globalStatus, total, problems };
}
//'https://tpw16984.live.dynatrace.com/api/v2/entities/CLOUD_APPLICATION-F84221B9E4BC5B33'
