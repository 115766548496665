// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imed-view .back-icon {
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.imed-view .back-icon img {
width: 40px;
}

.imed-view .content{
    display: grid;
    grid-template-columns: repeat(3, 500px);
    gap: 20px;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./views/Imed/Imed.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;AACA,WAAW;AACX;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,SAAS;IACT,8BAA8B;AAClC","sourcesContent":[".imed-view .back-icon {\n    position: absolute;\n    right: 50px;\n    top: 50px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n}\n\n.imed-view .back-icon img {\nwidth: 40px;\n}\n\n.imed-view .content{\n    display: grid;\n    grid-template-columns: repeat(3, 500px);\n    gap: 20px;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
