const nameSpaceReplicaCliente = 'cas-replica-cliente';

const arrayReplicaClienteApps = [
  'client-nats-01',
  'integracion-clientes-fase1',
  'client-nats-jetstream',
  'desencolador-jur-cas',
  'desencolador-jur-cat',
  'desencolador-jur-cav',
  'desencolador-nat-cas',
  'desencolador-nat-cat',
  'desencolador-nat-cav',
  // 'desencolador-nat-cav-srv'
];

const nameSpaceTuoTempoTemuco = 'cat-tuotempo:CLOUD_APPLICATION_NAMESPACE-6931AA9662EAC8B9';

const nameSpaceTuoTempoValdivia = 'cav-tuotempo:CLOUD_APPLICATION_NAMESPACE-84A2A8094B153C36';

const nameSpaceSalesforce = 'cas-salesforce:CLOUD_APPLICATION_NAMESPACE-8246B6B90045100E';

const nameSpaceApiClientes = 'cas-api-clientes';

const arrayApiClientes = ['apicli-operaciones-backend', 'cli-consultaidcliente', 'apijur-backend'];

const nameSpaceApiParam = 'cas-apiparam';

const arrayApiParam = ['apiparam-backend'];

const nameSpaceClientesV4 = 'cas-clientesv4';

const arrayClientesV4 = ['clientesv4-backend'];

const nameSpaceRoble = 'cas-sisroble';

const arrayRoble = ['app-admission-subcliper'];

const nameSpaceAgo = 'cas-alemana-go';

const arrayAgo = ['a-go-agent'];

const nameSpaceQuimio = 'cas-age-quimio-process';

const arrayQuimio = ['client-qp-agent'];

const nameSpaceSistdep = 'cas-sist-departamentales';

const arraySistdep = ['sist-departamentales'];

const nameSpaceNucleo = 'cas-nucleo-digital';

const arrayNucleo = ['cas-mf-nuc-cli'];

const nameSpaceSap = 'cas-int-sap';

const arraySap = ['conector-clientenatural-sap'];

// const nameSpaceApijur = 'apijur-backend';

export {
  nameSpaceReplicaCliente,
  arrayReplicaClienteApps,
  nameSpaceTuoTempoTemuco,
  nameSpaceTuoTempoValdivia,
  nameSpaceSalesforce,
  nameSpaceApiClientes,
  arrayApiClientes,
  nameSpaceApiParam,
  arrayApiParam,
  nameSpaceClientesV4,
  arrayClientesV4,
  nameSpaceRoble,
  arrayRoble,
  nameSpaceAgo,
  arrayAgo,
  nameSpaceQuimio,
  arrayQuimio,
  nameSpaceSistdep,
  arraySistdep,
  nameSpaceNucleo,
  arrayNucleo,
  nameSpaceSap,
  arraySap,
};
