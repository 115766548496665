const firewallContent = [
    {
        "label": "Datacenter-3",
        "hosts": [

            {
                label: "F5 Externo 03",
                hostName: "F5 Externo",
                title: "F5 Externo",
                services: [
                    { label: "Estado", serviceName: "F5 BIG-IP: Failover status" },
                    { label: "Uptime", serviceName: "F5 BIG-IP: Uptime" },
                    { label: "CPU", serviceName: "CPU Utilization" },
                    { label: "Memoria", serviceName: "Memory Utilization" },
                ]
            },
            {
                label: "FW PALO ALTO PERÍMETRO 01",
                hostName: "PAN-01",
                services: [
                    { label: "Estado", serviceName: "HA State" },
                    { label: "Uptime", serviceName: "System Uptime" },
                    { label: "Processor MGMT", serviceName: "Processor 1 Load (mgmt)" },
                    { label: "Processor Data", serviceName: "Processor 2 Load (data)" },
                    { label: "Memoria", serviceName: "Memory utilization" },
                ],
            },
            {
                label: "F5 Interno 01",
                hostName: "F5 Interno",
                title: "F5 Interno",
                services: [
                    { label: "Estado", serviceName: "F5 BIG-IP: Failover status" },
                    { label: "Uptime", serviceName: "F5 BIG-IP: Uptime" },
                    { label: "CPU", serviceName: "CPU Utilization" },
                    { label: "Memoria", serviceName: "Memory Utilization" },
                ]
            },
            {
                label: "FW CHECKPOINT DATACENTER",
                hostName: "FW-DC-INFO",
                services: [
                    { label: "Uptime", serviceName: "Check Point: System uptime" },
                    { label: "CPU", serviceName: "Check Point: CPU utilization" },
                    { label: "Memoria", serviceName: "Check Point: Memory utilization" },
                ],
            },
        ]
    },
    {
        "label": "Planta Telefónica",
        "hosts": [

            {
                label: "F5 Externo 04",
                hostName: "CAS-LB-ADC-04.alemana.cl",
                title: "F5 Externo",
                services: [
                    { label: "Estado", serviceName: "F5 BIG-IP: Failover status" },
                    { label: "Uptime", serviceName: "F5 BIG-IP: Uptime" },
                    { label: "CPU", serviceName: "CPU Utilization" },
                    { label: "Memoria", serviceName: "F5 BIG-IP: Host [0]: Used memory" },
                ]
            },
            {
                label: "FW PALO ALTO PERÍMETRO 02",
                hostName: "PAN-02",
                services: [
                    { label: "Estado", serviceName: "HA State" },
                    { label: "Uptime", serviceName: "System Uptime" },
                    { label: "Processor MGMT", serviceName: "Processor 1 Load (mgmt)" },
                    { label: "Processor Data", serviceName: "Processor 2 Load (data)" },
                    { label: "Memoria", serviceName: "Memory utilization" },
                ]
            },
            {
                label: "F5 Interno 02",
                hostName: "CAS-LB-ADC-02.alemana.cl",
                title: "F5 Interno",
                services: [
                    { label: "Estado", serviceName: "F5 BIG-IP: Failover status" },
                    { label: "Uptime", serviceName: "F5 BIG-IP: Uptime" },
                    { label: "CPU", serviceName: "CPU Utilization" },
                    { label: "Memoria", serviceName: "F5 BIG-IP: Host [0]: Used memory" },
                ]
            },
            {
                label: "FW CHECKPOINT PLANTA",
                hostName: "FW-DC-PLANTA",
                services: [
                    { label: "Uptime", serviceName: "Check Point: System uptime" },
                    { label: "CPU", serviceName: "Check Point: CPU utilization" },
                    { label: "Memoria", serviceName: "Check Point: Memory utilization" },
                ],
            },
        ]
    }
];


const clusterElements = [
    { idBox1: 'F5 Externo', idBox2: 'CAS-LB-ADC-04.alemana.cl' },
    { idBox1: 'PAN-01', idBox2: 'PAN-02' },
    { idBox1: 'F5 Interno', idBox2: 'CAS-LB-ADC-02.alemana.cl' },
    { idBox1: 'FW-DC-INFO', idBox2: 'FW-DC-PLANTA' },
]

export { firewallContent, clusterElements };
