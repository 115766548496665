import React, { useEffect } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import './Imed.css';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import InfoHost from '../../components/InfoHost/InfoHost';

function Imed({ infoImed, selectMainDashboard, }) {

    console.log(infoImed)

    const data = infoImed.map((item) =>
        <CardWrapper 
            status={item.status}
            mainContent={<InfoHost iconName={'web'} type={item.label} hostname={item.name} ip={item.url} />}
            items={[]}
            style={{
                width: '400px', 
                height: "150px",
            }}
        />
    );

    return(
        <section className='imed-view'>
            <Toolbar title={`Imed`} />
            <div className='content'>
                {data}
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default Imed;