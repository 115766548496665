import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import CardVmware from '../../components/CardVmware/CardVmware';
import './VDI.css';

function VDI({ selectMainDashboard, infoDashboard }) {

    const [vcenterObject, setVcenterObject] = useState([]);

    useEffect(() => {
        if (infoDashboard['vcenter']) {
            setVcenterObject(infoDashboard['vcenter']);
        }
        //console.log(infoDashboard);
    }, [infoDashboard]);

    function getVcenter() {
        console.log("Data:", vcenterObject);
        const vcenterRest = [];

        const arrayVcenter = [];
        for (const vcenter in vcenterObject) {
            let type = 0;

            const vcenterInfo = vcenterObject[vcenter];
            const arrayVcenterItems = [];
            const arrayVcenterItemsAux = [];

            let size = undefined;
            
            for (const vcenterHostname in vcenterInfo) {
                const vcenterHostInfo = vcenterInfo[vcenterHostname];
                if (vcenterHostname === 'status') {
                } else {
                    let temp = <CardVmware
                        host={{ name: vcenterHostname, status: vcenterHostInfo.status }}
                        uptime={{ status: vcenterHostInfo['Uptime'].status }}
                        overall_status={{ status: vcenterHostInfo['Overall_status'].status }}
                        cpu={{ status: vcenterHostInfo['CPU'].status }}
                        memory={{ status: vcenterHostInfo['Memory'].status }}
                        size = {size}
                    />
                    if(vcenterHostname.includes("tacora")){
                        arrayVcenterItemsAux.push(temp);
                    }else{
                        arrayVcenterItems.push(temp);
                        if(vcenterHostname.includes("carora")){
                            type = 1;
                        }
                    }

                }
            }
            
            if(vcenter !== "altamar"){
                vcenterRest.push(
                    <div className="vcenter">
                        <div className="vcenter-title">
                            <div style={{ fontWeight: 'bold' }}>{vcenter}</div>
                        </div>
                        <div className="vcenter-title">
                            <div style={{ fontWeight: 'bold' }}>{arrayVcenter}</div>
                        </div>
                        <div className="vcenter-items lascar-items">
                            <div style={{ fontWeight: 'bold' }}>
                                {type ? "VDI-DT-EMO" : "VDI La Dehesa"}
                            </div>
                            {type ? arrayVcenterItems:  arrayVcenterItemsAux }  
                        </div>
                        <div className="vcenter-items lascar-items">
                            <div style={{ fontWeight: 'bold' }}>
                                {type ? "VDI-Vitacura" : "VxRail VDI"}
                            </div>
                            {type ? arrayVcenterItemsAux: arrayVcenterItems}                            
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className='wrapper-lascar'>{vcenterRest}</div>
        );
    }

    return (
        <section className="bdi-view">
            <Toolbar title={`VDI`} />
            <div className='vmware'>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Vmware</div>
                {getVcenter()}
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default VDI;