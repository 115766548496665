// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { getStatusColorByType } from '../../utils/color';
import CustomTooltip from 'components/Tooltip/Tooltip.tsx';

import './EnlaceConexion.css';

type Props = {
  enlaces: any;
  showBoundCountries: any;
  showBoundMain: any;
};

const EnlaceConexion: React.FC<Props> = ({
  enlaces,
  scale,
  showBoundCountries,
  showBoundMain,
  speedEnlaces,
  overloadedSpeed,
  unusedSpeed,
}) => {
  //console.log(enlaces);

  const [hoverEnlace, setHoverEnlace] = useState(null);
  const [infoSpeedEnlaces, setInfoSpeedEnlaces] = useState({});

  useEffect(() => {
    if (speedEnlaces.length) {
      const infoObject = {};
      speedEnlaces.forEach((itemSpeed) => {
        const [id, speed] = itemSpeed.split(';');
        infoObject[id] = speed;
      });
      setInfoSpeedEnlaces(infoObject);
    }
  }, [speedEnlaces, enlaces]);

  // console.log(infoSpeedEnlaces);

  function getBorder(type) {
    if (type === '1 Gb') {
      return '1px dashed #000000';
    } else if (type === '300 Mb') {
      return '1px dashed #fb34d3';
    } else if (type === '100 Mb') {
      return '1px dashed #ffa55d';
    } else if (type === '120 Mb') {
      return '1px dashed #39ff14';
    }
  }

  function deltaPosition(type) {
    if (type === '1 Gb') {
      return -3;
    } else if (type === '300 Mb') {
      return 0;
    } else if (type === '100 Mb') {
      return -5;
    } else if (type === '120 Mb') {
      return -5;
    }
  }

  function showTip(type) {
    // console.log(type);

    setHoverEnlace(type);
  }

  function hideTip() {
    setHoverEnlace(null);
  }

  // function getColorEnlace(status, enlace_id, inbound, outbound) {
  //   const limitSpeed = infoSpeedEnlaces[enlace_id];
  //   if (status === 'OK') {
  //     const inboundUtilizationPercentage = (inbound * 100) / limitSpeed;
  //     const outboundUtilizationPercentage = (outbound * 100) / limitSpeed;
  //     if (inboundUtilizationPercentage >= overloadedSpeed || outboundUtilizationPercentage >= overloadedSpeed) {
  //       status = 'OVERLOADED';
  //     }
  //     if (inboundUtilizationPercentage <= unusedSpeed || outboundUtilizationPercentage <= unusedSpeed) {
  //       status = 'NOLOADED';
  //     }
  //   }
  //   return getStatusColorByType(status);
  // }

  function formatSpeed(speed) {
    let result;
    if (speed >= 1e9) {
      result = (speed / 1e9).toFixed(2) + ' Gbps';
    } else if (speed >= 1e6) {
      result = (speed / 1e6).toFixed(2) + ' Mbps';
    } else if (speed >= 1e3) {
      result = (speed / 1e3).toFixed(2) + ' Kbps';
    } else {
      result = speed + ' bps';
    }
    return result;
  }
  // tooltipMessage, enlaceColor

  function getInfoEnlace(status, enlace_id, inbound, outbound, label) {
    const limitSpeed = infoSpeedEnlaces[enlace_id];
    let inboundUtilizationPercentage = '';
    let outboundUtilizationPercentage = '';
    // console.log(status);

    // console.log(overloadedSpeed);
    // console.log(unusedSpeed);
    inboundUtilizationPercentage = (inbound * 100) / limitSpeed;
    outboundUtilizationPercentage = (outbound * 100) / limitSpeed;
    if (inbound === undefined || outbound == undefined) {
      status = 'NOLOADED';
    }
    if (limitSpeed === undefined) {
      status = 'NOLOADED';
    }
    if (status === 'OK') {
      if (
        inboundUtilizationPercentage >= parseInt(overloadedSpeed, 10).toFixed(2) ||
        outboundUtilizationPercentage >= parseInt(overloadedSpeed, 10).toFixed(2)
      ) {
        status = 'OVERLOADED';
      }
      if (
        inboundUtilizationPercentage <= parseInt(unusedSpeed, 10).toFixed(2) ||
        outboundUtilizationPercentage <= parseInt(unusedSpeed, 10).toFixed(2)
      ) {
        status = 'NOLOADED';
      }
    }
    return {
      enlaceColor: getStatusColorByType(status),
      tooltipMessage: `In: ${formatSpeed(inbound)} (${inboundUtilizationPercentage} %), Out: ${formatSpeed(
        outbound
      )} (${outboundUtilizationPercentage} %), ${label}`,
    };
  }

  function getEnlacesLine() {
    const arrayAuxEnlace = [];
    //console.log(enlaces);

    enlaces.forEach((enlace) => {
      let from = undefined;
      let to = undefined;
      let deltax1 = -4;
      let deltay1 = -22;
      let deltax2 = 5;
      let deltay2 = 0;
      if (!enlace.id.includes('itacura')) {
        // console.log(enlace);
        from = document.getElementById(enlace.id);
        const rectFrom = from.getBoundingClientRect();
        const xToEnlFrom = rectFrom.left / scale;
        const yToEnlFrom = rectFrom.top / scale;

        to = document.getElementById('vitacura-img');
        const rectTo = to.getBoundingClientRect();
        const xToEnlTo = rectTo.left / scale;
        const yToEnlTo = rectTo.top / scale;

        const movableElement = document.getElementById('enlaces-view-content');
        const rectMv = movableElement.getBoundingClientRect();
        const mvLeft = rectMv.left / scale;
        const mvTop = rectMv.top / scale;

        deltax1 = - 4;
        deltay1 = - 22;
        deltax2 = + 15;
        deltay2 = + 30;
      } else {
        let fromPt = '';
        let toPt = '';
        if (enlace.id.includes('Vitacura-719')) {

        }
        if (enlace.id === 'Vitacura-core_agg_1.alemana.cl-Ethernet1/14') {
          fromPt = 'Vitacura-core_agg_1.alemana.cl-Ethernet1/14';
          toPt = 'sonda-icon-enlace';
          deltay2 = 50
        } else if (enlace.id === 'Vitacura-core_agg_1.alemana.cl-Ethernet3/18') {
          fromPt = 'Vitacura-core_agg_1.alemana.cl-Ethernet3/18';
          toPt = 'sonda-icon-enlace';
          deltay2 = 50
        } else if (enlace.id === 'Vitacura-core_agg_1.alemana.cl-Ethernet3/24') {
          fromPt = 'Vitacura-core_agg_1.alemana.cl-Ethernet3/24';
          toPt = 'sonda-icon-enlace';
          deltay2 = 50
        } else if (enlace.id === 'Vitacura-core_agg_1.alemana.cl-Ethernet1/8') {
          fromPt = 'Vitacura-core_agg_1.alemana.cl-Ethernet1/8';
          toPt = 'sonda-icon-oracle';
          deltax1 = -10;
          deltax2 = 40;
          deltay2 = 7;
        } else if (enlace.id === 'Vitacura-core_agg_2.alemana.cl-Ethernet1/8') {
          fromPt = 'Vitacura-core_agg_2.alemana.cl-Ethernet1/8';
          toPt = 'sonda-icon-oracle';
          deltax1 = -10;
          deltax2 = 40;
          deltay2 = 7;
        } else if (enlace.id === 'Vitacura-core_agg_2.alemana.cl-Ethernet3/18') {
          fromPt = 'Vitacura-core_agg_2.alemana.cl-Ethernet3/18';
          toPt = 'sonda-icon-enlace';
          deltay2 = 50
        } else if (enlace.id === 'Vitacura-71922_internet_inf-3-Gi1/0/14') {
          fromPt = 'Vitacura-71922_internet_inf-3-Gi1/0/14';
          toPt = 'sonda-icon-world';
        } else if (enlace.id === 'Vitacura-71922_internet_inf-3-Te1/1/3') {
          fromPt = 'Vitacura-71922_internet_inf-3-Te1/1/3';
          toPt = 'sonda-icon-cirion';
          deltax2 = 60;
          deltay2 = 40;
        } else if (enlace.id === 'Vitacura-71921_internet_pt-1-Gi1/0/24') {
          fromPt = 'Vitacura-71921_internet_pt-1-Gi1/0/24';
          toPt = 'sonda-icon-claro';
          deltax1 = -30;
          deltay1 = 0;
          deltax2 = 17;
          deltay2 = 5;
        } else if (enlace.id === 'Vitacura-71921_internet_pt-1-Gi1/0/16') {
          fromPt = 'Vitacura-71921_internet_pt-1-Gi1/0/16';
          toPt = 'sonda-icon-world';
        }
        else if (enlace.id === 'Vitacura-DC-3 Vitacura-1/0/1') {
          fromPt = 'Vitacura-DC-3 Vitacura-1/0/1'
          toPt = 'imed-icon-enlace'
          deltay2 = 50
        }
        else if (enlace.id === 'Vitacura-DC-3 Vitacura-1/0/2') {
          fromPt = 'Vitacura-DC-3 Vitacura-1/0/2'
          toPt = 'transbank-icon-enlace'
          deltax2 = 30
          deltay2 = 50
        }
        else if (enlace.id === 'Vitacura-DC-3 Vitacura-1/0/3') {
          fromPt = 'Vitacura-DC-3 Vitacura-1/0/3'
          toPt = 'prego-icon-enlace'
          deltay2 = 50
        }
        else if (enlace.id === 'Vitacura-Planta telefónica -1-1/0/1') {
          fromPt = 'Vitacura-Planta telefónica -1-1/0/1'
          toPt = 'imed-icon-enlace'
          deltay2 = 50
        }
        else if (enlace.id === 'Vitacura-Planta telefónica -1-1/0/2') {
          fromPt = 'Vitacura-Planta telefónica -1-1/0/2'
          toPt = 'transbank-icon-enlace'
          deltax2 = 30
          deltay2 = 50
        }
        else if (enlace.id === 'Vitacura-Planta telefónica -1-1/0/3') {
          fromPt = 'Vitacura-Planta telefónica -1-1/0/3'
          toPt = 'prego-icon-enlace'
          deltay2 = 50
        }
        from = document.getElementById(fromPt);
        to = document.getElementById(toPt);
      }
      const { tooltipMessage, enlaceColor } = getInfoEnlace(
        enlace.portStatus,
        enlace.id,
        enlace.inbound,
        enlace.outbound,
        enlace.label
      );

      const rectFrom = from.getBoundingClientRect();
      const xToEnlFrom = rectFrom.left / scale;
      const yToEnlFrom = rectFrom.top / scale;

      const rectTo = to.getBoundingClientRect();
      const xToEnlTo = rectTo.left / scale;
      const yToEnlTo = rectTo.top / scale;

      const movableElement = document.getElementById('enlaces-view-content');
      const rectMv = movableElement.getBoundingClientRect();
      const mvLeft = rectMv.left / scale;
      const mvTop = rectMv.top / scale;

      let x1 = xToEnlFrom - mvLeft + deltax1;
      let y1 = yToEnlFrom - mvTop + deltay1;
      let x2 = xToEnlTo - mvLeft + deltax2;
      let y2 = yToEnlTo - mvTop + deltay2;

      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI) + 90;

      const topTmpLine = y2 > y1 ? y1 : y2;
      const height = y2 > y1 ? y2 - y1 : y1 - y2;

      const delta = (length - height) / 2;

      const topLine = topTmpLine - delta;
      arrayAuxEnlace.push(
        <>
          <div
            className='line'
            style={{
              position: 'absolute',
              border: `1px dashed ${enlaceColor}`,
              left: `${x2 + (x1 - x2) / 2 + 15}px`,
              top: `${topLine + 20}px`,
              width: `${1}px`,
              height: length,
              transform: `rotate(${angle}deg)`,
              visibility: 'visible',
            }}
          ></div>
          <div
            className='line-center'
            style={{
              position: 'absolute',
              left: `${x2 + (x1 - x2) / 2 + 15 + deltaPosition('300 Mb')}px`,
              top: `${topLine + 20}px`,
              width: `${12}px`,
              height: length,
              transform: `rotate(${angle}deg)`,
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              visibility: 'visible',
            }}
            onMouseEnter={() => showTip(`${enlace.id}_vitacura-img`)}
            onMouseLeave={() => hideTip()}
          >
            <div style={{ height: '70%' }} />
          </div>
          {enlace.inbound && enlace.outbound ? (
            <div
              className='circle'
              style={{
                position: 'absolute',
                left: `${(x1 + x2) / 2}px`,
                top: `${(y2 + y1) / 2}px`,
              }}
            >
              <CustomTooltip
                content={tooltipMessage}
                direction={'top'}
                show={enlace.id + '_' + 'vitacura-img' === hoverEnlace ? true : false}
              ></CustomTooltip>
            </div>
          ) : null}
        </>
      );

    });
    return arrayAuxEnlace;
  }
  return <section className='enlace-conexion-cmpt'>{getEnlacesLine()}</section>;
};

export default EnlaceConexion;
