// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fw-view {
    width: 100%;
    height: 100%;
    background-color: #f2f6fe;
    border-radius: 30px;
    padding: 20px;
    color: #596c88;
    position: relative;
}

.fw-view .back-icon {
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.fw-view .back-icon img {
    width: 40px;
}

.fw-view .content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 76px);
}`, "",{"version":3,"sources":["webpack://./views/Firewall/Firewall.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".fw-view {\n    width: 100%;\n    height: 100%;\n    background-color: #f2f6fe;\n    border-radius: 30px;\n    padding: 20px;\n    color: #596c88;\n    position: relative;\n}\n\n.fw-view .back-icon {\n    position: absolute;\n    right: 50px;\n    top: 50px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n}\n\n.fw-view .back-icon img {\n    width: 40px;\n}\n\n.fw-view .content{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: calc(100% - 76px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
