import React, { useEffect, useState, useRef } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardTransbank from '../../components/CardTransbank/CardTransbank';
import CardInfoFW from '../../components/CardInfoFW/CardInfoFW';
import InfoItem from '../../components/InfoItem/InfoItem';
import { icons } from '../../utils/dictionaryIcons';
import './Firewall.css';
import { firewallContent, clusterElements } from '../../utils/firewallComponents';
import InfoHost from '../../components/InfoHost/InfoHost';

function Firewall({ infoDashboard, selectMainDashboard, scale }) {

    const containerRef = useRef(null);
    const [dashedBorders, setDashedBorders] = useState([]);

    useEffect(() => {
        
        const dashedBorderElems = clusterElements.map((clusterElem) =>{
            const box1El = document.getElementById(clusterElem.idBox1);
            const box2El = document.getElementById(clusterElem.idBox2);
    
            const box1 = box1El.getBoundingClientRect();
            const box2 = box2El.getBoundingClientRect();
            const container = containerRef.current.getBoundingClientRect();
            return getDashedBorder(box1, box2, container);
        });

        setDashedBorders(dashedBorderElems);

    }, [scale]);

    function getDashedBorder(box1, box2, container) {
        const relativeBox1 = {
            left: (box1.left - container.left) / scale,
            top: (box1.top - container.top) / scale,
            width: box1.width / scale,
            height: box1.height / scale,
        };

        const relativeBox2 = {
            left: (box2.left - container.left) / scale,
            top: (box2.top - container.top) / scale,
            width: box2.width / scale,
            height: box2.height / scale,
        };
        return DashedBorder(relativeBox1, relativeBox2)
    }

    const content = firewallContent.map(fwContent => {
        const hosts = fwContent.hosts.map(host => {
            const hostData = infoDashboard[host.hostName];
            const servicesItems = host.services.map(sv => {
                const service = hostData.items.find(item => item.itemName.includes(sv.serviceName));
                let label = sv.label;
                let status = service ? service.statusItem : 'UNKNOWN';
                if (label === 'Estado') {
                    if (service.statusItem === 'OK') {
                        label = 'Activo';
                    }
                    else {
                        label = 'Stand-By';
                        status = 'UNKNOWN';
                    }
                }
                return <InfoItem itemName={label} status={status} style={{ marginTop: '0px', padding: '4px 10px', marginBottom: '4px' }} />
            })
            return (
                <CardInfoFW
                    id={host.hostName}
                    header={
                        <InfoHost hostname={host.label} ip={host.hostName} />
                    }
                    items={servicesItems}
                    status={hostData.status}
                    style={host.border ? {} : { borderWidth: '0px' }}
                />
            )
        })
        return <CardTransbank label={fwContent.label} iconName={'firewall'} style={{ width: '350px', margin: '0 30px' }}>{hosts}</CardTransbank>;
    })


    return (
        <section className='fw-view' ref={containerRef}>
            <Toolbar title={`Firewall`} />
            <div className='content'>
                {content}
            </div>
            <div className='back-icon' onClick={selectMainDashboard}>
                <img src={icons['back']} />
            </div>
            {dashedBorders}

        </section>
    )
}

const DashedBorder = (box1, box2) => {
    const left = Math.min(box1.left, box2.left);
    const top = Math.min(box1.top, box2.top);
    const right = Math.max(box1.left + box1.width, box2.left + box2.width);
    const bottom = Math.max(box1.top + box1.height, box2.top + box2.height);

    const width = right - left;
    const height = bottom - top;

    return (
        <div
            style={{
                position: 'absolute',
                left,
                top,
                width,
                height,
                border: '2px dashed BLACK',
                borderRadius: '10px',
                pointerEvents: 'none',
                zIndex: 9999,
            }}
        />
    );
};

export default Firewall;