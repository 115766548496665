const zabbix_states = {
  0: 'OK',
  1: 'OK',
  2: 'WARNING',
  3: 'WARNING',
  4: 'CRITICAL',
  5: 'CRITICAL',
};

const global_states = {
  0: 'OK',
  1: 'WARNING',
  2: 'CRITICAL',
  3: 'UNKNOWN',
};

const color_status = {
  OK: '#0CD808',
  CRITICAL: '#FF5555',
  NOTDEFINED: '#AEB0B2',
  UNK: '#AEB0B2',
  UNKNOWN: '#AEB0B2',
  WARNING: 'yellow'
};

const number_status = {
  OK: 0,
  CRITICAL: 2,
  NOTDEFINED: 4,
  UNK: 3,
  UNKNOWN: 3,
  WARNING: 1
}

export { zabbix_states, color_status, number_status, global_states };
