import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import Heatmap from '../../components/Heatmap/Heatmap'
import InfoItemIndicadores from '../../components/InfoItemIndicadores/InfoItemIndicadores';
import './Tanzu.css';



function Tanzu({ selectMainDashboard, infoDashboard , tanzu}) {
    const [dynatrace, setDynatrace] = useState(null);

    useEffect(() => {
        if (infoDashboard['dynatrace']) {
            setDynatrace(infoDashboard['dynatrace']);
        }
        //console.log(infoDashboard);
    }, [infoDashboard, tanzu]);

    function getDynatrace() {
        // console.log();
        const objDynatrace = dynatrace;
        const items = [];
        if (objDynatrace) {
            // console.log(objDynatrace['cpu'][0]);
            // console.log(objDynatrace['cpu'][0]['data']);
            items.push(
                <InfoItemIndicadores
                    status={'OK'}
                    itemName={`CPU - ${objDynatrace['cpu'][0]['data'][0]['values'][0].toFixed(2)}%`}
                    key={`cpu-dyna`}
                    style={{ width: '170px', height: '24px' }}
                />
            );
            items.push(
                <InfoItemIndicadores
                    status={'OK'}
                    itemName={`Memory - ${objDynatrace['memory'][0]['data'][0]['values'][0].toFixed(2)}%`}
                    key={`memory-dyna`}
                    style={{ width: '170px', height: '24px' }}
                />
            );
        }
        return (
            <div className='dyna-items'>
                {items}
            </div>
        );
    }

    return (
        <section className="tanzu-view">
            <Toolbar title={`Tanzu`} />
            <div className='dynatrace-section'>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Tuxedo</div>
                {getDynatrace()}
                <div id="tooltip" className="tooltip"></div>
                {<Heatmap tanzu={tanzu} />}
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default Tanzu;