// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tanzu-view {
    color: #596c88;
    height: 100%;
    background-color: #f2f6fe;
  }

.tanzu-view .back-icon{
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.tanzu-view .back-icon img{
    width: 40px;
}

.tanzu-view .dynatrace-section {
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    align-items: center;
    height: 100%;
}

.tanzu-view .dynatrace-section .dyna-items {
	padding: 15px;
	border-radius: 10px;
	background-color: #ffffff;
	margin: 10px 0;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 200px;
}

.tanzu-view .dynatrace-section .App{
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./views/Tanzu/Tanzu.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,yBAAyB;EAC3B;;AAEF;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;CACnB,iDAAiD;IAC9C,mBAAmB;IACnB,YAAY;AAChB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,yBAAyB;CACzB,cAAc;CACd,iDAAiD;IAC9C,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".tanzu-view {\n    color: #596c88;\n    height: 100%;\n    background-color: #f2f6fe;\n  }\n\n.tanzu-view .back-icon{\n    position: absolute;\n    right: 50px;\n    top: 50px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n}\n\n.tanzu-view .back-icon img{\n    width: 40px;\n}\n\n.tanzu-view .dynatrace-section {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 5px;\n\tborder-radius: 10px;\n\tbox-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    align-items: center;\n    height: 100%;\n}\n\n.tanzu-view .dynatrace-section .dyna-items {\n\tpadding: 15px;\n\tborder-radius: 10px;\n\tbackground-color: #ffffff;\n\tmargin: 10px 0;\n\tbox-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    width: 200px;\n}\n\n.tanzu-view .dynatrace-section .App{\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
