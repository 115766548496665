import React from 'react';
import SVG from 'svg.js';
import { extendHex, defineGrid } from 'honeycomb-grid';
import './style.css';

// Función para calcular filas y columnas equilibradas
function splitNumber(num) {
  const sqrt = Math.sqrt(num);
  const rows = Math.floor(sqrt);
  const cols = Math.ceil(num / rows);
  return [cols, rows];
}

export default function App({ tanzu = [] }) {
  // console.log(tanzu);

  const measureSize = splitNumber(tanzu.length);
  const settings = {
    size: 20,
    width: measureSize[0] || 4,
    height: measureSize[1] || 3,
  };

  return (
    <div className="App">
      <Grid tanzu={tanzu} {...settings} />
    </div>
  );
}

function Grid({ size, width, height, tanzu }) {
  const elementRef = React.useRef(null);

  useHoneyCombGrid(elementRef, { size, width, height, tanzu });

  return (
    <div
      ref={elementRef}
      style={{ transform: 'scale(1.7)', transformOrigin: 'top left', marginTop: '40px', marginLeft: '30px' }}
    />
  );
}

function useHoneyCombGrid(elementRef, { size, width, height, tanzu }) {
  React.useEffect(() => {
    const element = elementRef.current;
    const draw = SVG(element);
    const tooltip = document.getElementById('tooltip');
    // console.log(tanzu);

    const Hex = extendHex({
      size,
      render(draw, t) {

        const position = this.toPoint();
        const centerPosition = this.center().add(position);

        this.draw = draw;

        // Usa el color del objeto tanzu en el índice correspondiente
        // console.log(tanzu);
        // console.log(index);
        // console.log(this);

        // console.log(tanzu[index]);
        const index = width * this.y + this.x;
        // console.log(index);

        const fillColor = tanzu[index] ? tanzu[index].color : '#FFFFFF'; // Color por defecto si no está definido

        // Dibuja el hexágono
        const hexShape = this.draw
          .polygon(this.corners().map(({ x, y }) => `${x},${y}`))
          .fill(fillColor)
          .stroke({ width: 1, color: '#999' })
          .translate(position.x, position.y)
          .attr('data-index', index);

        const fontSize = 12;

        hexShape.on('mouseover', (event) => {
          event.stopPropagation();
          const currentIndex = event.target.dataset.index;
          tooltip.style.display = 'block';
          let tooltipText  = `${tanzu[currentIndex]['name']}`;
          if(tanzu[currentIndex]['errors']){
            tanzu[currentIndex]['errors'].forEach(error=>{
              tooltipText += `<br />${error}`;
            });
          }
          console.log(tooltipText)
          tooltip.innerHTML = tooltipText;
          tooltip.style.left = `${event.pageX - 100}px`;
          tooltip.style.top = `${event.pageY - 100}px`;
        });

        this.draw.on('mouseout', () => {
          tooltip.style.display = 'none';
        });

        // Dibuja las coordenadas x e y
        this.draw
          .text(``)
          .font({
            size: fontSize,
            anchor: 'middle',
            leading: 1.4,
            fill: '#69c',
          })
          .translate(centerPosition.x, centerPosition.y - fontSize);
      },

      highlight() {
        // Código para resaltar el hexágono
      },
    });

    const Grid = defineGrid(Hex);
    const grid = Grid.rectangle({
      width,
      height,
      start: [0, 0],
      direction: 0,
      onCreate: (hex, index) => hex.render(draw, index),
    });

    const handleClick = ({ offsetX, offsetY }) => {
      const coords = Grid.pointToHex([offsetX, offsetY]);
      const hex = grid.get(coords);
      const range = grid.hexesInRange(hex, 1);
      range.forEach((h, i) => {
        h.highlight();
      });
      if (hex) {
        hex.highlight();
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      element.innerHTML = '';
      document.removeEventListener('click', handleClick);
    };
  }, [elementRef, size, width, height, tanzu]);
}
