const nameSpace = "cas-nucleo-digital"

const arrayNucleoWorkflow = [
    "deploy-cas-proxy-vendors",
    "deploy-cas-ms-indicaciones-medicas",
    "deploy-cas-ms-episodios",
    "deploy-cas-ms-plantillas-evoluciones",
    "deploy-cas-ms-ind-farmacologicas",
    "deploy-cas-ms-evoluciones",
    "deploy-cas-ms-cargar-archivos-shp",
    "deploy-cas-ms-cargar-archivos",
    "deploy-cas-ms-api-docs",
    "deploy-cas-ms-agenda-medicap",
    "deploy-cas-ms-protocolo-quirurgico",
    "deploy-cas-ms-parametros-clinicos",
    "deploy-cas-ms-laboratorio",
    "deploy-cas-ms-indicaciones-grupos",
    "deploy-cas-ms-hospitalizacion-presupuesto",
    "deploy-cas-ms-gestion-camas",
    "deploy-cas-ms-evaluacion-preanestesica",
    "deploy-cas-ms-documentos",
    "deploy-cas-ms-cabecera-paciente-web",
    "deploy-cas-ms-cabecera",
    "deploy-cas-ws-terminologias",
    "deploy-cas-ws-sisnucdig-general",
    "deploy-cas-ws-modulab",
    "deploy-cas-ws-medicap-web-dicom",
    "deploy-cas-suscriptor-ord-hosp-sf",
    "deploy-cas-suscriptor-migracion-incremental",
    "deploy-cas-suscriptor-inter-saturno",
    "deploy-cas-suscriptor-documentos-nucleo",
    "deploy-cas-proxy-modulab",
    "deploy-cas-ms-sisnucdig-general",
    "deploy-cas-ms-reglas-negocios",
    "deploy-cas-ms-resumen-paciente",
    "deploy-cas-ms-migracion-incremental",
    "deploy-cas-ms-llamado",
    "deploy-cas-ms-indicaciones-procedimientos",
    "deploy-cas-ms-indicaciones-interconsulta",
    "deploy-cas-ms-indicaciones-imagenologia",
    "deploy-cas-ms-ind-oncologicas",
    "deploy-cas-ms-ind-laboratorio",
    "deploy-cas-ms-firma-dispositivo-consumer",
    "deploy-cas-ms-firma-dispositivo",
    "deploy-cas-ms-documentos-nucleo",
    "deploy-cas-ms-correo",
    "deploy-cas-ms-cargar-archivos-s3",
    "deploy-cas-ms-cabecera-web",
    "deploy-cas-ms-acceso",
    "deploy-cas-mf-nueva-evolucion",
    "deploy-cas-camel-migracion-incremental",
    "deploy-cas-camel-interconsultas",
    "deploy-cas-camel-documentos-nucleo",
    "deploy-cas-camel-cliente-episodio",
    "cas-mf-nuc-cli",
]

const arrayFarmacyWorkflow = [
    "deploy-cas-proxy-vendors-saf",
    "deploy-cas-proxy-vendors-documentos",
    "deploy-cas-ms-validacion-paciente-saf",
    "deploy-cas-ms-validacion-indicaciones-saf",
    "deploy-cas-ms-validacion-despachos-saf",
    "deploy-cas-ms-codificaciones-saf",
    "deploy-cas-ms-codificacion-indicaciones-saf",
    "deploy-cas-ms-validacion-devoluciones-saf",
    "deploy-cas-ms-saf",
    "deploy-cas-mf-validacion-posologias",
    "deploy-cas-mf-validacion-paciente-saf",
    "deploy-cas-mf-validacion-indicaciones-saf",
    "deploy-cas-mf-validacion-devoluciones-saf",
    "deploy-cas-mf-validacion-despachos-saf",
]

const arrayMantainerWorkflow = [
    "deploy-cas-proxy-vendors-admin"
]

const arrayDigitalWorkflow = [
    "deploy-cas-ms-sharepoint",
    "deploy-cas-ms-documentos-escaneados",
    "deploy-cas-web-sharepoint",
]

export { nameSpace, arrayDigitalWorkflow, arrayFarmacyWorkflow, arrayMantainerWorkflow, arrayNucleoWorkflow };