import React from 'react';
import './CardInfoFW.css';

import { color_status } from '../../utils/dictionaryStates';

function CardInfoFW({ status, id, header, items, style }) {
    return (
        <section className="card-info-fw" id={id} style={style}>
            <div className="header">
                {header}
                <div className='status' style={{backgroundColor: color_status[status]}} />
            </div>
            <div className="items-info">{items}</div>
        </section>
    );
}

export default CardInfoFW;
