import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import Heatmap from '../../components/Heatmap/Heatmap'
import './Tanzu.css';
import DynaGraph from '../../components/DynaGraph/DynaGraph';



function Tanzu({ selectMainDashboard, tanzu, cpuGraphData, memoryGraphData}) {

    return (
        <section className="tanzu-view">
            <Toolbar title={`Tanzu`} />
            <div className='dynatrace-section'>
                <div id="tooltip" className="tooltip"></div>
                <Heatmap tanzu={tanzu} />
                <div className='graphs'>
                    <div className='graph-container'>
                        <DynaGraph labels={cpuGraphData.timestamps} datasets={cpuGraphData.datasets} XLabel='' YLabel=''/>
                    </div>
                    <div className='graph-container'>
                        <DynaGraph labels={memoryGraphData.timestamps} datasets={memoryGraphData.datasets} XLabel='' YLabel=''/>
                    </div>
                </div>
                
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default Tanzu;