// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-transbank-cmpt {
  width: 434px;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.card-transbank-cmpt .header {
  display: flex;
  width: 100%;
  height: 40px;
}

.card-transbank-cmpt .header .icon {
  width: 40px;
  height: 40px;
}

.card-transbank-cmpt .header .icon img {
  width: 100%;
  height: 100%;
}

.card-transbank-cmpt .header .header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  width: calc(100% - 40px);
  height: 100%;
}

.card-transbank-cmpt .header .header-text .title {
  font-size: 12px;
  line-height: 19.26px;
}

.card-transbank-cmpt .header .header-text .label-cd-tr {
  font-size: 16px;
  font-weight: bold;
  line-height: 25.67px;
}

.card-transbank-cmpt .list-items {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-transbank-cmpt .list-items-wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./components/CardTransbank/CardTransbank.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,iDAAiD;EACjD,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;AACjB","sourcesContent":[".card-transbank-cmpt {\n  width: 434px;\n  height: max-content;\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  background-color: #ffffff;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  border-radius: 10px;\n  margin-bottom: 20px;\n  margin-top: 10px;\n}\n\n.card-transbank-cmpt .header {\n  display: flex;\n  width: 100%;\n  height: 40px;\n}\n\n.card-transbank-cmpt .header .icon {\n  width: 40px;\n  height: 40px;\n}\n\n.card-transbank-cmpt .header .icon img {\n  width: 100%;\n  height: 100%;\n}\n\n.card-transbank-cmpt .header .header-text {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  margin-left: 10px;\n  width: calc(100% - 40px);\n  height: 100%;\n}\n\n.card-transbank-cmpt .header .header-text .title {\n  font-size: 12px;\n  line-height: 19.26px;\n}\n\n.card-transbank-cmpt .header .header-text .label-cd-tr {\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 25.67px;\n}\n\n.card-transbank-cmpt .list-items {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.card-transbank-cmpt .list-items-wrap{\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
